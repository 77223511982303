import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import Context from '../Context';

import { DivGroup } from '../styles';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';
import Select from 'components/Input/Dropdown';
import { CpfInput } from 'components/masks';

import api from 'services/api';

export default function FirstStep({ nextStep }) {
  const {
    recomendation,
    setRecommendation,

    planner,
    setPlanner,

    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    birthdate,
    setBirthdate,
    address,
    setAddress,
    neighborhood,
    setNeighborhood,
    city,
    setCity,
    state,
    setState,
    rg,
    setRg,
    orgao,
    setOrgao,
    cpf,
    setCpf,
    setShowUpdateModal,
  } = useContext(Context);

  // Planejadores disponíveis
  const [allPlanners, setAllPlanners] = useState([]);

  async function handleInputChange(newValue) {
    const { data } = await api.get('/users', {
      params: {
        name: newValue,
        user_type: 'client',
        per_page: 10,
      },
    });

    return data.data.map(customer => ({
      value: customer.name,
      label: customer.name,
      id: customer.id,
    }));
  }

  useEffect(() => {
    async function fetchAllPlanners() {
      const { data } = await api.get(`/users`, {
        params: {
          user_type: 'planner',
          all: true,
        },
      });
      setAllPlanners(
        data.map(planner => ({
          label: planner.name,
          value: planner.name,
          id: planner.id,
        }))
      );
    }
    fetchAllPlanners();
  }, []);

  return (
    <>
      <Select
        label="Recomendado por..."
        width="100%"
        defaultValue={recomendation}
        options={handleInputChange}
        onChange={selected => setRecommendation(selected.id)}
      />
      <Select
        label="Planejador"
        width="100%"
        options={allPlanners}
        defaultValue={planner}
        onChange={selected => setPlanner(selected.id)}
      />
      <Input
        label="Nome Completo"
        width="100%"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Input
        type="email"
        width="100%"
        label="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <DivGroup>
        <Input
          label="Telefone"
          width="45%"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
        <Input
          type="date"
          label="Data de Nascimento"
          width="45%"
          value={birthdate ? format(birthdate, 'yyyy-MM-dd') : new Date()}
          onChange={e => setBirthdate(e.target.value)}
        />
      </DivGroup>
      <CpfInput
        inputLabel
        label="CPF"
        width="100%"
        value={cpf}
        onChange={cleanValue => setCpf(cleanValue)}
      />
      <DivGroup>
        <Input
          label="RG"
          width="30%"
          value={rg}
          onChange={e => setRg(e.target.value)}
        />
        <Input
          label="Orgão Emissor"
          width="50%"
          value={orgao}
          onChange={e => setOrgao(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          label="Endereço"
          width="55%"
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
        <Input
          label="Bairro"
          width="40%"
          value={neighborhood}
          onChange={e => setNeighborhood(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          label="Estado"
          width="40%"
          value={state}
          onChange={e => setState(e.target.value)}
        />
        <Input
          label="Cidade"
          width="55%"
          value={city}
          onChange={e => setCity(e.target.value)}
        />
      </DivGroup>
      <DivGroup buttons>
        <Button
          type="text"
          size="50%"
          bg="grafit"
          onClick={() => setShowUpdateModal(false)}
        >
          Cancelar
        </Button>
        <Button type="text" size="50%" bg="info" onClick={nextStep}>
          Próximo
        </Button>
      </DivGroup>
    </>
  );
}

FirstStep.propTypes = {
  nextStep: PropTypes.func,
};

FirstStep.defaultProps = {
  nextStep: () => {},
};
