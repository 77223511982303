/* eslint-disable no-console */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { AiOutlineUser } from 'react-icons/ai';

import { CSVDownload } from 'react-csv';

import { toast } from 'react-toastify';
import {
  Container,
  HeaderNav,
  ButtonExtractData,
  DropDownMenu,
  SubPagesNav,
} from './styles';

import { signOut } from 'store/modules/auth/actions';

import api from 'services/api';

export default function Header({hideExtrair}) {
  const [extractDataToCSV, setExtractDataToCSV] = useState('');
  const [showUserDropDown, setShowUserDropDown] = useState(false);

  const { name } = useSelector(state => state.auth.profile);

  const dispatch = useDispatch();

  async function handleSubmitExtractData(e) {
    try {
      e.preventDefault();
      const response = await api.get('/extract/planners/clients');

      setExtractDataToCSV(response.data);
    } catch (err) {
      toast.error('Falha ao buscar arquivo');
      console.error('\nError ->', err, '\n');
    }
  }
  return (
    <>
      <Container>
        {!hideExtrair &&
          <form onSubmit={handleSubmitExtractData}>
            <ButtonExtractData>Extrair</ButtonExtractData>
          </form>
        }
        {!!extractDataToCSV && (
          <CSVDownload data={extractDataToCSV} target="_blank" />
        )}
        <SubPagesNav />
        <HeaderNav>
          <li className="name">{name}</li>
          <li className={showUserDropDown ? 'active' : ''}>
            <button
              type="button"
              className="user"
              onClick={() => setShowUserDropDown(!showUserDropDown)}
              onBlur={() => setTimeout(() => setShowUserDropDown(false), 100)}
            >
              <AiOutlineUser type="user" />
            </button>
            <DropDownMenu className={showUserDropDown && 'show'}>
              <button type="button" onClick={() => dispatch(signOut())}>
                Sair
              </button>
            </DropDownMenu>
          </li>
        </HeaderNav>
      </Container>
    </>
  );
}

Header.propTypes = {
  hideExtrair: PropTypes.bool,
};

Header.defaultProps = {
  hideExtrair: false,
};
