import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';

import ReactTooltip from 'react-tooltip';

import { Container, Form, DivGroup } from '../styles';

import {
  CepInput,
  CpfInput,
  PhoneInput,
  CurrencyInput,
  RgInput,
} from 'components/masks';
import Loading from 'components/Loading';

import api from 'services/api';

export default function Contract({ match }) {
  const [contract, setContract] = useState({});
  const [user, setUser] = useState({});

  const [loading, setLoading] = useState(true);

  const { id } = match.params;
  const isChecked = contract?.billing_date;

  useEffect(() => {
    async function fetchedContract() {
      const { data } = await api.get(`/users/${id}/contracts`);

      setContract(data);
    }
    async function fetchedUser() {
      const { data } = await api.get(`users/${id}`);
      setUser(data);
    }
    setLoading(true);
    fetchedContract();
    fetchedUser();
    setLoading(false);
  }, [contract.phone, id]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Form>
            <div className="labelForm">
              <h3>DADOS DO CONTRATANTE</h3>
            </div>

            <DivGroup>
              <div>
                <label htmlFor="inputName">NOME COMPLETO</label>
                <input
                  type="text"
                  id="inputName"
                  className="width"
                  value={user?.name}
                  onChange={() => {}}
                />
              </div>
              <div>
                <label htmlFor="inputNameConjuge">
                  NOME DO CÔNJUGE (SE CASADO)
                </label>
                <input
                  type="text"
                  id="inputNameConjuge"
                  className="width"
                  value={contract?.partner_name}
                  onChange={() => {}}
                />
              </div>
            </DivGroup>

            <DivGroup>
              <div>
                <label htmlFor="inputEmail">E-MAIL</label>
                <input
                  type="text"
                  id="inputEmail"
                  className="width"
                  value={user?.email}
                  onChange={() => {}}
                />
              </div>
              <div>
                <label htmlFor="inputConjugeEmail">E-MAIL DO CÔNJUGE</label>
                <input
                  type="text"
                  id="inputConjugeEmail"
                  className="width"
                  value={contract?.partner_email}
                  onChange={() => {}}
                />
              </div>
            </DivGroup>

            <DivGroup>
              <div>
                <div className="line-up-horizontal">
                  <div>
                    <label htmlFor="inputPhone">TELEFONE</label>
                    <PhoneInput
                      type="text"
                      id="inputPhone"
                      className="phone"
                      value={contract?.phone}
                      onChange={() => {}}
                    />
                  </div>
                  <div>
                    <label htmlFor="inputBirthdate">NASCIMENTO</label>
                    <input
                      type="text"
                      id="inputBirthdate"
                      className="birthdate"
                      value={
                        contract?.birthdate &&
                        format(new Date(contract.birthdate), 'dd/MM/yyyy')
                      }
                      onChange={() => {}}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="line-up-horizontal">
                  <div>
                    <label htmlFor="inputPhonePartner">
                      TELEFONE DO CÔNJUGE
                    </label>
                    <PhoneInput
                      type="text"
                      id="inputPhonePartner"
                      className="phone"
                      value={contract?.partner_phone}
                      onChange={() => {}}
                    />
                  </div>
                  <div>
                    <label htmlFor="inputBirthdate">NASCIMENTO</label>
                    <input
                      type="text"
                      id="inputBirthdate"
                      className="birthdate"
                      value={
                        contract?.partner_birthdate &&
                        format(
                          new Date(contract?.partner_birthdate),
                          'dd/MM/yyyy'
                        )
                      }
                      onChange={() => {}}
                    />
                  </div>
                </div>
              </div>
            </DivGroup>

            <DivGroup>
              <div>
                <label htmlFor="inputAddress">ENDEREÇO</label>
                <input
                  type="text"
                  id="inputAddress"
                  className="width"
                  value={contract?.address}
                  onChange={() => {}}
                />
              </div>
            </DivGroup>

            <div className="data-format">
              <div className="row">
                <div className="column">
                  <div className="left">
                    <div className="divGroup">
                      <label htmlFor="inputNeighborhood">BAIRRO</label>
                      <input
                        type="text"
                        id="inputNeighborhood"
                        value={contract?.neighborhood}
                        onChange={() => {}}
                      />
                    </div>

                    <div className="divGroup">
                      <label htmlFor="inputCity">CIDADE</label>
                      <input
                        type="text"
                        id="inputCity"
                        value={contract?.city}
                        onChange={() => {}}
                      />
                    </div>

                    <div className="divGroup">
                      <label htmlFor="inputUf">UF</label>
                      <input
                        type="text"
                        id="inputUf"
                        className="uf"
                        value={contract?.city}
                        onChange={() => {}}
                      />
                    </div>

                    <div className="zip divGroup ">
                      <label htmlFor="inputZip">CEP</label>
                      <CepInput
                        type="text"
                        id="inputZip"
                        value={contract?.zip}
                        onChange={() => {}}
                      />
                    </div>
                  </div>

                  <div className="left">
                    <div className="divGroup">
                      <label htmlFor="inputRg">REGISTRO GERAL (RG)</label>
                      <RgInput
                        type="text"
                        id="inputRg"
                        value={contract?.rg}
                        onChange={() => {}}
                      />
                    </div>

                    <div className="divGroup">
                      <label htmlFor="inputOrgao">ÓRGÃO EXPEDITOR</label>
                      <input
                        type="text"
                        id="inputOrgao"
                        className="orgao"
                        value={contract?.orgao}
                        onChange={() => {}}
                      />
                    </div>

                    <div className="cpf divGroup">
                      <label htmlFor="inputCpf">CPF</label>
                      <CpfInput
                        type="text"
                        id="inputCpf"
                        value={contract?.cpf}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                </div>
                <div className="right" />
              </div>
            </div>

            <div className="labelForm">
              <h3>DADOS DO SERVIÇO CONTRATADO</h3>
            </div>

            <div className="data-format">
              <div className="row">
                <div className="column">
                  <div className="left">
                    <div className="divGroup">
                      <label htmlFor="inputDtAssContrato">
                        DATA ASS. CONTRATO
                      </label>
                      <input
                        type="text"
                        id="inputDtAssContrato"
                        value={
                          contract?.signature_date &&
                          format(
                            new Date(contract?.signature_date),
                            'dd/MM/yyyy'
                          )
                        }
                        onChange={() => {}}
                      />
                    </div>

                    <div className="divGroup">
                      <label htmlFor="inputDtFinalContrato">
                        DATA FINAL CONTRATO
                      </label>
                      <input
                        type="text"
                        id="inputDtFinalContrato"
                        value={
                          contract?.contract_end_date &&
                          format(
                            new Date(contract?.contract_end_date),
                            'dd/MM/yyyy'
                          )
                        }
                        onChange={() => {}}
                      />
                    </div>

                    <div className="divGroup">
                      <label htmlFor="inputDtPrimeriaReuniao">
                        DATA PRIMEIRA REUNIAO
                      </label>
                      <input
                        type="text"
                        id="inputDtPrimeriaReuniao"
                        value={
                          contract?.first_meeting_date &&
                          format(
                            new Date(contract?.first_meeting_date),
                            'dd/MM/yyyy'
                          )
                        }
                        onChange={() => {}}
                      />
                    </div>

                    <div className="divGroup">
                      <label htmlFor="inputNEtapas">Nº ETAPAS</label>
                      <input
                        type="text"
                        id="inputNEtapas"
                        className="nEtapas"
                        value={contract?.number_of_steps}
                        onChange={() => {}}
                      />
                    </div>
                  </div>

                  <div className="divGroup ">
                    <label htmlFor="inputDtCobranca">DATA DA COBRANÇA</label>
                  </div>
                  <div className=" customMargin left">
                    <input
                      type="checkbox"
                      name="checkCobranca"
                      id="inputDtCobranca"
                      className="customMargin-left"
                      onChange={() => {}}
                      checked={isChecked === 5}
                    />
                    <label>05</label>

                    <input
                      type="checkbox"
                      name="checkCobranca"
                      id="inputDtCobranca"
                      className="customMargin-left"
                      onChange={() => {}}
                      checked={isChecked === 15}
                    />
                    <label>15</label>

                    <input
                      type="checkbox"
                      name="checkCobranca"
                      id="inputDtCobranca"
                      className="customMargin-left"
                      onChange={() => {}}
                      checked={isChecked === 25}
                    />
                    <label>25</label>
                  </div>
                </div>
                <div className="right">
                  <DivGroup>
                    <div>
                      <label htmlFor="inputFirstMeeting">
                        PRAZO DA 1º ETAPA
                      </label>
                      <input
                        type="text"
                        id="inputFirstMeeting"
                        className="customWidth"
                        value={contract?.first_step_deadline}
                        onChange={() => {}}
                      />
                    </div>
                    <div>
                      <label htmlFor="inputFFMeeting">FEE - 1º ETAPA</label>
                      <CurrencyInput
                        type="text"
                        id="inputFFMeeting"
                        className="customWidth"
                        value={contract?.first_fee}
                        onChange={() => {}}
                      />
                    </div>
                  </DivGroup>
                  <DivGroup>
                    <div>
                      <label htmlFor="inputSecondMeeting">
                        PRAZO DA 2º ETAPA
                      </label>
                      <input
                        type="text"
                        id="inputSecondMeeting"
                        className="customWidth"
                        value={contract?.second_step_deadline}
                        onChange={() => {}}
                      />
                    </div>
                    <div>
                      <label htmlFor="inputSecondFE">FEE - 2º ETAPA</label>
                      <CurrencyInput
                        type="text"
                        id="inputSecondFE"
                        className="customWidth"
                        value={contract?.second_fee}
                        onChange={() => {}}
                      />
                    </div>
                  </DivGroup>
                  <DivGroup>
                    <div>
                      <label htmlFor="inputThirdMeeting">
                        PRAZO DA 3º ETAPA
                      </label>
                      <input
                        type="text"
                        id="inputThirdMeeting"
                        className="customWidth"
                        value={contract?.third_step_deadline}
                        onChange={() => {}}
                      />
                    </div>
                    <div>
                      <label htmlFor="inputThirdFee">FEE - 3º ETAPA</label>
                      <CurrencyInput
                        type="text"
                        id="inputThirdFee"
                        className="customWidth"
                        value={contract?.third_fee}
                        onChange={() => {}}
                      />
                    </div>
                  </DivGroup>
                </div>
              </div>
            </div>
          </Form>
          <ReactTooltip />
        </Container>
      )}
    </>
  );
}

Contract.propTypes = {
  match: PropTypes.object.isRequired,
};
