import React, { useState } from 'react';
import { InputCheckBox, Label, Button, Container } from './styles';

const Switch = ({ isOn, handleToggle, onColor }) => {
  const [on, setIsOn] = useState(isOn);

  const updateState = () => {
    setIsOn(!on);
    setTimeout(function() {
      handleToggle(!on);
    }, 250);
  };

  return (
    <Container onClick={updateState}>
      <InputCheckBox
        checked={on}
        className="react-switch-checkbox"
        id="react-switch-new"
        type="checkbox"
        onChange={updateState}
      />
      <Label
        className="react-switch-label"
        htmlFor="react-switch-new"
        style={{ background: on && onColor }}
      >
        <Button className="react-switch-button" />
      </Label>
    </Container>
  );
};

export default Switch;
