import produce from 'immer';

const INITIAL_STATE = {
  phone: null,
  personalEmail: null,
  birthdate: null,
  address: null,
  neighborhood: null,
  city: null,
  state: null,
  rg: null,
  cpf: null,
  razao_social: null,
  inscricao_social: null,
  cnpj: null,
  business_address: null,
  business_neighborhood: null,
  business_city: null,
  business_state: null,
  planner_id: null,
};

export default function contract(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@contract/STORE_CONTRACT':
      return produce(state, draft => {
        draft.phone = action.payload.phone;
        draft.personalEmail = action.payload.personalEmail;
        draft.birthdate = action.payload.birthdate;
        draft.address = action.payload.address;
        draft.neighborhood = action.payload.neighborhood;
        draft.city = action.payload.city;
        draft.state = action.payload.state;
        draft.rg = action.payload.rg;
        draft.cpf = action.payload.cpf;
        draft.razao_social = action.payload.razao_social;
        draft.inscricao_social = action.payload.inscricao_social;
        draft.cnpj = action.payload.cnpj;
        draft.business_address = action.payload.business_address;
        draft.business_neighborhood = action.payload.business_neighborhood;
        draft.business_city = action.payload.business_city;
        draft.business_state = action.payload.business_state;
      });

    case '@user/STORE_USER_SUCCESS':
      return produce(state, draft => {
        draft.planner_id = action.payload.user_id;
      });
    default:
      return state;
  }
}
