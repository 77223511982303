import React from 'react';
import PropTypes from 'prop-types';

import { ButtonStyled } from './styles';

export default function Button({
  size,
  height,
  marginTop,
  fontSize,
  iconSize,
  iconColor,
  color,
  bold,
  bg,
  stripped,
  children,
  className,
  ...rest
}) {
  return (
    <ButtonStyled
      size={size}
      height={height}
      marginTop={marginTop}
      fontSize={fontSize}
      iconSize={iconSize}
      iconColor={iconColor}
      color={color}
      bold={bold}
      bg={bg}
      stripped={stripped}
      className={className}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
}

Button.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginTop: PropTypes.number,
  fontSize: PropTypes.number,
  iconSize: PropTypes.arrayOf(PropTypes.number, PropTypes.number),
  iconColor: PropTypes.string,
  color: PropTypes.string,
  bold: PropTypes.bool,
  bg: PropTypes.string,
  stripped: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(), PropTypes.string])
    .isRequired,
};

Button.defaultProps = {
  size: '100',
  height: 47,
  marginTop: 0,
  fontSize: 17,
  iconSize: [32, 32],
  iconColor: 'black',
  color: '#fff',
  bold: true,
  bg: 'primary',
  stripped: false,
};
