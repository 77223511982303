import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 95%;

  padding-top: 1.5%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  & > div {
    display: flex;
    flex-direction: row;
  }

  button {
    margin-top: 2%;
  }
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin-top: 2%;

  width: 400px;
  height: 100%;

  & > div + div {
    margin-top: 3%;
  }
`;
