import produce from 'immer';

const INITIAL_STATE = {
  customer_id: null,
  phone: null,
  birthdate: null,
  partner_name: null,
  partner_email: null,
  partner_phone: null,
  partner_birthdate: null,
  address: null,
  neighborhood: null,
  city: null,
  state: null,
  zip: null,
  rg: null,
  orgao: null,
  cpf: null,

  signature_date: null,
  contract_end_date: null,
  billing_date: null,
  number_of_steps: null,
  first_step_deadline: null,
  second_step_deadline: null,
  third_step_deadline: null,
  first_fee: null,
  second_fee: null,
  third_fee: null,
};

export default function contract_customer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@contract_customer/STORE_CONTRACT_CUSTOMER':
      return produce(state, draft => {
        draft.phone = action.payload.phone;
        draft.birthdate = action.payload.birthdate;
        draft.partner_name = action.payload.partner_name;
        draft.partner_email = action.payload.partner_email;
        draft.partner_phone = action.payload.phone;
        draft.partner_birthdate = action.payload.partner_birthdate;
        draft.address = action.payload.address;
        draft.neighborhood = action.payload.neighborhood;
        draft.city = action.payload.city;
        draft.state = action.payload.state;
        draft.zip = action.payload.zip;
        draft.rg = action.payload.rg;
        draft.orgao = action.payload.orgao;
        draft.cpf = action.payload.cpf;

        draft.signature_date = action.payload.signature_date;
        draft.contract_end_date = action.payload.contract_end_date;
        draft.billing_date = action.payload.billing_date;
        draft.number_of_steps = action.payload.number_of_steps;
        draft.first_step_deadline = action.payload.first_step_deadline;
        draft.second_step_deadline = action.payload.second_step_deadline;
        draft.third_step_deadline = action.payload.third_step_deadline;
        draft.first_fee = action.payload.first_fee;
        draft.second_fee = action.payload.second_fee;
        draft.third_fee = action.payload.third_fee;
      });

    case '@user/STORE_USER_SUCCESS':
      return produce(state, draft => {
        draft.customer_id = action.payload.user_id;
      });

    default:
      return state;
  }
}
