import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';

import { Form, ButtonGroup } from '../styles';

import Modal from 'components/Modal/Default';

import Can from 'components/Can';
import Select from 'components/Input/Dropdown';
import Dropdown from 'components/Button/Dropdown';
import Input from 'components/Input/InputLabel';
import Button from 'components/Button';
import api from 'services/api';

export default function ModalCreate({ setClasses, setShowModal }) {
  const [instructor_id, setInstructorId] = useState('');
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [place, setPlace] = useState('');

  const [allPlanners, setAllPlanners] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get('/users', {
        params: {
          user_type: 'planner',
          all: true,
        },
      });
      setAllPlanners(
        data.map(planner => ({
          label: planner.name,
          value: planner.name,
          id: planner.id,
        }))
      );
    }
    fetchData();
  }, []);

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      const { data } = await api.post('/pfg_classes', {
        instructor_id,
        name,
        state,
        city,
        place,
      });

      setClasses(classes => {
        const newClass = [
          <Link to={`/pfg/${data.id}`}>{data.name}</Link>,
          data.state,
          data.city,
          data.place,
          <Can canRoles={['adm']}>
            <Dropdown>
              <button>Editar</button>
              <button>Excluir</button>
            </Dropdown>
          </Can>,
        ];
        return [...classes, newClass];
      });
      setShowModal(false);
      return toast.success('Turma criada com sucesso');
    } catch (err) {
      return toast.error(
        'Falha na criação da turma, verifique os dados e tente novamente'
      );
    }
  }

  return (
    <Modal title="Nova Turma" color="info" icon={null}>
      <Form onSubmit={handleSubmit}>
        <Select
          label="Instrutor"
          placeholder="Selecione o planejador..."
          options={allPlanners}
          onChange={selected => setInstructorId(selected.id)}
        />
        <Input
          label="Nome da turma"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Input
          label="Estado"
          value={state}
          onChange={e => setState(e.target.value)}
        />
        <Input
          label="Cidade"
          value={city}
          onChange={e => setCity(e.target.value)}
        />
        <Input
          label="Lugar"
          value={place}
          onChange={e => setPlace(e.target.value)}
        />
        <ButtonGroup>
          <Button bg="grafit" size="45%" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button bg="info" size="45%">
            Confirmar
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
}

ModalCreate.propTypes = {
  setClasses: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
