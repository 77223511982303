import styled, { css } from 'styled-components';

export const Form = styled.form`
  width: 400px;
`;

export const DivGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props =>
    props.buttons ? 'space-evenly' : 'space-between'};
  align-items: center;
  flex: 1;

  ${props =>
    props.buttons &&
    css`
      margin-top: 25px;
    `}
`;
