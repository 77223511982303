/* eslint-disable no-console */
import React, { useRef } from 'react';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';

import pagarme from 'pagarme';

import {
  Container,
  LogoReal,
  NumberCNPJ,
  FormCard,
  Legend,
  MessageError,
} from './styles';

import api from 'services/api';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';

export default function CheckoutPayment() {
  const cardNumberRef = useRef();
  const cardHolderNameRef = useRef();
  const cardExpirationDate = useRef();
  const cardCvvRef = useRef();

  const { user_id } = useParams();

  function handleReturnDataForm() {
    return {
      card_number: cardNumberRef?.current.value,
      card_holder_name: cardHolderNameRef?.current.value,
      card_expiration_date: cardExpirationDate?.current.value,
      card_cvv: cardExpirationDate?.current.value,
    };
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      const formData = handleReturnDataForm();

      const regexNumbers = /^[0-9]+$/;

      if (!formData.card_number.match(regexNumbers))
        return toast.error('Número do cartão pode conter apenas números');

      const connection = await pagarme.client.connect({
        encryption_key:
          process.env.PAGARME_ENCRYPTION_KEY ||
          'ek_test_uRsAQpNQjSiFAlBjcgElcJ468bG6tT',
      });

      const card_hash = await connection.security.encrypt(formData);

      await api.post(`users/${user_id}/cards`, { card_hash });

      return toast.success('Cartão criado com sucesso');
    } catch (err) {
      console.error(err);

      return toast.error(
        'Erro ao cadastrar cartão, verifique os dados e tente novamente'
      );
    }
  }

  return (
    <Container>
      {!user_id ? (
        <MessageError>
          Problema na requisição(Sem parâmetros necessários)
        </MessageError>
      ) : (
        <>
          <LogoReal />
          <NumberCNPJ>CNPJ - 28.616.998/0001-72</NumberCNPJ>

          <FormCard onSubmit={handleSubmit}>
            <Legend>Cadastrar novo cartão</Legend>
            <Input ref={cardNumberRef} label="Número do cartão" />
            <Input ref={cardHolderNameRef} label="Nome do Titular" />
            <Input ref={cardExpirationDate} label="Data de validade" />
            <Input ref={cardCvvRef} label="CVV" maxLength="3" />

            <Button type="submit" bg="info">
              Cadastrar
            </Button>
          </FormCard>
        </>
      )}
    </Container>
  );
}
