import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';

import { Form, ButtonGroup } from '../styles';

import Modal from 'components/Modal/Default';

import Can from 'components/Can';
import Select from 'components/Input/Dropdown';
import Dropdown from 'components/Button/Dropdown';
import Input from 'components/Input/InputLabel';
import Button from 'components/Button';
import api from 'services/api';

export default function ModalUpdate({
  selectedClass,
  setSelectedClass,
  setClasses,
  setShowUpdateModal,
}) {
  const pfg_id = selectedClass?.id;

  const [instructor_id, setInstructorId] = useState('');
  const [name, setName] = useState(selectedClass?.name);
  const [state, setState] = useState(selectedClass?.state);
  const [city, setCity] = useState(selectedClass?.city);
  const [place, setPlace] = useState(selectedClass?.place);

  const defaultInstructor = {
    value: selectedClass?.instructor?.name,
    label: selectedClass?.instructor?.name,
    id: selectedClass?.instructor?.id,
  };

  const [allPlanners, setAllPlanners] = useState([]);

  useEffect(() => {
    let _isMounted = true;
    async function fetchData() {
      const { data } = await api.get('/users', {
        params: {
          user_type: 'planner',
          all: true,
        },
      });
      setAllPlanners(
        data.map(planner => ({
          label: planner.name,
          value: planner.name,
          id: planner.id,
        }))
      );
    }
    if (_isMounted) {
      fetchData();
    }

    // eslint-disable-next-line no-return-assign
    return () => (_isMounted = false);
  }, []);

  function returnPfgId(pfg) {
    const string = pfg[0].props.to;

    const result = string.split('/');

    return Number(result[result.length - 1]);
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      let instructor;
      if (instructor_id) {
        instructor = instructor_id;
      }

      instructor = defaultInstructor.id;

      const { data } = await api.put(`/pfg_classes/${pfg_id}`, {
        instructor_id: instructor,
        name,
        state,
        city,
        place,
      });

      setClasses(pfgs =>
        pfgs.map(pfg => {
          const pfgId = returnPfgId(pfg);

          if (pfgId === pfg_id) {
            return [
              <Link to={`/pfg/${data.id}`}>{data.name}</Link>,
              data.state,
              data.city,
              data.place,
              <Can canRoles={['adm']}>
                <Dropdown>
                  <button
                    onClick={() => {
                      setShowUpdateModal(true);
                      setSelectedClass(pfg);
                    }}
                  >
                    Editar
                  </button>
                  <button>Excluir</button>
                </Dropdown>
              </Can>,
            ];
          }
          return pfg;
        })
      );
      setShowUpdateModal(false);
      return toast.success('Turma criada com sucesso');
    } catch (err) {
      return toast.error(
        'Falha na criação da turma, verifique os dados e tente novamente'
      );
    }
  }

  return (
    <Modal title="Nova Turma" color="info" icon={null}>
      <Form onSubmit={handleSubmit}>
        <Select
          label="Instrutor"
          placeholder="Selecione o planejador"
          defaultValue={defaultInstructor}
          options={allPlanners}
          onChange={selected => setInstructorId(selected.id)}
        />
        <Input
          label="Nome da turma"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Input
          label="Estado"
          value={state}
          onChange={e => setState(e.target.value)}
        />
        <Input
          label="Cidade"
          value={city}
          onChange={e => setCity(e.target.value)}
        />
        <Input
          label="Lugar"
          value={place}
          onChange={e => setPlace(e.target.value)}
        />
        <ButtonGroup>
          <Button
            bg="grafit"
            size="45%"
            onClick={() => setShowUpdateModal(false)}
          >
            Cancelar
          </Button>
          <Button bg="info" size="45%">
            Confirmar
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
}

ModalUpdate.propTypes = {
  selectedClass: PropTypes.shape({}).isRequired,
  setSelectedClass: PropTypes.func.isRequired,
  setShowUpdateModal: PropTypes.func.isRequired,
  setClasses: PropTypes.func.isRequired,
};
