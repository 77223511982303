import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from "react-input-mask";
import { TextField } from '@mui/material';
export default function CustomInputMask({ value, onChange, label, mask, ...rest }) {
  const [input, setInput] = useState(value);

  useEffect(() => {
    const result = maskInput(value);

    setInput(result);
  }, [value]);

  function maskInput(value) {
    if (!value) {
      return '';
    }

    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(.)/, '$1');
  }

  function cleanMask(value) {
    return value.replace(/\D/g, '');
  }

  function handleChange(e) {
    const result = maskInput(e.target.value);
    const cleanResult = cleanMask(result);
    setInput(result);
    onChange(cleanResult, result);
  }

  return (
    <InputMask
      mask={mask}
      value={input}
      onChange={handleChange}
      alwaysShowMask={true}
    >
      {() => <TextField label={label} variant="filled" />}
    </InputMask>
  )
}
CustomInputMask.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  mask: PropTypes.string
};

CustomInputMask.defaultProps = {
  value: '',
  label: false,
  onChange: () => { },
};
