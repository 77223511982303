import styled from 'styled-components';

import { colors, openInput } from 'styles/global';

export const Container = styled.div`
  width: 100%;
  height: 80%;

  .novo-cliente {
    white-space: nowrap;
    width: auto !important;
  }

  div.currentStep {
    display: flex;
    justify-content: center;
    h3 {
      font-family: 'Nunito', sans-serif;
      color: #9c9c9c;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
    }
  }
  div.subHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    button {
      height: 100%;
      font-size: 12px;
    }
  }
`;

export const Form = styled.form``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 1%;
  min-height: 48px;

  aside {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 0.5rem;

    a {
      margin-top: 4px;
    }

    svg {
      font-size: 26px;
      color: ${colors.white.default};
      transition: 200ms;
      &:hover {
        transform: scale(1.2);
        color: ${colors.info.default};
      }
    }

    p {
      margin-bottom: auto;
      font-family: 'Nunito', sans-serif;
      font-size: 32px;
      line-height: 1.35;
      letter-spacing: 0.26px;
      color: ${colors.white.default};
      margin-left: 2%;
      white-space: nowrap;
    }
  }

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20%;
    &.right-bar {
      div {
        display: flex;
        justify-content: space-evenly;
        &.right-bar-buttons {
          display: flex;
          justify-content: flex-start;
          width: 40%;
          button {
            width: auto;
            & + button {
              margin-left: 5%;
              margin-right: 0;
            }
          }
        }
      }
      svg {
        font-size: 30px;
        color: ${colors.grafit.hover};
      }
    }
  }
`;

export const Content = styled.div`
  background-color: #f2f2f2;
  border-radius: 8px;
`;

export const ModalHeader = styled.header`
  padding: 2% 0 0.5% 2%;
  background: #4545e6;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  h2 {
    color: #eee;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0.26px;
  }
  button {
    background: transparent;
    padding: 0 3% 2% 0;
    svg {
      color: #eee;
      font-weight: bold;
    }
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  svg {
    margin-right: 12px;
    font-size: 5px;
  }

  input {
    width: 0%;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 16px;
    border: 2px solid ${colors.grafit.default};
    border-left: 0;
    border-right: 0;
    border-top: 0;
    color: #fff;

    &.show {
      animation: ${openInput} forwards 500ms;
    }
  }

  button {
    background: transparent;

    svg {
      font-size: 30px;
      color: ${colors.grafit.default};

      &:hover {
        color: ${colors.grafit.hover};
        transition: color 0.2s ease;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const ContainerPaginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 10%;

  & > ul {
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
    width: 30%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      width: 30px;
      height: 30px;

      color: ${colors.grafit.hover};
      font-size: 16px;
      transition: 200ms ease;
      border-radius: 5px;
    }

    & > li + li {
      margin-left: 7%;
    }

    a.previous_page {
      padding: 5px;
      color: ${colors.grafit.hover};
      border: 1px solid ${colors.grafit.default};
    }
    a.next_page {
      padding: 5px;
      color: ${colors.grafit.hover};
      border: 1px solid ${colors.grafit.default};
    }

    li.active {
      a {
        color: ${colors.white.default};
        background: ${colors.info.default};
        border-radius: 50%;
      }
    }

    a:hover {
      color: ${colors.info.default};
      transform: scale(1.1);
    }
  }
`;

export const DivButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 5px;

  & > button + button {
    margin-left: 5px;
  }
`;

export const ContainerTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  width: 95%;
  height: 95%;
`;

export const StyleDefaultInputDate = styled.div`
  svg {color: #fff;}
  input {color: #fff;};
  label {color: #fff;}
`;
