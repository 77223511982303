import React from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonGroup } from './styles';
import Modal from 'components/Modal/Default';
import Button from 'components/Button';
import useDeleteProfile from 'hooks/useDeleteProfile';
import Loading from 'components/Loading';
export default function ModalExclude({
  selectedProfile,
  setProfiles,
  setShowModalExclude,
}) {

  const {
    handleSubmit,
    loading
  } = useDeleteProfile(selectedProfile, setProfiles, setShowModalExclude);
  if (loading) {
    return <Loading />
  }
  return (
    <Modal color="danger" title="Tem certeza que deseja excluir este Perfil?" icon={null}>
      <Form onSubmit={handleSubmit}>
        <ButtonGroup>
          <Button
            bg="grafit"
            stripped
            size="45%"
            onClick={() => setShowModalExclude(false)}
          >
            Cancelar
          </Button>
          <Button bg="danger" size="45%">
            Confirmar
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
}

ModalExclude.propTypes = {
  selectedProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  setProfiles: PropTypes.func.isRequired,
  setShowModalExclude: PropTypes.func.isRequired,
};
