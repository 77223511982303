import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { DivGroup } from '../styles';

import Context from '../Context';

import Input from 'components/Input/InputLabel';
import CpfInput from 'components/masks/CpfInput';
import Button from 'components/Button';

export default function FirstStep({ nextStep }) {
  const {
    name,
    setName,
    email,
    setEmail,
    rg,
    setRg,
    birthdate,
    setBirthdate,
    cpf,
    setCpf,
    phone,
    setPhone,
    address,
    setAddress,
    neighborhood,
    setNeighborhood,
    city,
    setCity,
    state,
    setState,
    personal_email,
    setPersonalEmail,

    setShowUpdateModal,
  } = useContext(Context);

  return (
    <>
      <Input
        label="Nome Completo"
        width="100%"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <DivGroup>
        <Input
          label="RG"
          width="45%"
          value={rg}
          onChange={e => setRg(e.target.value)}
        />
        <Input
          type="date"
          label="Data de Nascimento"
          width="45%"
          value={
            birthdate ? format(new Date(birthdate), 'yyyy-MM-dd') : new Date()
          }
          onChange={e => setBirthdate(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <CpfInput
          inputLabel
          width="45%"
          label="CPF"
          value={cpf}
          onChange={cleanValue => setCpf(cleanValue)}
        />
        <Input
          label="Telefone"
          width="45%"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          label="Endereço"
          width="45%"
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
        <Input
          label="Bairro"
          width="45%"
          value={neighborhood}
          onChange={e => setNeighborhood(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          label="Cidade"
          width="45%"
          value={city}
          onChange={e => setCity(e.target.value)}
        />
        <Input
          label="Estado"
          width="45%"
          value={state}
          onChange={e => setState(e.target.value)}
        />
      </DivGroup>
      <Input
        label="Email Pessoal"
        width="100%"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Input
        label="Email Real"
        width="100%"
        value={personal_email}
        onChange={e => setPersonalEmail(e.target.value)}
      />
      <DivGroup buttons>
        <Button
          type="text"
          size="50%"
          bg="grafit"
          onClick={() => setShowUpdateModal(false)}
        >
          Cancelar
        </Button>
        <Button type="text" size="50%" bg="info" onClick={nextStep}>
          Próximo
        </Button>
      </DivGroup>
    </>
  );
}

FirstStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
};
