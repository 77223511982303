export function storeUser({ name, email, password, type, contract }) {
  return {
    type: '@user/STORE_USER',
    payload: { name, email, password, type, contract },
  };
}

export function storeUserSuccess({ user_id }) {
  return {
    type: '@user/STORE_USER_SUCCESS',
    payload: { user_id },
  };
}

export function storeUserFailure() {
  return {
    type: '@user/STORE_USER_FAILURE',
  };
}
