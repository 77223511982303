import React from 'react';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Route from './Route';
import Wrapper from 'pages/_layouts/default/index';
import NotFoundPage from 'pages/main/NotFound';
import {
  SignIn,
  Dashboard,
  Planners,
  Customers,
  Profile,
  ProfileCustomer,
  Pfg,
  ProfilePfg,
  Profiles,
  CheckoutPayment,
  Reports,
  RecommendedWallet,
  ViewByReport,
} from 'pages';
const routesConfig = require('./routesConfig.json');
const withWrapper = (Component) => (props) => (
  <Wrapper>
    <Component {...props} />
  </Wrapper>
);

export default function Routes() {

  return (
    <>
      <ToastContainer />
      <Switch>
        {routesConfig.routes.map((route) => {
          const Component = getComponent(route.component);
          return (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={
                route.isPrivate ? withWrapper(Component) : Component}
              roles={route.roles}
              isPrivate={route.isPrivate}
            />
          )

        })}

      </Switch>
    </>
  );
}

const getComponent = (componentName) => {

  const components = {
    SignIn,
    CheckoutPayment,
    Dashboard,
    Planners,
    Profile,
    RecommendedWallet,
    Customers,
    ProfileCustomer,
    Reports,
    ViewByReport,
    Pfg,
    ProfilePfg,
    Profiles,
    NotFoundPage
  };

  return components[componentName] || NotFoundPage;
};
