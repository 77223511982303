import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  /* padding: 50px; */
  /* background: #1f29; */
  padding-left: 5px;
  color: #ffffff;
  margin-top: 3px;
  h1 {
    color: #ffffff;
    margin-bottom: 1px;
    margin-left: 10px;
    font-size: 24px;
    font-weight: 500;
  }
  div.card {
    display: flex;
    flex-direction: column;
    background: #1f2940;
    border-radius: 15px;
    height: 90%;
    padding: 15px;
    justify-content: space-evenly;
  }
`;
