import React, { useState } from 'react';

import { Container } from '../styles';

// import Button from 'components/Button';

import Transactions from './Transactions';
import Subscriptions from './Subscriptions';

export default function Finances({ match }) {
  const [toggleButton] = useState('subscriptions');

  return (
    <Container>
      <>
        {toggleButton === 'transactions' ? (
          <Transactions match={match} />
        ) : (
          <Subscriptions match={match} />
        )}
      </>
    </Container>
  );
}
