import styled from 'styled-components';

import { colors } from 'styles/global';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;

  width: 100%;
  height: 100%;

  position: relative;

  button {
    width: 100%;
    height: 100%;

    background: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  div.options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 200px;
    /* height: 300%; */
    background: ${colors.white.default};
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    z-index: 1;
    transform: translateX(-70px) translateY(40px);

    padding: 15%;

  
    & > button + button {
      margin-top: 2%;
    }

    a {
      text-align: center;
      margin-top: 2%;
      border-radius: 8px;
      color: ${colors.grafit.hover};
      transition: 100ms ease;
      height: auto;
      &:hover {
        background: ${colors.info.default};
        color: ${colors.white.default};
      }
    }

    button {
      border-radius: 8px;
      color: ${colors.grafit.hover};
      transition: 100ms ease;
      height: 40px;
      &:hover {
        background: ${colors.info.default};
        color: ${colors.white.default};
      }
    }
  }
`;
