import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Context from '../Context';

import { DivGroup, DivCheckbox } from '../styles';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';

export default function ThirdStep({ previousStep }) {
  const {
    signature_date,
    setSignatureDate,
    contract_end_date,
    setContractEndDate,
    first_meeting_date,
    setFirstMeetingDate,
    billing_date,
    setBillingDate,
    number_of_steps,
    setNumberOfSteps,
    first_step_deadline,
    setFirstStepDeadline,
    second_step_deadline,
    setSecondStepDeadline,
    third_step_deadline,
    setThirdStepDeadline,
    first_fee,
    setFirstFee,
    second_fee,
    setSecondFee,
    third_fee,
    setThirdFee,

    handleSubmit,
  } = useContext(Context);

  return (
    <>
      <DivGroup>
        <Input
          label="Início do Contrato"
          width="45%"
          value={
            signature_date
              ? format(new Date(signature_date), 'yyyy-MM-dd')
              : new Date()
          }
          onChange={e => setSignatureDate(e.target.value)}
        />
        <Input
          label="Fim do Contrato"
          width="45%"
          value={
            contract_end_date
              ? format(new Date(contract_end_date), 'yyyy-MM-dd')
              : new Date()
          }
          onChange={e => setContractEndDate(e.target.value)}
        />
      </DivGroup>
      <Input
        label="Primeira reunião"
        width="100%"
        value={
          first_meeting_date
            ? format(new Date(first_meeting_date), 'yyyy-MM-dd')
            : new Date()
        }
        onChange={e => setFirstMeetingDate(e.target.value)}
      />
      <DivGroup>
        <DivCheckbox>
          <label htmlFor="">Dia de Cobrança</label>
          <div className="check">
            <input
              type="radio"
              name="billing_date"
              id="inputBillingDate"
              checked={billing_date === 5 && true}
              onChange={() => setBillingDate(5)}
            />
            <span>5</span>
            <input
              type="radio"
              name="billing_date"
              id="inputBillingDate"
              checked={billing_date === 15 && true}
              onChange={() => setBillingDate(15)}
            />
            <span>15</span>
            <input
              type="radio"
              name="billing_date"
              id="inputBillingDate"
              checked={billing_date === 25 && true}
              onChange={() => setBillingDate(25)}
            />
            <span>25</span>
          </div>
        </DivCheckbox>
        <DivCheckbox>
          <label htmlFor="">Número de passos</label>
          <div className="check">
            <input
              type="radio"
              name="number_of_steps"
              id="inputNumberOfSteps"
              checked={number_of_steps === 1 && true}
              onChange={() => setNumberOfSteps(1)}
            />
            <span>1</span>
            <input
              type="radio"
              name="number_of_steps"
              id="inputNumberOfSteps"
              checked={number_of_steps === 2 && true}
              onChange={() => setNumberOfSteps(2)}
            />
            <span>2</span>
            <input
              type="radio"
              name="number_of_steps"
              id="inputNumberOfSteps"
              checked={number_of_steps === 3 && true}
              onChange={() => setNumberOfSteps(3)}
            />
            <span>3</span>
          </div>
        </DivCheckbox>
      </DivGroup>
      <DivGroup>
        <Input
          type="number"
          width="45%"
          label="Primeira Etapa"
          value={first_step_deadline}
          onChange={e => setFirstStepDeadline(e.target.value)}
        />
        <Input
          type="number"
          width="45%"
          label="Mensalidade da 1ª Etapa"
          value={first_fee}
          onChange={e => setFirstFee(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          type="number"
          width="45%"
          label="Segunda Etapa"
          value={second_step_deadline}
          onChange={e => setSecondStepDeadline(e.target.value)}
        />
        <Input
          type="number"
          width="45%"
          label="Mensalidade da 2ª Etapa"
          value={second_fee}
          onChange={e => setSecondFee(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          type="number"
          width="45%"
          label="Terceira Etapa"
          value={third_step_deadline}
          onChange={e => setThirdStepDeadline(e.target.value)}
        />
        <Input
          type="number"
          width="45%"
          label="Mensalidade da 2ª Etapa"
          value={third_fee}
          onChange={e => setThirdFee(e.target.value)}
        />
      </DivGroup>
      <DivGroup buttons>
        <Button type="text" size="50%" bg="grafit" onClick={previousStep}>
          Voltar
        </Button>
        <Button type="text" size="50%" bg="info" onClick={handleSubmit}>
          Confirmar
        </Button>
      </DivGroup>
    </>
  );
}

ThirdStep.propTypes = {
  previousStep: PropTypes.func,
};

ThirdStep.defaultProps = {
  previousStep: () => {},
};
