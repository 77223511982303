import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import {
  AiOutlineCaretLeft,
  AiOutlineCaretRight,
} from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import { useLastLocation } from 'react-router-last-location';
import { Container, Header, DivInput, ContainerPaginate } from './styles';
import ProfilesTable from 'components/organisms/ProfilesTable';
import useProfiles from 'hooks/useProfiles';
import Button from 'components/Button';
import ModalCreate from './Modals/ModalCreate';
import ModalExclude from './Modals/ModalExclude';
import Loading from 'components/Loading';
export default function Profiles() {

  const [showModalExclude, setShowModalExclude] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});
  const location = useLastLocation();

  const { profiles, loading, setPage, pageCount, setProfiles } = useProfiles(5);

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPage(selected + 1);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Header>
          <aside>
            <Link to={location ? location.pathname : '#'} data-tip="Voltar">
              <Icon type="import" />
            </Link>
            <p>Perfis</p>
          </aside>
          <div className="right-bar">
            <div className="right-bar-buttons">
              <Button size={35} bg="info" onClick={() => setShowModal(true)}>
                Novo Perfil
              </Button>
            </div>
          </div>
        </Header>
        <ProfilesTable
          profiles={profiles}
          setSelectedProfile={setSelectedProfile}
          setProfiles={setProfiles}
          setShowModalExclude={setShowModalExclude}
        />
        <ContainerPaginate>
          <ReactPaginate
            previousLabel={<AiOutlineCaretLeft size={22} />}
            pageCount={pageCount}
            previousLinkClassName="previous_page"
            nextLabel={<AiOutlineCaretRight size={22} />}
            nextLinkClassName="next_page"
            activeClassName="active"
            onPageChange={handlePageClick}
          />
        </ContainerPaginate>
      </Container>

      {showModal && (
        <ModalCreate
          setShowModal={setShowModal}
          setProfiles={setProfiles}
          setSelectedProfile={setSelectedProfile}
          setShowModalExclude={setShowModalExclude}
        />
      )}

      {showModalExclude && (
        <ModalExclude
          selectedProfile={selectedProfile}
          setProfiles={setProfiles}
          setShowModalExclude={setShowModalExclude}
        />
      )}
    </>
  );
}
