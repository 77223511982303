import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';

import { Container, DivNewCard, Body } from '../../styles';

import TotalTable from 'components/Table/Default';

import Button from 'components/Button';

import CreateModal from './Modals/CreateModal';

import api from '../../../../../../services/api';

export default function Subscriptions({ match }) {
  const [showModal, setShowModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await api.get(`clients/${match.params.id}/subscriptions`)
        .then(response => setSubscriptions(response.data));
    }
    fetchData();
  }, [match.params.id]);

  return (
    <>
      <Container>
        <DivNewCard>
          <Button size={10} bg="info" onClick={() => setShowModal(true)}>
            Nova assinatura
          </Button>
        </DivNewCard>
        <Body>
          <TotalTable
            head={[
              'id',
              'Nome do plano',
              'Amount',
              'Cobranças realizadas',
              'Cobranças',
              'Cartão',
              'Proxima cobrança',
            ]}
            body={subscriptions?.map(s => [
              s.id,
              s.plan?.name,
              (s.plan?.amount / 100).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }),
              s.charges,
              s.plan?.charges,
              `****-${s.card?.last_digits}`,
              format(new Date(s.current_period_end), 'dd/MM/yyyy'),
              '',
            ])}
          />
        </Body>
      </Container>
      {showModal && (
        <CreateModal
          setShowModal={setShowModal}
          match={match}
          setSubscriptions={setSubscriptions}
        />
      )}
    </>
  );
}
