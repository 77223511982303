import React, { useState, forwardRef, useImperativeHandle} from 'react';

import PropTypes from 'prop-types';

import { AiOutlineMore } from 'react-icons/ai';

import { Container } from './styles';

const Dropdown = forwardRef(({icon, children, className, canClose}, ref) => {

  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    close() {
      setShow(false);
    }
  }));

  const closeOptions = () => {
    if(canClose) {
      setTimeout(() => setShow(false), 100)
    }
  }

  return (
    <Container className={className}>
      <button
        type="button"
        onClick={() => setShow(!show)}
        onBlur={closeOptions}
      >
        {icon}
      </button>
      {show && <div className="options">{children}</div>}
    </Container>
  );
})

Dropdown.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  canClose: PropTypes.bool
};

Dropdown.defaultProps = {
  icon: <AiOutlineMore />,
  canClose: true
};

export default Dropdown;
