import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import makeAnimated from 'react-select/animated';

import { toast } from 'react-toastify';
import { Container, Form, FormColumn } from './styles';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';
import Select from 'components/Input/Dropdown';

import api from 'services/api';
import Loading from 'components/Loading';

export default function Details() {
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [place, setPlace] = useState('');
  const [city, setCity] = useState('');
  const [created_at, setCreateAt] = useState('');

  const [isLoad, setIsLoad] = useState(true);

  // Planejador responsável vindo da api
  const [planner, setPlanner] = useState({});

  // Planejador responsável que será enviado
  const [plannerResp, setPlannerResp] = useState({});

  // Planejadores assistentes vindos da api
  const [plannersAssistant, setPlannersAssistant] = useState([]);

  // Planejadores assistenetes que serão enviados
  const [pAssistantsApi, setPAssistantsApi] = useState([]);

  // Todos os planejadores vindo da api
  const [plannersApi, setPlannersApi] = useState([]);

  const { pfg_class_id } = useParams();

  useEffect(() => {
    async function fetchData() {
      setIsLoad(true);
      const { data } = await api.get(`/pfg_classes/${pfg_class_id}`);

      setName(data.name);
      setState(data.state);
      setCity(data.city);
      setPlace(data.place);
      setCreateAt(format(new Date(data.created_at), 'dd/MM/yyyy'));

      setPlanner({
        value: data.instructor.name,
        label: data.instructor.name,
        id: data.instructor.id,
      });

      setPlannersAssistant(
        data.assistantPlanners.map(assistant => ({
          value: assistant.name,
          label: assistant.name,
          id: assistant.id,
        }))
      );
    }

    async function fetchPlanners() {
      const { data } = await api.get('/users', {
        params: {
          user_type: 'planner',
          all: true,
        },
      });
      setPlannersApi(
        data.map(planner => ({
          value: planner.name,
          label: planner.name,
          id: planner.id,
        }))
      );

      setIsLoad(false);
    }
    fetchData();
    fetchPlanners();
  }, [pfg_class_id]);

  async function handleDeleteAssistant(selected, action) {
    try {
      if (action === 'clear') {
        plannersAssistant.map(async planner => {
          await api.delete(
            `/pfg_classes/${pfg_class_id}/assistant_planners/${planner.id}`
          );
        });

        return toast.success('Excluido com sucesso');
      }

      await api.delete(
        `/pfg_classes/${pfg_class_id}/assistant_planners/${selected.id}`
      );
      return toast.success('Excluido com sucesso');
    } catch (err) {
      return toast.error('Falha ao retirar assistente, tente novamente');
    }
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      let instructor_id;

      if (plannerResp.id) {
        instructor_id = plannerResp.id;
      }
      instructor_id = planner.id;

      await api.put(`/pfg_classes/${pfg_class_id}`, {
        instructor_id,
        name,
        state,
        city,
        place,
      });

      await api.post(`/pfg_classes/${pfg_class_id}/assistant_planners`, {
        user_id: pAssistantsApi,
      });

      toast.success('Turma editada com sucesso');
    } catch (err) {
      toast.error(
        'Falha ao editar turma, verifique os dados e tente novamente'
      );
    }
  }

  return (
    <>
      {isLoad ? (
        <Loading />
      ) : (
        <Container>
          <Form onSubmit={handleSubmit}>
            <div>
              <FormColumn>
                <Input
                  label="Nome da Turma"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <Input
                  label="Estado"
                  value={state}
                  onChange={e => setState(e.target.value)}
                />
                <Input
                  label="Cidade"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
                <Input
                  label="Lugar"
                  value={place}
                  onChange={e => setPlace(e.target.value)}
                />
                <Input label="Criada em" disabled value={created_at} />
              </FormColumn>
              <FormColumn>
                {planner.value && (
                  <Select
                    label="Planejador Responsável"
                    defaultValue={planner}
                    options={plannersApi}
                    onChange={selected => setPlannerResp(selected.id)}
                  />
                )}

                <Select
                  label="Planejador(es) Assitente(s)"
                  isMulti
                  components={makeAnimated()}
                  defaultValue={plannersAssistant}
                  options={plannersApi}
                  onChange={(selected, action) => {
                    if (
                      action.action === 'remove-value' ||
                      action.action === 'clear'
                    )
                      return handleDeleteAssistant(
                        action.removedValue,
                        action.action
                      );

                    if (!selected) {
                      return setPAssistantsApi([]);
                    }

                    return setPAssistantsApi(
                      selected.map(selected => selected.id)
                    );
                  }}
                />
              </FormColumn>
            </div>
            <Button bg="info" size={20}>
              Editar
            </Button>
          </Form>
        </Container>
      )}
    </>
  );
}
