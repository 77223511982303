import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from 'store/modules/auth/actions';
import { store } from '../store';
import { redirects } from './routesConfig.json'

const getRedirect = (userRoles) => {
  const role = userRoles[0]?.slug;
  return redirects[role];
}

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  path,
  roles,
  redirect,
  permissions,
  ...rest
}) {
  const dispatch = useDispatch();
  const { signed, roles: userRoles } = useSelector(state => state.auth);

  const validSession = () => {
    if (path === '/' && signed) {
      dispatch(signOut());
    }
  }

  useEffect(() => {
    validSession()
    if (store.getState().auth.profile?.last_acess) {
      const today = new Date();

      const lastAcess = new Date(store.getState().auth.profile.last_acess);

      if (lastAcess.getDate() < today.getDate()) {
        dispatch(signOut());
      }
    }
    // eslint-disable-next-line
  }, [dispatch]);

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  const hasPermission = (userRoles.some(userRole => roles?.includes(userRole.slug)) || roles[0] === "*");

  if (signed && !isPrivate) {
    return <Redirect to={getRedirect(userRoles)} />;
  }

  if (!hasPermission && signed && path !== '/') {
    return <Redirect to={{
      pathname: "/notfound",
      state: { path: getRedirect(userRoles) }
    }} />;
  }

  return <Route {...rest} component={Component} />;
}
RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  isPrivate: PropTypes.bool,
};
