import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import { MdLockOutline, MdOutlineMail } from 'react-icons/md';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import ChangeProfile from '../../../components/ChangeProfile';

import { Wrapper,
        WrapperGray,
        WrapperSelectPerfil,
        Logo,
        Container,
        Form,
        InputGroup,
        HeaderGroup,
        BottomGroup,
        ContainerImage } from './styles';

import Button from 'components/Button';

import { signInRequest } from 'store/modules/auth/actions';

import LogoImg from 'assets/logo-login.png';

export default function SignIn() {
  const dispatch = useDispatch();

  const [visiblePassword, setVisiblePassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loading = useSelector(state => state.auth.loading);

  function handleSubmit(e) {
    e.preventDefault();
    if (!email || !password) {
      return toast.error('Preencha todos os campos');
    }
    dispatch(signInRequest(email, password));
  }

  function handlerVisibility() {
    setVisiblePassword(!visiblePassword);
  }

  return (
    <WrapperGray>
      <Wrapper>
      <ContainerImage>
          <HeaderGroup>
            <div className="watermark" />
            <h1>Planejamento Financeiro<br />
            para quem quer curtir o presente e desfrutar do futuro.
            </h1>
          </HeaderGroup>
          <BottomGroup>
            <h2>Viver é o melhor investimento!</h2>
          </BottomGroup>
        </ContainerImage>
        <Container>
          <Logo src={LogoImg} alt="Yuno On" className="imgLogo" />
          <Form onSubmit={handleSubmit}>
            <div className="control-group">
              <label className="control-label" htmlFor="inputEmail">E-mail</label>
              <InputGroup className="bordered">
                <div className="button-lock">
                  <MdOutlineMail size={25} />
                </div>
                <input
                  type="email"
                  name="email"
                  id="inputEmail"
                  placeholder="Digite seu email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </InputGroup>
            </div>
            <div className="control-group">
              <label className="control-label" htmlFor="inputPassword">Senha</label>
              <InputGroup className="bordered">
                <div className="button-lock">
                  <MdLockOutline size={25} />
                </div>
                <input
                  type={ visiblePassword ? "text" : "password" }
                  name="password"
                  id="inputPassword"
                  placeholder="Digite sua senha"
                  value={password}
                  autoComplete="on"
                  onChange={e => setPassword(e.target.value)}
                />
                <div className="button-visibility" onClick={handlerVisibility}>
                  {visiblePassword && <AiOutlineEyeInvisible />}
                  {!visiblePassword && <AiOutlineEye />}
                </div>
              </InputGroup>
            </div>
            <Button className="higher inter" bg="grayButton" color="white" marginTop={20} type="submit">
              {loading ? <FaSpinner className="spin" size={25} /> : 'Entrar'}
            </Button>
          </Form>
          <WrapperSelectPerfil>
              <ChangeProfile />
            </WrapperSelectPerfil>
        </Container>
      </Wrapper>
    </WrapperGray>
  );
}
