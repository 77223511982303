import React from 'react';
import Dropdown from 'components/Button/Dropdown';

const ProfileActions = ({ onEdit, onDelete }) => (
  <Dropdown>
    <button onClick={onDelete}>Excluir</button>
  </Dropdown>
);

export default ProfileActions;
