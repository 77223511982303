import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input/InputLabel';

export default function CpfInput({ value, onChange, inputLabel, ...rest }) {
  const [input, setInput] = useState(value ? value.toString() : '');

  useEffect(() => {
    const result = maskInput(value);
    setInput(result);
  }, [value]);

  function maskInput(value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/([.]\d{3})(\d)/, '$1.$2')
      .replace(/([.]\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})(.)/, '$1');
  }

  function cleanMask(value) {
    return value.replace(/\D/g, '');
  }

  function handleChange(e) {
    const result = maskInput(e.target.value);
    const cleanResult = cleanMask(result);
    onChange(cleanResult, result);
    setInput(result);
  }

  if (inputLabel) {
    return <Input value={input} onChange={handleChange} {...rest} />;
  }

  return <input value={input} onChange={handleChange} {...rest} />;
}

CpfInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  inputLabel: PropTypes.bool,
};

CpfInput.defaultProps = {
  value: '',
  onChange: () => {},
  inputLabel: false,
};
