import styled, { css } from 'styled-components';

import { colors } from 'styles/global';

export const ButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.size && props.size}%;
  height: ${props => props.height}px;
  padding: 0 15px;
  margin-top: ${props => props.marginTop && props.marginTop}px;
  font-size: ${props => props.fontSize && '14'}px;
  font-weight: ${props => props.bold && 'bold'};
  color: ${props => props.color};
  background-color: ${props => colors[props.bg].default} !important;
  border-radius: 5px;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => colors[props.bg].disabled} !important;
  }

  transition: 0.5s ease;

  ${props =>
    props.stripped &&
    css`
      background: transparent !important;
      border: 2px solid ${colors[props.bg].hover};
      color: ${colors[props.bg].hover};
    `}

  ${props =>
    props.children.isArray &&
    css`
      display: flex;
      align-items: center;
      padding: 2px;
    `}

  img {
    width: ${props => props.iconSize && `${props.iconSize[1]}px`};
    height: ${props => props.iconSize && `${props.iconSize[2]}px`};
    color: ${props => props.iconColor && props.iconColor};
    margin: 0 5px;
  }

  &:hover:not(:disabled) {
    background: ${props => colors[props.bg].hover} !important;
    color: ${props => props.stripped && '#eee'};
  }
`;
