import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

export default function CurrencyInput({
  label,
  value,
  onChange,
  precision,
  prefix,
  suffix,
  decimalSeparator,
  thousandSeparator,
  ...rest
}) {
  const [input, setInput] = useState(maskInput(Number(value).toFixed(2)));

  function maskInput(cleanValue) {
    // GET DIGITS ARRAY
    let digits = cleanValue.toString().match(/\d/g) || ['0'];

    // ADD ZEROES TO DECIMAL
    while (digits.length <= precision) digits.unshift('0');

    // ADD DECIMAL SEPARATOR
    if (precision > 0) {
      digits.splice(digits.length - precision, 0, '.');
    }

    // clean up extraneous digits like leading zeros.
    digits = Number(digits.join(''))
      .toFixed(precision)
      .split('');

    let decimalpos = digits.length - precision - 1; // -1 needed to position the decimal separator before the digits.
    if (precision > 0) {
      // set the final decimal separator
      digits[decimalpos] = decimalSeparator;
    } else {
      // when precision is 0, there is no decimal separator.
      decimalpos = digits.length;
    }

    // add in any thousand separators
    for (let x = decimalpos - 3; x > 0; x -= 3) {
      digits.splice(x, 0, thousandSeparator);
    }

    // if we have a prefix or suffix, add them in.
    if (prefix.length > 0) {
      digits.unshift(prefix);
    }
    if (suffix.length > 0) {
      digits.push(suffix);
    }

    return digits.join('').trim();
  }

  function cleanMask(maskedValue) {
    const nonDigitPattern = `[^\\d(${decimalSeparator})]`;
    const decimalSeparatorPattern = `(${decimalSeparator})`;
    const nonDigitRegex = new RegExp(nonDigitPattern, 'g');
    const decimalSeparatorRegex = new RegExp(decimalSeparatorPattern, 'g');
    return Number(
      maskedValue.replace(nonDigitRegex, '').replace(decimalSeparatorRegex, '.')
    );
  }

  function handleChange(e) {
    const result = maskInput(e.target.value);
    const cleanResult = cleanMask(result);
    setInput(result);
    onChange(cleanResult, result);
  }

  return (
    <div>
       {label && (
        <label>
          {label}
        </label>
      )}
      <Input type="text" value={input} onChange={handleChange} {...rest} />
    </div>
  );
}

CurrencyInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  precision: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
};

CurrencyInput.defaultProps = {
  value: '',
  onChange: () => {},
  precision: 2,
  prefix: '',
  suffix: '',
  decimalSeparator: ',',
  thousandSeparator: '.',
};
