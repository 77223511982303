import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Container, DivGroup } from './styles';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';
import Select from 'components/Input/Dropdown';

import { CpfInput, PhoneInput, RgInput } from 'components/masks';

import { api_ibge } from 'services/api';

export default function FirstStep({
  totalSteps,
  currentStep,
  nextStep,
  name,
  setName,
  rg,
  setRg,
  birthdate,
  setBirthdate,
  cpf,
  setCpf,
  phone,
  setPhone,
  address,
  setAddress,
  neighborhood,
  setNeighborhood,
  city,
  setCity,
  state,
  setState,
  personalEmail,
  setPersonalEmail,
  realEmail,
  setRealEmail,
  setActiveModal,
}) {
  const [allUfs, setAllUfs] = useState([]);

  const [selectedUf, setSelectedUf] = useState({});

  const [allCities, setAllCities] = useState([]);

  useEffect(() => {
    async function fetchAllUfs() {
      try {
        const { data } = await api_ibge.get('/estados');

        setAllUfs(
          data.map(uf => ({
            label: uf.nome,
            value: uf.nome,
            sigla: uf.sigla,
            id: uf.id,
          }))
        );
      } catch (err) {
        toast.error('Falha ao carregar Estados');
      }
    }

    fetchAllUfs();
  }, []);

  useEffect(() => {
    async function fetchCityData() {
      try {
        const { data } = await api_ibge.get(
          `/estados/${selectedUf.id}/municipios`
        );

        setAllCities(
          data.map(city => ({
            label: city.nome,
            value: city.nome,
            id: city.id,
          }))
        );
      } catch (err) {
        toast.error('Falha ao carregar cidades');
      }
    }
    fetchCityData();
  }, [selectedUf.id]);

  return (
    <Container>
      <div className="currentStep">
        <h3>
          Passo {currentStep} de {totalSteps}
        </h3>
      </div>
      <Input
        label="Nome"
        width="100%"
        value={name}
        onChange={e => setName(e.target.value)}
      />

      <DivGroup>
        <RgInput
          width="45%"
          inputLabel
          label="RG"
          value={rg}
          onChange={cleanValue => setRg(cleanValue)}
        />
        <Input
          type="date"
          width="45%"
          label="Data de Nascimento"
          value={birthdate}
          onChange={e => setBirthdate(e.target.value)}
        />
      </DivGroup>

      <DivGroup>
        <CpfInput
          inputLabel
          label="CPF"
          width="45%"
          value={cpf}
          onChange={cleanValue => setCpf(cleanValue)}
        />
        <PhoneInput
          inputLabel
          label="Telefone"
          width="45%"
          value={phone}
          onChange={cleanValue => setPhone(cleanValue)}
        />
      </DivGroup>

      <DivGroup>
        <Input
          label="Endereço"
          width="45%"
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
        <Input
          label="Bairro"
          width="45%"
          value={neighborhood}
          onChange={e => setNeighborhood(e.target.value)}
        />
      </DivGroup>

      <DivGroup>
        <Select
          label="Estado"
          width="185px"
          value={{
            value: state,
            label: state,
          }}
          options={allUfs}
          onChange={selected => {
            setSelectedUf(selected);

            setState(selected.sigla);
          }}
        />
        <Select
          label="Cidade"
          width="185px"
          value={{
            value: city,
            label: city,
          }}
          options={allCities.length >= 1 ? allCities : []}
          onChange={selected => setCity(selected.label)}
        />
      </DivGroup>

      <Input
        label="Email Pessoal"
        width="100%"
        value={personalEmail}
        onChange={e => setPersonalEmail(e.target.value)}
      />

      <Input
        label="Email da Real"
        value={realEmail}
        width="100%"
        onChange={e => setRealEmail(e.target.value)}
      />

      <DivGroup className="buttonsForm">
        <Button size={20} bg="grafit" onClick={() => setActiveModal(false)}>
          Cancelar
        </Button>
        <Button size={20} bg="info" onClick={nextStep}>
          Próximo
        </Button>
      </DivGroup>
    </Container>
  );
}

FirstStep.propTypes = {
  totalSteps: PropTypes.number,
  currentStep: PropTypes.number,
  nextStep: PropTypes.func,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  rg: PropTypes.string.isRequired,
  setRg: PropTypes.func.isRequired,
  birthdate: PropTypes.string.isRequired,
  setBirthdate: PropTypes.func.isRequired,
  cpf: PropTypes.string.isRequired,
  setCpf: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  setAddress: PropTypes.func.isRequired,
  neighborhood: PropTypes.string.isRequired,
  setNeighborhood: PropTypes.func.isRequired,
  city: PropTypes.string.isRequired,
  setCity: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  personalEmail: PropTypes.string.isRequired,
  setPersonalEmail: PropTypes.func.isRequired,
  realEmail: PropTypes.string.isRequired,
  setRealEmail: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
};

FirstStep.defaultProps = {
  totalSteps: 0,
  currentStep: 0,
  nextStep: () => {},
};
