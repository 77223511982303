import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { DivGroup } from '../styles';

import Context from '../Context';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';

import CnpjInput from 'components/masks/CnpjInput';

export default function SecondStep({ previousStep }) {
  const {
    razao_social,
    setRazaoSocial,
    cnpj,
    setCnpj,
    inscricao_social,
    setInscricaoSocial,
    business_address,
    setAddressBusiness,
    business_neighborhood,
    setNeighborhoodBusiness,
    business_city,
    setCityBusiness,
    business_state,
    setStateBusiness,

    handleSubmit,
  } = useContext(Context);

  return (
    <>
      <Input
        label="Razão Social"
        width="100%"
        value={razao_social}
        onChange={e => setRazaoSocial(e.target.value)}
      />
      <CnpjInput
        label="CNPJ"
        inputLabel
        width="100%"
        value={cnpj}
        onChange={cleanValue => setCnpj(cleanValue)}
      />
      <Input
        label="Inscriçao Social"
        width="100%"
        value={inscricao_social}
        onChange={e => setInscricaoSocial(e.target.value)}
      />
      <DivGroup>
        <Input
          label="Endereço Empresarial"
          width="45%"
          value={business_address}
          onChange={e => setAddressBusiness(e.target.value)}
        />
        <Input
          label="Bairro"
          width="45%"
          value={business_neighborhood}
          onChange={e => setNeighborhoodBusiness(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          label="Cidade"
          width="45%"
          value={business_city}
          onChange={e => setCityBusiness(e.target.value)}
        />
        <Input
          label="Estado"
          width="45%"
          value={business_state}
          onChange={e => setStateBusiness(e.target.value)}
        />
      </DivGroup>

      <DivGroup buttons>
        <Button type="text" size="50%" bg="grafit" onClick={previousStep}>
          Cancelar
        </Button>
        <Button type="text" size="50%" bg="info" onClick={handleSubmit}>
          Próximo
        </Button>
      </DivGroup>
    </>
  );
}

SecondStep.propTypes = {
  previousStep: PropTypes.func.isRequired,
};
