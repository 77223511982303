import styled from 'styled-components';

import { colors } from 'styles/global';

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border: none;
  border-spacing: 0 10px;
  border-collapse: separate;
`;

export const Thead = styled.thead`
  color: ${colors.white.default};
  font-size: 17px;

  &.no-padding-bottom {
    th {
      padding-bottom: 0 !important;
      width: 23%;

      &:last-child {
        width: 8%;
      }
    }
  }

  th {
    padding: 20px;
    font-weight: normal;

    &.text-center {
      text-align: center;
    }
  }

  th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const Tbody = styled.tbody`
  color: #999999;
  font-size: 17px;

  tr {
    transition: 500ms ease;

    td {
      background: #1f2940;

      &.text-center {
        text-align: center;
      }
    }

    &.green td {
      background: #00795b;
    }

    &.red td {
      background: #a60d11;
    }

    &.black td {
      background: #000000;
    }

    &.yellow td {
      background: #c4a200;
    }

    &.blue td {
      background: #0029fa;
      }
  }

  td {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 500ms ease;
    color: ${colors.white.default};

    a {
      color: ${colors.white.default};
    }
  }

  td:last-child {
    width: 1%;
    padding: 0;
    padding-right: 10px;
    text-align: right;

    &:not(.linear) {
      font-size: 30px;
      padding-right: 10px;
    }

    svg {
      cursor: pointer;
      transition: 200ms ease;
    }

    svg:hover {
      color: ${colors.info.default};
      transform: scale(1.2);
    }
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    svg {
      margin-top: 10px;
    }
  }
`;
