import styled, { css } from 'styled-components';

export const Form = styled.form``;

export const DivGroup = styled.div`
  display: flex;
  justify-content: ${props =>
    props.buttons ? 'space-evenly' : 'space-between'};
  align-items: center;
  ${props =>
    props.buttons &&
    css`
      margin-top: 15px;
    `}
`;

export const DivCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.last ? 'flex-end' : 'flex-start')};
  margin-top: 10px;
  width: 190px;

  div.check {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > span {
      margin-left: 5px;
      margin-right: 10px;
    }
  }
`;
