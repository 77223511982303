import React, { useState, useEffect } from 'react';

// import { Link } from 'react-router-dom';

import { format, addMonths, subMonths, getMonth } from 'date-fns';
import { pt } from 'date-fns/locale';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Container, Header, Body } from './styles';
import { Doughnut } from 'react-chartjs-2';
import Loading from 'components/Loading';
import { BsFillBarChartFill } from 'react-icons/bs';
import api from 'services/api';
import { useSelector } from 'react-redux';

export default function Home() {
  const [clients, setClients] = useState([]);
  const [planners, setPlanners] = useState([]);
  const [dateStart, setDateStart] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const { name } = useSelector(state => state.auth.profile);
  const { id } = useSelector(state => state.auth.profile);
  const [ activeContracts, setActiveContracts ] = useState(0);
  const [ disabledContracts, setDisabledContracts ] = useState(0);
  const [ allContracts, setAllContracts ] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const month = getMonth(dateStart);
      const response = await api.get(`birthdays_of_the_month/${month + 1}`);

      if(response.status === 200) {
        const { data } = response;

        setClients(data?.clients);
        setPlanners(data?.planners);
      }

      const responseContracts = await api.get(`users/${id}/contracts/count`, {
        params: {
          all: true,
          active: true,
        },
      });

      const amount = responseContracts.data;

      if(responseContracts.status === 200) {
        setAllContracts(amount.all);
        setActiveContracts(amount.active);
        setDisabledContracts(amount.all - amount.active);
      }

      setIsLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart]);

  function handleDatePicker(action) {
    if (action === 'next') {
      const newDate = addMonths(dateStart, 1);
      return setDateStart(newDate);
    }
    const previousDate = subMonths(dateStart, 1);
    return setDateStart(previousDate);
  }

  const options = {
    borderWidth: 0,
    maintainAspectRatio: false,
    hoverBorderWidth: 2,
    tooltips: {
      enabled: false, // hidden canvas hover
    },
    cutoutPercentage: 100,

    legend: {
      position: 'top',
      title: {
        display: true,
      },
      labels: {
        // boxWidth: 30,
        fontFamily: 'Nunito',
        // padding: 20,
        // lineCap: 'round',
        // fontStyle: 'bold',
        // fontSize: 200,
      },
    },
  };

  const data = {
    labels: ['Ativos', 'Inativos'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [activeContracts, disabledContracts],
        backgroundColor: ['#1de9b6', '#1399ff'],
        hoverOffset: 4,
      },
    ],
  };

  function percent(clients, total) {
    return (clients * 100) / total;
  }

  return (
    <Container>
      {isLoading ? <Loading></Loading> : <></>}
      <Header>
        <div className="container-title">
          <h1>Dashboard</h1>
          <p>Seja bem-vindo(a), {name} </p>
        </div>
        <div className="container-button">
          <div className="date">
            <button onClick={() => handleDatePicker('previous')}>
              <AiOutlineLeft size={18} />
            </button>
            <span>{format(dateStart, 'MMMM', { locale: pt })}</span>
            <button onClick={() => handleDatePicker('next')}>
              <AiOutlineRight size={18} />
            </button>
          </div>
        </div>
      </Header>

      <Body>
        <div className="cards-header">
          <div className="card-header">
            <span className="title">Clientes Ativos</span>
            <span className="value">
              {activeContracts}
              <span className="percent">
                <BsFillBarChartFill />
                {percent(activeContracts, allContracts).toLocaleString(
                  'en-IN',
                  { maximumSignificantDigits: 3 }
                )}
                %
              </span>
            </span>
          </div>
          <div className="card-header">
            <span className="title">Clientes Inativos</span>
            <span className="value">
              {disabledContracts}
              <span className="percent">
                <BsFillBarChartFill />
                {percent(
                  disabledContracts,
                  allContracts
                ).toLocaleString('en-IN', { maximumSignificantDigits: 3 })}
                %
              </span>
            </span>
          </div>
          <div className="card-header">
            <span className="title">Total</span>
            <span className="value">
              {allContracts}
              <span className="percent">
                <BsFillBarChartFill /> 100%
              </span>
            </span>
          </div>
        </div>
        <div className="cards-body">
          <div className="card graph">
            <Doughnut data={data} height="100px" options={options} />
          </div>

          <div className="card">
            <div className="header-card">
              <span>Clientes Aniversariantes</span>
              <span>Data</span>
            </div>
            <div className="body-card">
              {clients.map(client => (
                <div className="row" key={client.id}>
                  <span>{client.name}</span>
                  <span>
                    {format(new Date(client.contract.birthdate), 'dd/MM/yyyy')}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="card">
            <div className="header-card">
              <span>Planejadores Aniversariantes</span>
              <span>Data</span>
            </div>
            <div className="body-card">
              {planners.map(planner => (
                <div className="row" key={planner.id}>
                  <span>{planner.name}</span>
                  <span>
                    {format(
                      new Date(planner.plannerContract.birthdate),
                      'dd/MM/yyyy'
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Body>
    </Container>
  );
}
