import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import StepWizard from 'react-step-wizard';

import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Form } from './styles';

import Modal from 'components/Modal/Default';
import Dropdown from 'components/Button/Dropdown';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';

import Context from './Context';

import api from 'services/api';

export default function UpdateModal({
  selectedUser,
  setCustomers,
  setShowUpdateModal,
  setSelectedUser,
  setShowDeleteModal,
}) {
  const { contract } = selectedUser;

  const plannerContract = contract?.planner;
  const recommendationContract = contract?.recommendation;

  // Dados pessoais
  const [id] = useState(selectedUser?.id);
  const [name, setName] = useState(selectedUser?.name);
  const [email, setEmail] = useState(selectedUser?.email);
  const [phone, setPhone] = useState(contract?.phone);
  const [birthdate, setBirthdate] = useState(
    contract?.birthdate ? new Date(contract?.birthdate) : null
  );
  const [address, setAddress] = useState(contract?.address);
  const [neighborhood, setNeighborhood] = useState(contract?.neighborhood);
  const [city, setCity] = useState(contract?.city);
  const [state, setState] = useState(contract?.state);
  const [rg, setRg] = useState(contract?.rg);
  const [orgao, setOrgao] = useState(contract?.orgao);
  const [cpf, setCpf] = useState(contract?.cpf);

  // Dados do cônjuge
  const [partner_name, setPartnerName] = useState(contract?.partner_name);
  const [partner_email, setPartnerEmail] = useState(contract?.partner_email);
  const [partner_phone, setPartnerPhone] = useState(contract?.partner_phone);
  const [partner_birthdate, setPartnerBirthdate] = useState(
    contract?.partner_birthdate ? new Date(contract?.partner_birthdate) : null
  );
  const [zip, setZip] = useState(contract?.zip);

  // Dados do contrato
  const [recommendation, setRecommendation] = useState({
    value: recommendationContract?.name,
    lable: recommendationContract?.name,
    id: recommendationContract?.id,
  });
  const [planner, setPlanner] = useState({
    value: plannerContract?.name,
    label: plannerContract?.name,
    id: plannerContract?.id,
  });
  const [signature_date, setSignatureDate] = useState(
    contract?.signature_date ? new Date(contract?.signature_date) : null
  );
  const [contract_end_date, setContractEndDate] = useState(
    contract?.contract_end_date ? new Date(contract?.contract_end_date) : null
  );
  const [first_meeting_date, setFirstMeetingDate] = useState(
    contract?.first_meeting_date ? new Date(contract?.first_meeting_date) : null
  );
  const [billing_date, setBillingDate] = useState(contract?.billing_date);
  const [number_of_steps, setNumberOfSteps] = useState(
    contract?.number_of_steps
  );
  const [first_step_deadline, setFirstStepDeadline] = useState(
    contract?.first_step_deadline
  );
  const [second_step_deadline, setSecondStepDeadline] = useState(
    contract?.second_step_deadline
  );
  const [third_step_deadline, setThirdStepDeadline] = useState(
    contract?.third_step_deadline
  );
  const [first_fee, setFirstFee] = useState(contract?.first_fee);
  const [second_fee, setSecondFee] = useState(contract?.second_fee);
  const [third_fee, setThirdFee] = useState(contract?.third_fee);

  const token = useSelector(state => state.auth.token);

  const loggedUser = useSelector(state => state.session.profile);

  async function handleSubmit() {
    try {
      let data;
      if (email !== selectedUser.email) {
        const response = await api.put(`/users/${id}`, {
          name,
          email,
        });
        data = response.data;
      } else {
        const response = await api.put(`/users/${id}`, {
          name,
        });
        data = response.data;
      }

      await api.put(`/users/${id}/contracts`, {
        partner_name,
        partner_email,
        partner_phone,
        partner_birthdate,
        zip,
        signature_date,
        contract_end_date,
        first_meeting_date,
        billing_date,
        number_of_steps: Number(number_of_steps),
        first_step_deadline: Number(first_step_deadline),
        second_step_deadline: Number(second_step_deadline),
        third_step_deadline: Number(third_step_deadline),
        first_fee: Number(first_fee),
        second_fee: Number(second_fee),
        third_fee: Number(third_fee),
      });

      setCustomers(customers =>
        customers.map(customer => {
          const customer_id = customer[1].substr(1).toString();

          if (customer_id === data.id.toString()) {
            return [
              data.name,
              `#${data.id}`,
              '',
              format(new Date(data.created_at), 'dd/MM/yyyy'),
              '',
              '##############',
              <Dropdown>
                <button
                  onMouseDown={() => {
                    setShowUpdateModal(true);
                    setSelectedUser(selectedUser);
                  }}
                >
                  Editar
                </button>
                <button
                  onMouseDown={() => {
                    setShowDeleteModal(true);
                    setSelectedUser(selectedUser);
                  }}
                >
                  Excluir
                </button>
                <button
                  onMouseDown={() => {
                    window.open(
                      `${process.env.REACT_APP_CLIENT_URL ||
                        'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                        customer.id
                      }&external_user_id=${loggedUser.id}`
                    );
                  }}
                >
                  Yuno On
                </button>
              </Dropdown>,
            ];
          }
          return customer;
        })
      );

      toast.success('Editado com sucesso');
      setShowUpdateModal(false);
    } catch (err) {
      toast.error('Falha ao editar dados');
    }
  }

  return (
    <Modal color="info" title="Editar Cliente" icon={null}>
      <Form onSubmit={e => e.preventDefault()}>
        <Context.Provider
          value={{
            recommendation,
            setRecommendation,
            planner,
            setPlanner,
            name,
            setName,
            email,
            setEmail,
            phone,
            setPhone,
            birthdate,
            setBirthdate,
            address,
            setAddress,
            neighborhood,
            setNeighborhood,
            city,
            setCity,
            state,
            setState,
            rg,
            setRg,
            orgao,
            setOrgao,
            cpf,
            setCpf,
            partner_name,
            setPartnerName,
            partner_email,
            setPartnerEmail,
            partner_phone,
            setPartnerPhone,
            partner_birthdate,
            setPartnerBirthdate,
            zip,
            setZip,
            signature_date,
            setSignatureDate,
            contract_end_date,
            setContractEndDate,
            first_meeting_date,
            setFirstMeetingDate,
            billing_date,
            setBillingDate,
            number_of_steps,
            setNumberOfSteps,
            first_step_deadline,
            setFirstStepDeadline,
            second_step_deadline,
            setSecondStepDeadline,
            third_step_deadline,
            setThirdStepDeadline,
            first_fee,
            setFirstFee,
            second_fee,
            setSecondFee,
            third_fee,
            setThirdFee,
            handleSubmit,
            setShowUpdateModal,
          }}
        >
          <StepWizard>
            <FirstStep />
            <SecondStep />
            <ThirdStep />
          </StepWizard>
        </Context.Provider>
      </Form>
    </Modal>
  );
}

UpdateModal.propTypes = {
  selectedUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    contract: PropTypes.object.isRequired,
  }).isRequired,
  setCustomers: PropTypes.func.isRequired,
  setShowUpdateModal: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
};
