import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import { Link, useParams } from 'react-router-dom';

import { format } from 'date-fns';

import {
  AiOutlineCaretLeft,
  AiOutlineCaretRight,
  AiOutlineSearch,
} from 'react-icons/ai';

import ReactPaginate from 'react-paginate';

import api from 'services/api';

import { Container, Header, ContainerPaginate, DivInput } from './styles';

import debounce from 'utils/debounce';

import ModalCreate from './Modals/ModalCreate';
import ModalUpdate from './Modals/ModalUpdate';

import Button from 'components/Button';
import Table from 'components/Table/Default';
import Dropdown from 'components/Button/Dropdown';
import Can from 'components/Can';

import userName from 'utils/userName';

export default function Customers() {
  const [customers, setCustomers] = useState([]);

  const [page, setPage] = useState(1);

  const [pageCount, setPageCount] = useState(1);

  const [showInput, setShowInput] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [, setShowDeleteModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState({});

  // Value do input de pesquisa
  const [searchInputCustomer, setInputCustomer] = useState('');

  const inputRef = useRef();

  const { pfg_class_id } = useParams();

  const token = useSelector(state => state.auth.token);

  const loggedUser = useSelector(state => state.session.profile);

  // Busca os dados vindos da api
  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`/pfg_classes/${pfg_class_id}/clients`, {
        params: {
          with_contract: true,
          per_page: 4,
        },
      });

      setCustomers(
        data.data.map(customer => [
          <Link to={`/customers/${customer.id}/contracts`}>
            {userName(customer)}
          </Link>,
          `#${customer.id}`,
          customer.email,
          format(new Date(customer.created_at), 'dd/MM/yyyy'),
          <Dropdown>
            <button
              onMouseDown={() => {
                setShowUpdateModal(true);
                setSelectedUser(customer);
              }}
            >
              Editar
            </button>
            <button
              onMouseDown={() => {
                setShowDeleteModal(true);
                setSelectedUser(customer);
              }}
            >
              Excluir
            </button>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    customer.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Yuno On
            </button>
          </Dropdown>,
        ])
      );
    }
    fetchData();
  }, [loggedUser.id, pfg_class_id, token]);

  // Busca os dados a partir do input de pesquisa
  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`/pfg_classes/${pfg_class_id}/clients`, {
        params: {
          per_page: 4,
          name: searchInputCustomer,
          with_contract: true,
        },
      });

      setPageCount(data.lastPage);
      setPage(1);

      setCustomers(
        data.data.map(customer => [
          <Link to={`/customers/${customer.id}/contracts`}>
            {userName(customer)}
          </Link>,
          `#${customer.id}`,
          customer.email,
          format(new Date(customer.created_at), 'dd/MM/yyyy'),
          <Dropdown>
            <button
              onMouseDown={() => {
                setShowUpdateModal(true);
                setSelectedUser(customer);
              }}
            >
              Editar
            </button>
            <button
              onMouseDown={() => {
                setShowDeleteModal(true);
                setSelectedUser(customer);
              }}
            >
              Excluir
            </button>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    customer.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Yuno On
            </button>
          </Dropdown>,
        ])
      );
    }
    debounce(fetchData, 800);
  }, [loggedUser.id, pfg_class_id, searchInputCustomer, token, page]);

  // Disparada quando clicar em um elemento da paginação
  function handlePageClick(data) {
    setPage(data.selected + 1);
  }

  return (
    <>
      <Container>
        <Header>
          <div />
          <div className="right">
            <div>
              <DivInput>
                <button
                  onClick={() => {
                    setShowInput(!showInput);
                    inputRef.current.focus();
                  }}
                  type="submit"
                >
                  <AiOutlineSearch size={35} />
                </button>
                <input
                  ref={inputRef}
                  className={showInput ? 'show' : ''}
                  type="text"
                  placeholder="Pesquisar..."
                  value={searchInputCustomer}
                  onChange={e => setInputCustomer(e.target.value)}
                />
              </DivInput>
            </div>
            <Can canRoles={['adm']}>
              <Button size={45} bg="info" onClick={() => setShowModal(true)}>
                Novo Aluno
              </Button>
            </Can>
          </div>
        </Header>
        <Table
          head={['Nome', 'ID', 'Email', 'Criado em', '']}
          body={customers}
        />
      </Container>
      <ContainerPaginate>
        <ReactPaginate
          previousLabel={<AiOutlineCaretLeft size={22} />}
          pageCount={pageCount}
          previousLinkClassName="previous_page"
          nextLabel={<AiOutlineCaretRight size={22} />}
          nextLinkClassName="next_page"
          activeClassName="active"
          onPageChange={handlePageClick}
        />
      </ContainerPaginate>
      {showModal && (
        <ModalCreate
          setShowModal={setShowModal}
          pfg_class_id={Number(pfg_class_id)}
          setCustomers={setCustomers}
        />
      )}
      {showUpdateModal && (
        <ModalUpdate
          selectedUser={selectedUser}
          setCustomers={setCustomers}
          setShowUpdateModal={setShowUpdateModal}
          setSelectedUser={setSelectedUser}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
    </>
  );
}
