import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input/InputLabel';

export default function PhoneInput({ value, onChange, inputLabel, ...rest }) {
  const [input, setInput] = useState(value);

  useEffect(() => {
    const result = maskInput(value);

    setInput(result);
  }, [value]);

  function maskInput(value) {
    if (!value) {
      return '';
    }

    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(.)/, '$1');
  }

  function cleanMask(value) {
    return value.replace(/\D/g, '');
  }

  function handleChange(e) {
    const result = maskInput(e.target.value);
    const cleanResult = cleanMask(result);
    setInput(result);
    onChange(cleanResult, result);
  }
  if (inputLabel) {
    return <Input value={input} onChange={handleChange} {...rest} />;
  }
  return <input value={input} onChange={handleChange} {...rest} />;
}
PhoneInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputLabel: PropTypes.bool,
  onChange: PropTypes.func,
};

PhoneInput.defaultProps = {
  value: '',
  inputLabel: false,
  onChange: () => {},
};
