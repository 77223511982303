import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import Context from '../Context';

import { DivGroup } from '../styles';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';

export default function SecondStep({ nextStep, previousStep }) {
  const {
    partner_name,
    setPartnerName,
    partner_email,
    setPartnerEmail,
    partner_phone,
    setPartnerPhone,
    partner_birthdate,
    setPartnerBirthdate,
    zip,
    setZip,
  } = useContext(Context);

  return (
    <>
      <Input
        label="Nome do Cônjuge"
        width="100%"
        value={partner_name}
        onChange={e => setPartnerName(e.target.value)}
      />
      <Input
        label="Email do Cônjuge"
        width="100%"
        value={partner_email}
        onChange={e => setPartnerEmail(e.target.value)}
      />
      <DivGroup>
        <Input
          label="Telefone"
          width="40%"
          value={partner_phone}
          onChange={e => setPartnerPhone(e.target.value)}
        />
        <Input
          type="date"
          label="Data de Nascimento"
          width="45%"
          value={
            partner_birthdate
              ? format(partner_birthdate, 'yyyy-MM-dd')
              : new Date()
          }
          onChange={e => setPartnerBirthdate(e.target.value)}
        />
      </DivGroup>
      <Input
        label="CEP:"
        width="45%"
        value={zip}
        onChange={e => setZip(e.target.value)}
      />
      <DivGroup buttons>
        <Button type="text" size="50%" bg="grafit" onClick={previousStep}>
          Voltar
        </Button>
        <Button type="text" size="50%" bg="info" onClick={nextStep}>
          Próximo
        </Button>
      </DivGroup>
    </>
  );
}

SecondStep.propTypes = {
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
};

SecondStep.defaultProps = {
  previousStep: () => {},
  nextStep: () => {},
};
