import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DivGroup } from '../styles';

import Context from '../Context';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';

import { PhoneInput, CepInput } from 'components/masks';

export default function SecondStep({ previousStep, nextStep }) {
  const {
    partner_name,
    setPartnerName,
    partner_email,
    setPartnerEmail,
    partner_phone,
    setPartnerPhone,
    partner_birthdate,
    setPartnerBirthdate,
    zip,
    setZip,
  } = useContext(Context);

  return (
    <>
      <Input
        label="Nome do Cônjuge"
        width="100%"
        value={partner_name}
        onChange={e => setPartnerName(e.target.value)}
      />
      <Input
        label="Email do Cônjuge"
        width="100%"
        value={partner_email}
        onChange={e => setPartnerEmail(e.target.value)}
      />
      <DivGroup>
        <PhoneInput
          label="Telefone do Cônjuge"
          inputLabel
          width="45%"
          value={partner_phone}
          onChange={cleanValue => setPartnerPhone(cleanValue)}
        />
        <Input
          type="date"
          label="Data de Nascimento"
          width="45%"
          value={partner_birthdate}
          onChange={e => setPartnerBirthdate(e.target.value)}
        />
      </DivGroup>
      <CepInput
        label="CEP"
        inputLabel
        width="100%"
        value={zip}
        onChange={cleanValue => setZip(cleanValue)}
      />
      <DivGroup buttons>
        <Button bg="grafit" size="30" onClick={previousStep}>
          Voltar
        </Button>
        <Button bg="info" size="30" onClick={nextStep}>
          Próximo
        </Button>
      </DivGroup>
    </>
  );
}

SecondStep.propTypes = {
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
};

SecondStep.defaultProps = {
  previousStep: () => {},
  nextStep: () => {},
};
