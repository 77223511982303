import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Card({ width, height, title, alignItems, children }) {
  return (
    <Container width={width} height={height}>
      {title && <h1>{title}</h1>}
      <div className="card">{children}</div>
    </Container>
  );
}

Card.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  alignItems: PropTypes.string,

  children: PropTypes.element,
};

Card.defaultProps = {
  width: '100%',
  height: '100%',
  alignItems: 'center',
};
