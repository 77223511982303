import styled from 'styled-components';

import { colors } from 'styles/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow-y: auto;

  padding-right: 10px;
  padding-left: 5px;

  div.currentStep {
    display: flex;
    justify-content: center;
    h3 {
      font-family: 'Nunito', sans-serif;
      color: ${colors.grafit.hover};
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
    }
  }
`;

export const DivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1%;

  &.buttonsForm {
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-left: 2%;
    }
  }
`;
