import { useState, useEffect } from 'react';
import api from 'services/api';

const useProfiles = (limit) => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {

        const { data } = await api.get('/profiles', {
          params: {
            page,
            per_page: limit,
          },
        });
        setProfiles(data.profiles.data);
        setPageCount(data.profiles.lastPage);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, limit]);

  return { profiles, loading, setPage, pageCount, setProfiles };
};

export default useProfiles;
