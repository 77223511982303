import React from 'react';

import PropTypes from 'prop-types';

import { Form } from '../styles';

import Modal from 'components/Modal/Default';

import Button from 'components/Button';

export default function CreateModal({ setShowModal }) {
  return (
    <Modal title="Nova transação" color="info">
      <Form>
        <div className="divGroup">
          <Button stripped bg="grafit" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button bg="info">Confirmar</Button>
        </div>
      </Form>
    </Modal>
  );
}

CreateModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};
