import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  margin-top: 8%;
  width: 100%;
`;
