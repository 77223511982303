import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DivGroup } from '../styles';

import Context from '../Context';

import { PhoneInput, CpfInput } from 'components/masks';

import Select from 'components/Input/Dropdown';
import Input from 'components/Input/InputLabel';
import Button from 'components/Button';
import api from 'services/api';

export default function FirstStep({ nextStep }) {
  const [allPlanners, setAllPlanners] = useState([]);

  const {
    setShowModal,

    setPlannerId,

    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    birthdate,
    setBirthdate,
    address,
    setAddress,
    neighborhood,
    setNeighborhood,
    city,
    setCity,
    state,
    setState,
    rg,
    setRg,
    orgao,
    setOrgao,
    cpf,
    setCpf,
  } = useContext(Context);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get('/users', {
        params: {
          user_type: 'planner',
          all: true,
        },
      });
      setAllPlanners(
        data.map(planner => ({
          label: planner.name,
          value: planner.name,
          id: planner.id,
        }))
      );
    }
    fetchData();
  }, []);

  return (
    <>
      <Select
        label="Recomendação"
        placeholder="Foi recomendado?"
        width="100%"
        options={[{}]}
      />
      <Select
        label="Planejador"
        placeholder="Qual planejador?"
        width="100%"
        options={allPlanners}
        onChange={selected => setPlannerId(selected.id)}
      />
      <Input
        label="Nome Completo"
        width="100%"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Input
        label="Email"
        width="100%"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <DivGroup>
        <PhoneInput
          label="Telefone"
          width="45%"
          inputLabel
          value={phone}
          onChange={cleanValue => setPhone(cleanValue)}
        />
        <Input
          type="date"
          label="Data de Nascimento"
          width="45%"
          value={birthdate}
          onChange={e => setBirthdate(e.target.value)}
        />
      </DivGroup>
      <CpfInput
        label="CPF"
        width="45%"
        inputLabel
        value={cpf}
        onChange={cleanValue => setCpf(cleanValue)}
      />
      <DivGroup>
        <Input
          label="RG"
          width="45%"
          value={rg}
          onChange={e => setRg(e.target.value)}
        />
        <Input
          label="Orgão emissor"
          width="45%"
          value={orgao}
          onChange={e => setOrgao(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          label="Endereço"
          width="45%"
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
        <Input
          label="Bairro"
          width="45%"
          value={neighborhood}
          onChange={e => setNeighborhood(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          label="Estado"
          width="45%"
          value={state}
          onChange={e => setState(e.target.value)}
        />
        <Input
          label="Cidade"
          width="45%"
          value={city}
          onChange={e => setCity(e.target.value)}
        />
      </DivGroup>
      <DivGroup buttons>
        <Button bg="grafit" size="30" onClick={() => setShowModal(false)}>
          Cancelar
        </Button>
        <Button bg="info" size="30" onClick={nextStep}>
          Próximo
        </Button>
      </DivGroup>
    </>
  );
}

FirstStep.propTypes = {
  nextStep: PropTypes.func,
};

FirstStep.defaultProps = {
  nextStep: () => {},
};
