import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input/InputLabel';

export default function CepInput({ value, onChange, inputLabel, ...rest }) {
  const [input, setInput] = useState(value ? value.toString() : '');

  useEffect(() => {
    const result = maskInput(value);
    setInput(result);
  }, [value]);

  function maskInput(value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})(.)/, '$1');
  }

  function cleanMask(value) {
    return value.replace(/\D/g, '');
  }

  function handleChange(e) {
    const result = maskInput(e.target.value);
    const cleanResult = cleanMask(result);
    onChange(cleanResult, result);
    setInput(result);
  }

  if (inputLabel) {
    return <Input value={input} onChange={handleChange} {...rest} />;
  }

  return <input value={input} onChange={handleChange} {...rest} />;
}

CepInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputLabel: PropTypes.bool,
  onChange: PropTypes.func,
};

CepInput.defaultProps = {
  value: '',
  inputLabel: false,
  onChange: () => {},
};
