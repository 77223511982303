import { useState } from 'react';
import api from 'services/api';
import { toast } from 'react-toastify';
const usePostProfiles = (profile) => {
  const [loading, setLoading] = useState(false);
  const [returnedProfile, setReturnedProfile] = useState({})

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!profile.role) {
      return toast.warn('O campo permissões é obrigatório');
    }
    try {
      setLoading(true);
      const { data } = await api.post(`/profiles/${profile.role}`, profile);
      toast.success(`Usuários do perfil ${profile.role} foi criado com sucesso!`);
      setReturnedProfile(data);
    } catch (error) {
      toast.error('Falha ao criar perfil! Verifique os dados e tente novamente');
      console.error('Error on usePostProfiles', error);
    } finally {
      setLoading(false);
    }
  };


  return { handleSubmit, loading, returnedProfile };
};

export default usePostProfiles;
