import React from 'react';
import Table from 'components/Table/Default';
import ProfileLink from 'components/atoms/ProfileLink';
import DateFormatter from 'components/atoms/DateFormatter';
import ProfileActions from 'components/molecules/ProfileActions';

const ProfilesTable = ({
  profiles,
  setSelectedProfile,
  setShowModalExclude,
}) => {
  return (
    <>
      <Table
        head={['Nome', 'ID', 'Criado em', '']}
        body={profiles.map(profile => [
          <ProfileLink id={profile.id} name={profile.name} />,
          <span>{profile.id}</span>,
          <DateFormatter date={profile.created_at} />,
          <ProfileActions
            onDelete={() => {
              setSelectedProfile(profile);
              setShowModalExclude(true);
            }}
          />,
        ])}
      />
    </>
  );
};

export default ProfilesTable;
