import React, { useState } from 'react';

import pagarme from 'pagarme';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import { Form } from '../styles';

import Modal from 'components/Modal/Default';
import Input from 'components/Input/InputLabel';
import Button from 'components/Button';

import api from 'services/api';

export default function CreateModal({ setShowModal, setCards, userId }) {
  const [card_number, setCardNumber] = useState('');
  const [card_holder_name, setCardHolderName] = useState('');
  const [card_expiration_date, setCardExpirationDate] = useState('');
  const [card_cvv, setCardCvv] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    setLoading(true);
    try {
      e.preventDefault();
      const connection = await pagarme.client.connect({
        encryption_key: 'ek_live_3zHcUL5nMh3h7m65P3ub4CzTVcM7Aq',
      });

      const card_hash = await connection.security.encrypt({
        card_number,
        card_holder_name,
        card_expiration_date: card_expiration_date.replace(/\D/g, ''),
        card_cvv,
      });

      const { data } = await api.post(`users/${userId}/cards`, { card_hash });

      toast.success('Cadastro de cartão realizado com sucesso');

      setCards(oldState => [...oldState, data]);
    } catch (err) {
      setShowModal(false);
      if (
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.message
      ) {
        toast.error(err?.response?.data?.error?.message);
        return;
      }

      toast.error('Tivemos um erro');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal title="Novo cartão" color="info">
      <Form onSubmit={handleSubmit}>
        <Input
          label="Número do cartão"
          value={card_number}
          onChange={e => setCardNumber(e.target.value)}
        />
        <Input
          label="Nome completo"
          value={card_holder_name}
          onChange={e => setCardHolderName(e.target.value)}
        />
        <Input
          label="Data de validade"
          value={card_expiration_date}
          onChange={e => {
            const { value } = e.target;

            setCardExpirationDate(
              value
                .replace(/\D/g, '')
                .replace(/(\d{2})(\d{1})/g, '$1/$2')
                .replace(/(\/)(\d{2})(.)/g, '$1$2')
            );
          }}
        />
        <Input
          label="Código de segurança"
          value={card_cvv}
          onChange={e => setCardCvv(e.target.value)}
        />

        <div className="divGroup">
          <Button stripped bg="grafit" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button bg="info">{loading ? 'Carregando...' : 'Confirmar'}</Button>
        </div>
      </Form>
    </Modal>
  );
}

CreateModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setCards: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};
