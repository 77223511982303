import styled from 'styled-components';
import { colors } from 'styles/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 100px;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  div.container-title {
    display: flex;
    flex-direction: column;

    h1 {
      margin: unset;
      font-weight: 700;
      color: ${colors.white.default};
    }
    p {
      color: white;
    }
  }
  div.container-button {
    display: flex;

    div.date {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1f2940;
      border-radius: 110px;
      padding: 5px 5px;

      span {
        color: ${colors.white.default};
        font-weight: 700;
        font-size: 18px;
      }
    }

    button {
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00b4db;
      border-radius: 100%;
      padding: 5px 5px;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }

      svg {
        fill: ${colors.white.default};
      }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;

  justify-content: space-between;
  div.cards-header {
    display: flex;
    justify-content: space-between;
    height: 90px;
    width: 640px;
    /* background: pink; */
    div.card-header {
      height: 100%;
      width: 200px;
      border-radius: 10px;
      background: #1f2940;
      box-shadow: 6px 6px 6px 0 rgb(0 0 0 / 16%);
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span.title {
        color: white;
        font-size: 16px;
        font-weight: 100;
        &.date {
          border-bottom: 1px solid ${colors.primary.default};
        }
      }
      span.value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        font-weight: 700;
        font-size: 25px;
        span.percent {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 30%;
          font-size: 12px;
          color: ${colors.primary.hover};
          svg {
            fill: ${colors.primary.hover};
          }
        }
      }
      svg {
        fill: white;
      }
      &.date {
        align-items: center;
      }
    }
  }
  div.cards-body {
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: space-between;
    margin-top: 30px;

    @media(max-height: 750px){
      height: 320px;
      
    }

    @media(max-height: 678px){
      height: 270px;
      
    }

    @media(max-width : 1255px ){
      span{
        font-size: 12px;
      }
    }
    
   
    div.card {
      width: 36%;
      height: 100%;
      background: #1f2940;
      border-radius: 15px;
      &.graph {
        width: 25%;
        canvas {
          margin: 20px;
        }
      }

      div.header-card {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 1%;
        padding-left: 5%;
        padding-right: 11%;
        span {
          font-weight: 600;
          color: ${colors.white.default};
        }
      }
      div.body-card {
        display: flex;
        flex-direction: column;
        width: 98%;
        height: 90%;
        overflow-y: scroll;
        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${colors.white.default};
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: ${colors.primary.default};
        }
        div.row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 5%;
          padding-right: 5%;
          /* border-bottom: .5px solid ${colors.primary.default}; */
          width: 97%;
          background: #1f2940;
          margin-top: 5px;
          span {
            display: flex;
            align-items: center;
            height: 40px;
            color: ${colors.white.default};
          }
        }
      }
    }
  }
`;
