import axios from 'axios';
// import { store } from 'store';
// import { signOut } from 'store/modules/auth/actions';

// import history from 'services/history';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3333',
});

api.interceptors.response.use(
  response => response
  // err => {
  //   if (
  //     (err.response.status === 401 &&
  //       err.response.data.error.name === 'InvalidJwtToken') ||
  //     err.response.data.error.name === 'ExpiredJwtToken'
  //   ) {
  //     history.push('/sign_out');
  //     store.dispatch(signOut());
  //   }
  //   return Promise.reject(err);
  // }
);

export const api_ibge = axios.create({
  baseURL: 'https://servicodados.ibge.gov.br/api/v1/localidades',
});

export const api_nort = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_NEW_API || 'https://realteste.eastus.cloudapp.azure.com/TESTE_RealeasyAPI/api',
  // baseURL: process.env.REACT_APP_BASE_URL_NEW_API || 'http://localhost:55555/api',
});

export default api;
