import styled, { keyframes } from 'styled-components';
import { colors } from 'styles/global';
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(40px);
  width: 100%;
  height: 100%;
  color: ${colors.secondary.disabled};

  svg {
    width: 35%;
    height: 35%;
    animation: ${spin} 2s linear infinite;
  }
`;
