import React, { useState, useEffect } from 'react';
import { Container, DivNewCard } from '../styles';
import Button from 'components/Button';
import CreateModal from './Modals/CreateModal';
import api from '../../../../../services/api';
import Card from 'components/Card';

export default function CreditCard({ match }) {
  const { id } = match.params;

  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    api.get(`users/${id}/cards`).then(response => {
      setCards(response.data);
    });
  }, [id]);

  return (
    <>
      <Container>
        <DivNewCard>
          <Button bg="info" size={8} onClick={() => setShowModal(true)}>
            Novo Cartão
          </Button>
        </DivNewCard>
        {cards.map(card => (
          <Card key={card.id} height="200px" width="50%">
            <>
              <small>Brand</small>
              <span>{card.brand}</span>
              <small>Nome da pessoa</small>
              <span>{card.holder_name}</span>
              <small>Últimos dígitos</small>
              <span>{card.last_digits}</span>
            </>
          </Card>
        ))}
      </Container>
      {showModal && (
        <CreateModal
          userId={id}
          setCards={setCards}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
}
