import React, { useState, useEffect } from 'react';

import { useLastLocation } from 'react-router-last-location';

import { Icon } from 'antd';

import StepWizard from 'react-step-wizard';

import { AiOutlineCaretRight, AiOutlineCaretLeft } from 'react-icons/ai';

import ReactPaginate from 'react-paginate';

import { Link } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';

import { toast } from 'react-toastify';

import * as Yup from 'yup';

import api, { api_nort } from 'services/api';

import {
  Container,
  Header,
  ContainerPaginate,
  DivButtons,
  Form,
} from './styles';

import UpdateModal from './UpdateModal';
import Modal from 'components/Modal/Default';
import Table from 'components/Table/Default';
import Button from 'components/Button';
import Dropdown from 'components/Button/Dropdown';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import { format } from 'date-fns';
import Loading from 'components/Loading';

export default function Planners() {
  // Dados pessoais
  const [name, setName] = useState('');
  const [rg, setRg] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [personalEmail, setPersonalEmail] = useState('');
  const [realEmail, setRealEmail] = useState('');

  // Dados do contrato
  const [razao_social, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricao_social, setInscricaoSocial] = useState('');
  const [business_address, setAddressBusiness] = useState('');
  const [business_neighborhood, setNeighborhoodBusiness] = useState('');
  const [business_city, setCityBusiness] = useState('');
  const [business_state, setStateBusiness] = useState('');

  // Handle modal
  const [activeModal, setActiveModal] = useState(false);

  // Handle update modal
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // Hanle delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Planners da api
  const [planners, setPlanners] = useState([]);

  const [selectedUser, setSelectedUser] = useState([]);

  const [page, setPage] = useState(0);

  const [pageCount, setPageCount] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  // Recebe a rota anterior acessada
  const location = useLastLocation();

  const headTable = ['Nome', 'ID', 'Nº de Clientes', 'Último acesso', ''];

  const divStyle = {
    textAlign: 'center',
  };

  const fetchPlanners = async () => {
    setIsLoading(true);
    const response = await api_nort.get(`/Usuario/planner/getall/${page}`);
    if (response?.data) {
      setPageCount(response.data.total / 10);
      const resultadoLista = response.data.data.map(planner => {
        return {
          id: planner.id,
          nome: planner.nome,
          quantidade_cliente: planner.quantidade_cliente,
          data_ultimo_acesso:
            planner.data_ultimo_acesso === null
              ? '-'
              : format(new Date(planner.data_ultimo_acesso), 'dd/MM/yyyy'),
        };
      });

      setPlanners(
        resultadoLista.map(item => [
          <Link to={`/planners/${item.id}`}>{item.nome}</Link>,
          `#${item.id}`,
          <div style={divStyle}>{item.quantidade_cliente}</div>,
          item.data_ultimo_acesso,
          <div className="div-icons">
            <div className="icons">
              <Dropdown>
                <button
                  onMouseDown={() => {
                    setShowUpdateModal(!showUpdateModal);
                    setSelectedUser(item);
                  }}
                >
                  Editar
                </button>
                <button
                  onMouseDown={() => {
                    setShowDeleteModal(true);
                    setSelectedUser(item);
                  }}
                >
                  Excluir
                </button>
                <button>Acessar Yuno On</button>
              </Dropdown>
            </div>
          </div>,
        ])
      );
    }
    setIsLoading(false);
  };

  function handleShowModal() {
    setActiveModal(true);
  }

  //Chama a função fetchPlanners para executar a rota que alimenta a grid de clientes
  useEffect(() => {
    fetchPlanners();
    // eslint-disable-next-line
  }, [page]);

  async function handleDeleteUser(user_id) {
    try {
      await api.delete(`/users/${user_id}`);

      setPlanners(planners =>
        planners.filter(planner => {
          const planner_id = planner[1].substr(1).toString();

          return planner_id !== selectedUser.id.toString();
        })
      );
    } catch (err) {
      toast.error('Falha ao excluir planejador');
    }
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      const type = 'planner';

      // A senha por padrão será o cpf do usuário
      const password = cpf;

      try {
        const schema = Yup.object().shape({
          // begin user
          name: Yup.string().required('Nome é obrigatório'),
          emailValidate: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um email Real válido'),
          passwordValidate: Yup.string().required('Senha é obrigatória'),
          // end user
          phoneValidate: Yup.string().required('Telefone é obrigatório'),
          personal_emailValidate: Yup.string()
            .required('E-mail pessoal é obrigatório')
            .email('Digite um email pessoal válido'),
          birthdateValidate: Yup.string().required(
            'Data de Nascimento é obrigatória'
          ),
          addressValidate: Yup.string().required('O endereço é obrigatório'),
          neighborhoodValidate: Yup.string().required('Bairro é obrigatório'),
          cityValidate: Yup.string().required('Cidade é obrigatória'),
          stateValidate: Yup.string().required('Estado é obrigatório'),
          rgValidate: Yup.string().required('RG é obrigatório'),
          cpfValidate: Yup.string().required('CPF é obrigatório'),
          razao_socialValidate: Yup.string().required(
            'Razão social é obrigatória'
          ),
          inscricao_socialValidate: Yup.string().required('Inscrição Estadual'),
          cnpjValidate: Yup.string().required('CNPJ é obrigatório'),
          business_addressValidate: Yup.string().required(
            'Endereço Empresarial é obrigatório'
          ),
          business_neighborhoodValidate: Yup.string().required(
            'Bairro Empresarial é obrigatório'
          ),
          business_cityValidate: Yup.string().required(
            'Cidade empresarial é obrigatório'
          ),
          business_stateValidate: Yup.string().required(
            'O estado empresarial é obrigatório'
          ),
        });

        await schema.validate(
          {
            // begin user
            name,
            emailValidate: realEmail,
            passwordValidate: cpf,
            // end user
            phoneValidate: phone,
            personal_emailValidate: personalEmail,
            birthdateValidate: birthdate,
            addressValidate: address,
            neighborhoodValidate: neighborhood,
            cityValidate: city,
            stateValidate: state,
            rgValidate: rg,
            cpfValidate: cpf,
            razao_socialValidate: razao_social,
            inscricao_socialValidate: inscricao_social,
            cnpjValidate: cnpj,
            business_addressValidate: business_address,
            business_neighborhoodValidate: business_neighborhood,
            business_cityValidate: business_city,
            business_stateValidate: business_state,
          },
          { abortEarly: false }
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.errors.map(error => toast.error(error.toString()));
          // toast.error(err.errors.toString());
          return;
        }
      }

      const { data } = await api.post('/users', {
        name,
        email: personalEmail,
        password,
        type,
      });

      await api.post(`/planners/${data.id}/contracts`, {
        phone,
        personal_email: realEmail,
        birthdate,
        address,
        neighborhood,
        city,
        state,
        rg,
        cpf,
        razao_social,
        inscricao_social,
        cnpj,
        business_address,
        business_neighborhood,
        business_city,
        business_state,
      });
      await api.post(`/users/${data.id}/roles`, {
        role_id: 2,
      });

      toast.success('Contrato criado com sucesso');

      setName('');
      setRg('');
      setBirthdate('');
      setCpf('');
      setPhone('');
      setAddress('');
      setNeighborhood('');
      setCity('');
      setState('');
      setPersonalEmail('');
      setRealEmail('');

      setRazaoSocial('');
      setCnpj('');
      setInscricaoSocial('');
      setAddressBusiness('');
      setNeighborhoodBusiness('');
      setCityBusiness('');
      setStateBusiness('');
      setActiveModal(false);
    } catch (err) {}
  }

  return (
    <>
      <Container>
        {isLoading && <Loading />}
        <Header>
          <aside>
            <Link to={location ? location.pathname : '#'} data-tip="Voltar">
              <Icon type="import" />
            </Link>
            <p>Planejadores</p>
          </aside>
          <div className="right-bar">
            <div className="right-bar-buttons">
              <Button size={35} bg="info" onClick={handleShowModal}>
                Novo Planejador
              </Button>
            </div>
          </div>
        </Header>
        <Table head={headTable} body={planners} />
        <ContainerPaginate>
          <ReactPaginate
            previousLabel={<AiOutlineCaretLeft size={22} />}
            pageCount={pageCount}
            previousLinkClassName="previous_page"
            nextLabel={<AiOutlineCaretRight size={22} />}
            nextLinkClassName="next_page"
            activeClassName="active"
            onPageChange={data => setPage(data.selected * 10)}
          />
        </ContainerPaginate>
      </Container>

      {activeModal && (
        <>
          <Modal title="Novo Planejador" icon={null}>
            <Form onSubmit={e => e.preventDefault()}>
              <StepWizard>
                <FirstStep
                  name={name}
                  setName={setName}
                  rg={rg}
                  setRg={setRg}
                  birthdate={birthdate}
                  setBirthdate={setBirthdate}
                  cpf={cpf}
                  setCpf={setCpf}
                  phone={phone}
                  setPhone={setPhone}
                  address={address}
                  setAddress={setAddress}
                  neighborhood={neighborhood}
                  setNeighborhood={setNeighborhood}
                  city={city}
                  setCity={setCity}
                  state={state}
                  setState={setState}
                  personalEmail={personalEmail}
                  setPersonalEmail={setPersonalEmail}
                  realEmail={realEmail}
                  setRealEmail={setRealEmail}
                  setActiveModal={setActiveModal}
                />
                <SecondStep
                  razao_social={razao_social}
                  setRazaoSocial={setRazaoSocial}
                  cnpj={cnpj}
                  setCnpj={setCnpj}
                  inscricao_social={inscricao_social}
                  setInscricaoSocial={setInscricaoSocial}
                  business_address={business_address}
                  setAddressBusiness={setAddressBusiness}
                  business_neighborhood={business_neighborhood}
                  setNeighborhoodBusiness={setNeighborhoodBusiness}
                  business_city={business_city}
                  setCityBusiness={setCityBusiness}
                  business_state={business_state}
                  setStateBusiness={setStateBusiness}
                  handleSubmit={handleSubmit}
                />
              </StepWizard>
            </Form>
          </Modal>
        </>
      )}
      {showUpdateModal && (
        <UpdateModal
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setPlanners={setPlanners}
          setShowUpdateModal={setShowUpdateModal}
        />
      )}
      {showDeleteModal && (
        <Modal title="Confirmar Exclusão" icon={null} color="danger">
          <DivButtons>
            <Button
              bg="grafit"
              onClick={() => setShowDeleteModal(!showDeleteModal)}
            >
              Cancelar
            </Button>
            <Button
              bg="danger"
              onClick={() => {
                handleDeleteUser(selectedUser.id);
                setShowDeleteModal(!showDeleteModal);
              }}
            >
              Confirmar
            </Button>
          </DivButtons>
        </Modal>
      )}
      <ReactTooltip />
    </>
  );
}
