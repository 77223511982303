import { createGlobalStyle, keyframes } from 'styled-components';

export const openInput = keyframes`
  0% {
    width:0%;
    opacity: 0;
  }
  100%{
    width: 60%;
    opacity: 1;

  }
`;

export const colors = {
  primary: {
    hover: ' #19cc9f',
    default: '#00ffbf',
    disabled: '#ccfff2',
  },
  secondary: {
    hover: '#000033',
    default: '#4545e6',
    disabled: ' #ccccff',
  },
  info: {
    hover: '#236499',
    default: '#3ba7ff',
    disabled: '#bae0ff',
  },
  success: {
    hover: '#2a8038',
    default: '#4be364',
    disabled: '#ccffd4',
  },
  warning: {
    hover: '#d9bc2b',
    default: '#f6cd00',
    disabled: '#fffbcc',
  },
  danger: {
    hover: '#ab1136',
    default: '#f7194e',
    disabled: '#ffccd8',
  },
  grafit: {
    hover: '#999999',
    default: '#cccccc',
    disabled: '#f2f2f2',
  },
  white: {
    default: '#ffffff',
  },
  darker: {
    hover: '#000033',
    default: '#1a1a47',
    disabled: '#4d4d70',
    custom: '#33335c',
  },
  blueDarker: {
    hover: '#000033',
    default: '#140361',
    disabled: '#4d4d70',
    custom: '#33335c',
  },
  blue: {
    default: "#4739EB",
    disabled: '#5960FF',
    custom: '#4739EB',
  },
  grayButton: {
    hover: '#282728',
    default: '#6C6C6C',
    disabled: '#f0f1f7',
    custom: '#282728',
  },
  gray: {
    hover: '#E2E2E2',
    default: '#8C8B8C',
    disabled: '#f0f1f7',
    custom: '#282728',
  },
  blueTitle: {
    default: '#4739EB',
    hover: '#5960FF',
    down: '#1C11A7',
    disabled: 'rgba(55, 65, 81, 0.48)'
  },
  grayTitle: {
    default: '#6C6C6C',
    hover: '#282728',
    down: '#8C8B8C',
    disabled: 'rgba(55, 65, 81, 0.48)'
  },
  blueDarkenTitle: {
    default:'#0C053F',
    hover: '#140361',
    down: '#060328',
    disabled: 'rgba(55, 65, 81, 0.48)'
  }
};

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
    background: #141b2d;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    height: 100%;
  }

  body, input, button {
    font-family: Arial, Helvetica, sans-serif;
  }
  button {
    border: 0;
    cursor: pointer;
  }
  a{
    text-decoration: none !important;
  }
`;
