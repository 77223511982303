import React, { useState, useEffect, useRef } from 'react';

import { Icon } from 'antd';

import {
  AiOutlineSearch,
  AiOutlineCaretLeft,
  AiOutlineCaretRight,
} from 'react-icons/ai';

import { useLastLocation } from 'react-router-last-location';

import ReactTooltip from 'react-tooltip';

import { Link } from 'react-router-dom';

import ReactPaginate from 'react-paginate';

import { Container, Header, DivInput, ContainerPaginate } from './styles';

import debounce from 'utils/debounce';

import Button from 'components/Button';
import Dropdown from 'components/Button/Dropdown';
import Table from 'components/Table/Default';
import Can from 'components/Can';

import ModalCreate from './Modals/ModalCreate';
import ModalUpdate from './Modals/ModalUpdate';

import api from 'services/api';

export default function Pfg() {
  const [classes, setClasses] = useState([]);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [showInput, setShowInput] = useState(false);

  // Value do input de pesquisa
  const [searchInputCustomer, setInputCustomer] = useState('');

  const [showModal, setShowModal] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [selectedClass, setSelectedClass] = useState({});

  const inputRef = useRef();

  const location = useLastLocation();

  // Busca as turmas vindas da api
  useEffect(() => {
    let _isMounted = true;
    async function fetchData() {
       const responsePfg = await api.get('/pfg_classes', {
        params: {
            // name: searchInputCustomer,
          },
        }
      );

      if(responsePfg.status === 200) {
        const { data } = responsePfg;
        setPageCount(data.lastPage);
        setPage(1);

        return setClasses(
          data.data.map(clas => [
            <Link to={`/pfg/${clas.id}`}>{clas.name}</Link>,
            clas.state,
            clas.city,
            clas.place,
            <Can canRoles={['adm']}>
              <Dropdown>
                <button
                  onClick={() => {
                    setShowUpdateModal(!showUpdateModal);
                    setSelectedClass(clas);
                  }}
                >
                  Editar
                </button>
                <button>Excluir</button>
              </Dropdown>
            </Can>,
          ])
        );
      }
    }

    if (_isMounted) {
      fetchData();
      debounce(fetchData, 800);
    }

    // eslint-disable-next-line no-return-assign
    return () => (_isMounted = false);
  }, [page, showUpdateModal]);

  // Busca as turmas pelo input de pesquisa
  useEffect(() => {
    let _isMounted = true;
    async function fetchData() {
       const responsePFGClasses = await api.get('/pfg_classes', {
        params: {
          page,
          per_page: 5,
          name: searchInputCustomer,
        },
      });

      const { data } = responsePFGClasses;

      if(responsePFGClasses.status === 200) {
        setPageCount(data.lastPage);
        return setClasses(
          data.data.map(clas => [
            <Link to={`/pfg/${clas.id}`}>{clas.name}</Link>,
            clas.state,
            clas.city,
            clas.place,
            <Can canRoles={['adm']}>
              <Dropdown>
                <button
                  onClick={() => {
                    setShowUpdateModal(!showUpdateModal);
                    setSelectedClass(clas);
                  }}
                >
                  Editar
                </button>
                <button>Excluir</button>
              </Dropdown>
            </Can>,
          ])
        );
      }
    }
    if (_isMounted) {
      debounce(fetchData, 600);
    }

    // eslint-disable-next-line no-return-assign
    return () => (_isMounted = false);
  }, [page, searchInputCustomer, showUpdateModal]);

  // Disparada quando clicar em um elemento da paginação
  function handlePageClick(data) {
    setPage(data.selected + 1);
  }

  return (
    <>
      <Container>
        <Header>
          <aside>
            <Link to={location ? location.pathname : '#'} data-tip="Voltar">
              <Icon type="import" />
            </Link>
            <p>Turmas de PFG</p>
          </aside>
          <div className="right-bar">
            <div>
              <DivInput>
                <button
                  onClick={() => {
                    setShowInput(!showInput);
                    inputRef.current.focus();
                  }}
                  type="submit"
                >
                  <AiOutlineSearch />
                </button>
                <input
                  ref={inputRef}
                  className={showInput ? 'show' : ''}
                  type="text"
                  placeholder="Pesquisar..."
                  value={searchInputCustomer}
                  onChange={e => setInputCustomer(e.target.value)}
                />
              </DivInput>
            </div>
            <div className="right-bar-buttons">
              <Button size={35} bg="info" onClick={() => setShowModal(true)}>
                Nova Turma
              </Button>
            </div>
          </div>
        </Header>
        <Table
          head={['Nome', 'Estado', 'Cidade', 'Lugar', '']}
          body={classes}
        />
        <ContainerPaginate>
          <ReactPaginate
            previousLabel={<AiOutlineCaretLeft size={22} />}
            pageCount={pageCount}
            previousLinkClassName="previous_page"
            nextLabel={<AiOutlineCaretRight size={22} />}
            nextLinkClassName="next_page"
            activeClassName="active"
            onPageChange={handlePageClick}
          />
        </ContainerPaginate>
      </Container>

      <ReactTooltip />
      {showModal && (
        <ModalCreate setClasses={setClasses} setShowModal={setShowModal} />
      )}
      {showUpdateModal && (
        <ModalUpdate
          selectedClass={selectedClass}
          setClasses={setClasses}
          setSelectedClass={setSelectedClass}
          setShowUpdateModal={setShowUpdateModal}
          classes={classes}
        />
      )}
    </>
  );
}
