import React, { useState, useEffect, useCallback } from 'react';
import { Form, ButtonGroup, Small } from './styles';
import Modal from 'components/Modal/Default';
import Input from 'components/Input/InputLabel';
import Select from 'components/Input/Dropdown';
import Button from 'components/Button';
import usePostProfiles from 'hooks/usePostProfiles';
import Loading from 'components/Loading';

const defaultRoles = [
  {
    value: 'assistant-planner',
    label: 'Planejador assistente',
  },
];

export default function ModalCreate({
  setShowModal,
  setProfiles
}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const { handleSubmit, loading, returnedProfile } = usePostProfiles({ name, email, role });

  useEffect(() => {
    if (!loading && returnedProfile.password) {
      setProfiles(prev => [...prev, returnedProfile]);
    }
  }, [loading, returnedProfile, setProfiles]);

  const handleNameChange = useCallback(e => setName(e.target.value), []);
  const handleEmailChange = useCallback(e => setEmail(e.target.value), []);

  if (loading) {
    return <Loading />;
  }

  if (returnedProfile.password) {
    return (
      <Modal color="info" title="Novo Perfil" icon={null}>
        <Form>
          <Small>Essa será a senha padrão do perfil e você não poderá recuperá-la.</Small>
          <Input
            value={returnedProfile.password}
            disabled
          />
          <ButtonGroup>
            <Button
              bg="grafit"
              size="99%"
              stripped
              onClick={() => setShowModal(false)}
            >
              Fechar
            </Button>
          </ButtonGroup>
        </Form>
      </Modal>
    );
  }

  return (
    <Modal color="info" title="Novo Perfil" icon={null}>
      <Form onSubmit={handleSubmit}>
        <Input
          label="Nome"
          value={name}
          onChange={handleNameChange}
        />
        <Input
          label="email"
          type="email"
          value={email}
          onChange={handleEmailChange}
        />

        <Select
          label="Permissões"
          placeholder="Selecione a permissão do usuário"
          options={defaultRoles}
          onChange={selected => setRole(selected.value)}
        />

        <ButtonGroup>
          <Button
            bg="grafit"
            size="45%"
            stripped
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>
          <Button bg="info" size="45%" type="submit">
            Confirmar
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
}
