import React, { useState, useEffect } from 'react';

import { useLastLocation } from 'react-router-last-location';

import debounce from 'utils/debounce';

import getObjectDiff from 'utils/getObjectDiff';

import { toast } from 'react-toastify';

import Loading from 'components/Loading';

import { Icon } from 'antd';

import { Link } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';

import PercentInput from 'components/masks/PercentInput';

import { api_nort } from 'services/api';

import {
  Container,
  Header,
  Toolbar,
  TableContainer,
  HeaderTitle
} from './styles';

import Table from 'components/Table/Default';
import Button from 'components/Button';

const headTable = [
  'Investimento',
  'Iniciante',
  'Intermediário',
  'Expert',
  'Renda',
];

export default function RecommendedWallet() {

  const [isLoading, setIsLoading] = useState(true);

  const [tableList, setTableList] = useState([]);

  const [ buttonDisabled, setButtonDisabled] = useState(true);

  const [ dataList, setDataList ] = useState([]);

  const [ initialDataList, setInitialDataList ] = useState([]);

  const [ linhasTabela, setLinhasTabela ] = useState([]);

  const [ percentageTable, setPercentageTable ] = useState(headTable);

  const [ totalPercent, setTotalPercent ] = useState([]);

  // Recebe a rota anterior acessada
  const location = useLastLocation();


  const startLoading = () => {
    setIsLoading(true);
  }

  useEffect(() => {
    if(dataList.length > 0) {

      // Cria uma linha, pelo id_investimento
      const arrayIdInvestimento = [...new Set(dataList.map((investimento) => investimento.id_investimento))]

      const linhas = arrayIdInvestimento.map(indice => {
        const linha = dataList.filter(item => item.id_investimento === indice);
        return linha;
      })

      setLinhasTabela(linhas);
    }
  // eslint-disable-next-line
  }, [dataList])

  useEffect(() => {
    var porcentagemOk = totalPercent.reduce((acumulador, numero) => {
      return acumulador += numero === 100 ? 1 : 0;
      }, 0)

    const difs = getObjectDiff(initialDataList, dataList)
              .map((item) => {
                const _index = parseInt(item)
                const itemDif = dataList[_index]
                return  itemDif
              })

    if(difs.length <= 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(porcentagemOk !== totalPercent.length);
    }
  // eslint-disable-next-line
  }, [totalPercent])

  useEffect(() => {
    if(linhasTabela.length > 0){

      // Lines table array
      const linhasInvestimentos = linhasTabela.map((item) => {
        const itemWithTitle = [item[0].nome_investimento, ...item];
        return itemWithTitle;
      })

      let arPercentage = [];
      const titlesWithPercent = linhasTabela.filter((i, index) => index < 4).map((item, index) => {
        const percent = calcPercentage(item[0].id_investimento);
        arPercentage.push(percent);
        const strPercent = `(${percent}%)`;

        const title = (
          <HeaderTitle key={`headertitle-${item[0].id_investimento}`}>
            <span>{headTable[index + 1]}</span>
            <span className={percent !== 100 ? 'danger': 'percent'}>{strPercent}</span>
          </HeaderTitle>
        )
        return title;
      })

      setTotalPercent(arPercentage);

      // Add Column "Investimento"
      titlesWithPercent.unshift(<HeaderTitle key="title"><span>{headTable[0]}</span></HeaderTitle>)

      setPercentageTable(titlesWithPercent);

      setTableList(
        linhasInvestimentos.map((item) => {

            const linha = item.map((subitem) => {

              if(typeof subitem === "string") {
                return subitem
              }

              return (
                <PercentInput
                  key={`${subitem.id_perfil}-${subitem.id_investimento}`}
                  extra={subitem}
                  precision={1}
                  value={subitem.percentual}
                  onChange={updateValues}
                />
              )
            })
            return linha;
      }));

      setButtonDisabled(false);
    }
    // eslint-disable-next-line
  }, [linhasTabela])

  // Load initial clients
  useEffect(() => {
    startLoading();
    async function listGrid() {
      const response = await api_nort.get('/estrategia');

      setDataList(response.data);
      setInitialDataList(response.data);

      setIsLoading(false);
    }
    listGrid();
    // eslint-disable-next-line
  }, []);

  const calcPercentage = (idPerfil) => {

    const allPercentage = dataList.filter(item => item.id_perfil === idPerfil);
    var porcentagemSomada = allPercentage.reduce((acumulador, numero) => {
      return acumulador += numero.percentual;
      }, 0)

    return porcentagemSomada;
  }

  const updateValues = (newValue, oldState) => {

    debounce(() => {
      const { id_perfil, id_investimento} = oldState;

      const findState = dataList.map(item => {
        if(item.id_perfil === id_perfil && item.id_investimento === id_investimento) {
          const itemReplaced = {
            ...item,
            percentual: parseFloat(newValue)
          }

          return itemReplaced
        }
        return item;
      })

      setDataList(findState)
    }, 800)
  }

  const atualizaDados = () => {
    let strError = [];
    const difs = getObjectDiff(initialDataList, dataList)
                .map((item) => {
                  const _index = parseInt(item)
                  const itemDif = dataList[_index]
                  return  itemDif
                })

    if(difs.length > 0) {
      setButtonDisabled(true);

      const dataToSend = dataList.map(item => {
        return {
          id_perfil: item.id_perfil,
          id_investimento: item.id_investimento,
          percentual: item.percentual
        }
      })

      const errosPercent = totalPercent.map((perc, index) => {
        if(perc !== 100) {
          return {
            percent: perc,
            index: index + 1
          }
        }

        return undefined;
      }).filter(item => item !== undefined);

      if(errosPercent.length > 0) {
        strError = errosPercent.map(erro => {
          if(erro.percent > 100) {
            return `A coluna ${headTable[erro.index]} tem ${erro.percent}% e não são permitidos valores superiores a 100%!`
          } else {
            return `A coluna ${headTable[erro.index]} tem ${erro.percent}% e não são permitidos valores inferiores a 100%!`
          }
        })

        for(let i = 0; i < strError.length; i++) {
          toast.error(strError[i]);
        }
        return;
      }

      async function updateDados() {
        startLoading();
        const response = await api_nort.patch('/estrategia', dataToSend);

        if(response.data.sucesso) {
          toast.success('Dados atualizados!');

          startLoading();
          async function listGrid() {
            const response = await api_nort.get('/estrategia');

            setDataList(response.data);
            setInitialDataList(response.data);

            setIsLoading(false);
          }
          setButtonDisabled(false);

          listGrid();
        }
      }

      updateDados();

    } else {
      setButtonDisabled(false);
      toast.error('Não existem dados alterados.');
    }
  }

  return (
    <>
      <Container>
        <Header hideExtrair={true}>
          <aside>
            <Link to={location ? location.pathname : '#'} data-tip="Voltar">
              <Icon type="import" />
            </Link>
            <p>Carteira Recomendada</p>
          </aside>
        </Header>
        <div>
        </div>
        <Toolbar>
          <div className="right-bar">
            <div className="right-bar-buttons">
              <Button size={35} bg="info" onClick={atualizaDados} disabled={buttonDisabled}>
                Atualizar dados
              </Button>
            </div>
          </div>
        </Toolbar>
        { isLoading &&
          <Loading />
        }
        { tableList.length > 0 && !isLoading &&
          <TableContainer>
            <Table head={percentageTable} body={tableList} linear={true} centered={[false, true, true, true, true]} headClass={"no-padding-bottom"}/>
          </TableContainer>
        }
      </Container>
      <ReactTooltip />
    </>
  );
}
