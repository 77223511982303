import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

export default function Can({ canRoles, canPermissions, children }) {
  const { permissions, roles } = useSelector(state => state.auth);

  const inRoles = roles
    .map(role => canRoles.includes(role.slug))
    .includes(true);

  const inPermissions = permissions
    .map(permission => canPermissions.includes(permission.slug))
    .includes(true);

  const check = inRoles || inPermissions;

  return check ? <>{children}</> : null;
}

Can.propTypes = {
  canRoles: PropTypes.arrayOf(PropTypes.string),
  canPermissions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

Can.defaultProps = {
  canRoles: [],
  canPermissions: [],
};
