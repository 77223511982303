import styled from 'styled-components';

import Button from 'components/Button';

import { colors } from 'styles/global';

import { measures } from '../styles';

const {
  sidebarWidth,
  headerHeight,
  headerIconsSize,
  searchIconSize,
  wrapperSize,
} = measures;

export const Container = styled.nav`
  display: flex;
  position: absolute;
  height: ${headerHeight}px;
  width: 100%;
  padding-left: ${sidebarWidth + 30}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 0;
`;

export const Search = styled.form`
  display: flex;
  align-items: center;

  input {
    margin-left: 21px;
    outline: 0;
    border: 0;
    font-family: 'Nunito', sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0.26px;
    color: #999999;

    &::placeholder {
      color: #cccccc;
    }
  }

  img {
    height: ${searchIconSize}px;
    width: ${searchIconSize}px;
    color: #cccccc;
  }
`;

export const ButtonExtractData = styled(Button)`
  position: relative;
  width: 100px;
  height: 40px;
  background: ${colors.info.disabled} !important;
  color: ${colors.info.default};

  &:hover {
    background: ${colors.grafit.disabled} !important;
    color: ${colors.info.default};
  }
`;

export const SubPagesNav = styled.div`
  a {
    padding: 18px 30px;
    border-radius: 5px;
    background: none;
    font-size: 17px;
    font-weight: bold;
    color: #999999;
    transition: 500ms ease;

    &.active {
      color: ${colors.info.default};
      background: #e6f4ff;
    }

    &:hover {
      color: ${colors.info.default};
      background: #e6f4ff;
    }

    & + a {
      margin-left: 5px;
    }
  }
`;

export const HeaderNav = styled.ul`
  list-style: none;
  display: flex;
  margin-right: ${wrapperSize}px;
  margin-bottom: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;

    &:not(.name) {
      cursor: pointer;
    }

    svg {
      transition: 300ms ease;
    }

    &.name {
      white-space: nowrap;
      color: #999999;
    }

    &.active {
      button.user {
        border: solid 2px ${colors.info.default};
      }
      svg {
        fill: ${colors.info.default};
      }
    }

    button {
      background: none;

      &.user {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: solid 2px #999999;
        opacity: 1;
        transition: all .3s ease;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    svg {
      height: ${headerIconsSize}px;
      width: ${headerIconsSize}px;
      color: #999999;
    }

    &.launch {
      svg {
        &:hover {
          color: ${colors.info.default};
        }
      }
    }

    & + li {
      margin-left: 30px;
    }
  }
`;

export const DropDownMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 160px;
  background: #fff;
  right: 0;
  top: 0;
  transform: translateY(50px);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &.show {
    opacity: 1;
    transform: scale(1);
    transform: translateY(70px);
  }

  button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 38px;
    padding: 0 25px;
    font-size: 13px;
    font-weight: bold;
    background: #fff;
    border-radius: 7px;
    color: #999999;

    &:hover {
      background: ${colors.info.default};
      color: #fff;
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  padding: ${headerHeight + wrapperSize}px ${wrapperSize}px ${wrapperSize}px
    ${sidebarWidth + wrapperSize}px;
`;
