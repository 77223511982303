import React, { useState } from 'react';

import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Form } from '../styles';

import Modal from 'components/Modal/Default';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';

import api from 'services/api';

export default function ModalCreate({
  setShowModal,
  plannerId,
  bankAccount,
  setBankAccount,
  transfer_day,
  setTransferDay,
}) {
  const [bank_code, setBankCode] = useState(bankAccount?.bank_code);
  const [agencia, setAgencia] = useState(bankAccount?.agencia);
  const [agencia_dv, setAgenciaDv] = useState(
    bankAccount?.agencia_dv === '-' ? '' : bankAccount?.agencia_dv
  );
  const [type] = useState(bankAccount?.type);
  const [conta, setConta] = useState(bankAccount?.conta);
  const [conta_dv, setContaDv] = useState(bankAccount?.conta_dv);
  const [legal_name, setLegalName] = useState(bankAccount?.legal_name);
  const [document_number, setDocumentNumber] = useState(
    bankAccount?.document_number
  );
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (
      !bank_code ||
      !agencia ||
      !conta ||
      !conta_dv ||
      !legal_name ||
      !document_number ||
      !transfer_day
    ) {
      toast.error('Insira todos os dadoss');
      return;
    }
    try {
      const { data } = await api.post(`users/${plannerId}/receivers`, {
        bank_code,
        agencia,
        agencia_dv,
        type,
        conta,
        conta_dv,
        legal_name,
        document_number,
        transfer_day,
      });

      toast.success('Atualizado / criado com sucesso');

      setBankAccount(data);
      setTransferDay(data.transfer_day);
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.message
      ) {
        toast.error(err?.response?.data?.error?.message);
        return;
      }

      toast.error('Tivemos um erro');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal title="Novos dados bancários" color="info">
      <Form onSubmit={handleSubmit}>
        <Input
          label="Dia de transferência"
          value={transfer_day}
          onChange={e => setTransferDay(e.target.value)}
        />
        <Input
          label="Código do banco"
          value={bank_code}
          onChange={e => setBankCode(e.target.value)}
        />
        <Input
          label="Âgencia"
          value={agencia}
          onChange={e => setAgencia(e.target.value)}
        />
        <Input
          label="Dígito da âgencia"
          value={agencia_dv}
          onChange={e => setAgenciaDv(e.target.value)}
        />
        <Input
          label="Conta"
          value={conta}
          onChange={e => setConta(e.target.value)}
        />
        <Input
          label="Dígito da conta"
          value={conta_dv}
          onChange={e => setContaDv(e.target.value)}
        />
        <Input
          label="Nome completo"
          value={legal_name}
          onChange={e => {
            setLegalName(e.target.value);
          }}
        />
        <Input
          label="CPF / CNPJ"
          value={document_number}
          onChange={e => setDocumentNumber(e.target.value)}
        />

        <div className="divGroup">
          <Button stripped bg="grafit" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button bg="info">{loading ? 'Carregando...' : 'Confirmar'}</Button>
        </div>
      </Form>
    </Modal>
  );
}

ModalCreate.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};
