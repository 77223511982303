import React from 'react';
import PropTypes from 'prop-types';

import { AiOutlineCreditCard } from 'react-icons/ai';
import { ModalStyled, DivBackground } from './styles';

export default function Modal({ children, color, icon, title }) {
  return (
    <>
      <DivBackground>
        <ModalStyled color={color}>
          <div className="head">
            {icon}
            <h3>{title}</h3>
          </div>
          <div className="body">{children}</div>
        </ModalStyled>
      </DivBackground>
    </>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.string,
};

Modal.defaultProps = {
  children: () => {},
  color: 'info',
  icon: <AiOutlineCreditCard />,
  title: 'Nova Dívida',
};
