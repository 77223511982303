import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { DivGroup, DivCheckbox } from '../styles';

import Context from '../Context';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';

export default function ThirdStep({ previousStep }) {
  const {
    handleSubmit,

    signature_date,
    setSignatureDate,
    contract_end_date,
    setContractEndDate,
    first_meeting_date,
    setFirstMeetingDate,
    billing_date,
    setBillingDate,
    number_of_steps,
    setNumberOfSteps,
    first_step_deadline,
    setFirstStepDeadline,
    second_step_deadline,
    setSecondStepDeadline,
    third_step_deadline,
    setThirdStepDeadline,
    first_fee,
    setFirstFee,
    second_fee,
    setSecondFee,
    third_fee,
    setThirdFee,
  } = useContext(Context);

  return (
    <>
      <DivGroup>
        <Input
          type="date"
          label="Início do Contrato"
          width="45%"
          value={signature_date}
          onChange={e => setSignatureDate(e.target.value)}
        />
        <Input
          type="date"
          label="Fim do Contrato"
          width="45%"
          value={contract_end_date}
          onChange={e => setContractEndDate(e.target.value)}
        />
      </DivGroup>
      <Input
        type="date"
        label="Primeira reunião"
        width="45%"
        value={first_meeting_date}
        onChange={e => setFirstMeetingDate(e.target.value)}
      />
      <DivGroup>
        <DivCheckbox>
          <label htmlFor="">Dia de Cobrança</label>
          <div className="check">
            <input
              type="radio"
              name="billing_date"
              id="inputBillingDate"
              checked={billing_date === 5 && true}
              onChange={() => setBillingDate(5)}
            />
            <span>5</span>
            <input
              type="radio"
              name="billing_date"
              id="inputBillingDate"
              checked={billing_date === 15 && true}
              onChange={() => setBillingDate(15)}
            />
            <span>15</span>
            <input
              type="radio"
              name="billing_date"
              id="inputBillingDate"
              checked={billing_date === 25 && true}
              onChange={() => setBillingDate(25)}
            />
            <span>25</span>
          </div>
        </DivCheckbox>
        <DivCheckbox last>
          <label htmlFor="">Número de passos</label>
          <div className="check">
            <input
              type="radio"
              name="number_of_steps"
              id="inputNumberOfSteps"
              checked={number_of_steps === 1 && true}
              onChange={() => setNumberOfSteps(1)}
            />
            <span>1</span>
            <input
              type="radio"
              name="number_of_steps"
              id="inputNumberOfSteps"
              checked={number_of_steps === 2 && true}
              onChange={() => setNumberOfSteps(2)}
            />
            <span>2</span>
            <input
              type="radio"
              name="number_of_steps"
              id="inputNumberOfSteps"
              checked={number_of_steps === 3 && true}
              onChange={() => setNumberOfSteps(3)}
            />
            <span>3</span>
          </div>
        </DivCheckbox>
      </DivGroup>
      <DivGroup>
        <Input
          type="number"
          label="Primeira Etapa"
          width="45%"
          value={first_step_deadline}
          onChange={e => setFirstStepDeadline(Number(e.target.value))}
        />
        <Input
          type="number"
          label="Mensalidade da 1ª etapa"
          width="45%"
          value={first_fee}
          onChange={e => setFirstFee(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          type="number"
          label="Segunda Etapa"
          width="45%"
          value={second_step_deadline}
          onChange={e => setSecondStepDeadline(Number(e.target.value))}
        />
        <Input
          type="number"
          label="Mensalidade da 2ª etapa"
          width="45%"
          value={second_fee}
          onChange={e => setSecondFee(e.target.value)}
        />
      </DivGroup>
      <DivGroup>
        <Input
          type="number"
          label="Terceita Etapa"
          width="45%"
          value={third_step_deadline}
          onChange={e => setThirdStepDeadline(Number(e.target.value))}
        />
        <Input
          type="number"
          label="Mensalidade da 3ª etapa"
          width="45%"
          value={third_fee}
          onChange={e => setThirdFee(e.target.value)}
        />
      </DivGroup>
      <DivGroup buttons>
        <Button bg="grafit" size="30" onClick={previousStep}>
          Voltar
        </Button>
        <Button bg="info" size="30" onClick={handleSubmit}>
          Confirmar
        </Button>
      </DivGroup>
    </>
  );
}

ThirdStep.propTypes = {
  previousStep: PropTypes.func,
};

ThirdStep.defaultProps = {
  previousStep: () => {},
};
