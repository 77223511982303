import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { useLastLocation } from 'react-router-last-location';

import { AiOutlineImport } from 'react-icons/ai';

import ReactTooltip from 'react-tooltip';

import { Container, Header } from './styles';

import Button from 'components/Button';

import Customers from './Customers';
import Details from './Details';

export default function Profile() {
  const [toggleButton, setToggleButton] = useState('clients');

  const location = useLastLocation();

  return (
    <Container>
      <Header>
        <div className="title">
          <Link to={location ? location.pathname : '#'} data-tip="Voltar">
            <AiOutlineImport size={50} />
          </Link>

          <span>
            Turma / {toggleButton === 'clients' ? 'Alunos' : 'Detalhes'}
          </span>
        </div>
        <div className="buttons">
          <Button
            size={45}
            bg="info"
            stripped={toggleButton === 'details'}
            onClick={() => setToggleButton('clients')}
          >
            Alunos
          </Button>
          <Button
            size={45}
            bg="info"
            stripped={toggleButton === 'clients'}
            onClick={() => setToggleButton('details')}
          >
            Detalhes
          </Button>
        </div>
      </Header>
      {toggleButton === 'clients' ? <Customers /> : <Details />}
      <ReactTooltip />
    </Container>
  );
}
