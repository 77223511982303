import React, { useState } from 'react';

import { Container, DivNewCard, Body } from '../../styles';

import TotalTable from 'components/Table/Default';
import Button from 'components/Button';

import CreateModal from './Modals/CreateModal';

export default function Transactions() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Container>
        <DivNewCard>
          <Button size={10} bg="info" onClick={() => setShowModal(true)}>
            Nova transação
          </Button>
        </DivNewCard>
        <Body>
          <TotalTable />
        </Body>
      </Container>
      {showModal && <CreateModal setShowModal={setShowModal} />}
    </>
  );
}
