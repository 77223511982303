import styled from 'styled-components';

import { colors, openInput } from 'styles/global';

export const Container = styled.div`
  height: 80%;

  a {
    color: ${colors.white.default};
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 1%;
  min-height: 48px;

  aside {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 0.5rem;

    a {
      margin-top: 4px;
    }

    svg {
      font-size: 26px;
      color: ${colors.white.default};
      transition: 200ms;
      &:hover {
        transform: scale(1.2);
        color: ${colors.info.default};
      }
    }

    p {
      margin-bottom: auto;
      font-family: 'Nunito', sans-serif;
      font-size: 32px;
      line-height: 1.35;
      letter-spacing: 0.26px;
      color: ${colors.white.default};
      margin-left: 2%;
      white-space: nowrap;
    }
  }

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
    &.right-bar {
      width: 30%;
      div {
        display: flex;
        justify-content: space-evenly;
        &.right-bar-buttons {
          display: flex;
          justify-content: flex-start;
          width: 40%;
          button {
            width: auto;
            & + button {
              margin-left: 5%;
              margin-right: 0;
            }
          }
        }
      }
      svg {
        font-size: 30px;
        color: ${colors.grafit.hover};
      }
    }
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  padding-top: 4%;
  padding-bottom: 4%;
  input {
    width: 0%;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 16px;
    border: 2px solid ${colors.grafit.default};
    border-left: 0;
    border-right: 0;
    border-top: 0;

    &.show {
      animation: ${openInput} forwards 500ms;
    }
  }

  button {
    background: transparent;

    svg {
      font-size: 30px;
      color: ${colors.grafit.default};

      &:hover {
        color: ${colors.grafit.hover};
        transition: color 0.2s ease;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const ContainerPaginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 10%;

  margin-top: 20px;

  & > ul {
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
    width: 30%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      width: 30px;
      height: 30px;

      color: ${colors.grafit.hover};
      font-size: 16px;
      transition: 200ms ease;
      border-radius: 5px;
    }

    & > li + li {
      margin-left: 7%;
    }

    a.previous_page {
      padding: 5px;
      color: ${colors.grafit.hover};
      border: 1px solid ${colors.grafit.default};
    }
    a.next_page {
      padding: 5px;
      color: ${colors.grafit.hover};
      border: 1px solid ${colors.grafit.default};
    }

    li.active {
      a {
        color: ${colors.white.default};
        background: ${colors.info.default};
        border-radius: 50%;
      }
    }

    a:hover {
      color: ${colors.info.default};
      transform: scale(1.1);
    }
  }
`;
