import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ClienteData from './ClienteData';
import ContractData from './ContractData';
import api, { api_nort } from 'services/api';
import Loading from './Loading';
import { Stepper } from '@mui/material';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import userName from 'utils/userName';
import Dropdown from 'components/Button/Dropdown';
export default function ClientManagement({
  setCustomers,
  setShowUpdateModal,
  setSelectedUser,
  setShowDisableModal,
  token,
  loggedUser,
  selectedUser,
  setActiveModal,
  activeModal,
  showUpdateModal,
}) {
  const steps = ['Cliente', 'Contrato', 'Conclusão'];
  const [name, setName] = React.useState(selectedUser?.name || '');
  const [email, setEmail] = React.useState(selectedUser?.email || '');
  const [phone, setPhone] = React.useState(selectedUser?.contract.phone || '');
  const [birthdate, setBirthdate] = React.useState(
    new Date(selectedUser?.contract?.birthdate) || new Date()
  );
  const [address, setAddress] = React.useState(
    selectedUser?.contract?.address || ''
  );
  const [neighborhood, setNeighborhood] = React.useState(
    selectedUser?.contract?.neighborhood || ''
  );
  const [city, setCity] = React.useState(selectedUser?.contract?.city || '');
  const [state, setState] = React.useState(selectedUser?.contract?.state || '');
  const [cpf, setCpf] = React.useState(selectedUser?.contract?.cpf || '');
  const [zip, setZip] = React.useState(selectedUser?.contract?.zip || '');
  const [deadline, setDeadline] = React.useState(
    selectedUser?.contract?.deadline || 0
  );
  const [who_planner, setWhoPlanner] = React.useState(
    selectedUser?.contract?.who_planner || ''
  );
  const [indicated_by_colaborator, setIndicatedByColaborator] = React.useState(
    selectedUser?.contract?.indicated_by_colaborator || ''
  );
  const [indicated_by_name, setIndicatedByName] = React.useState(
    selectedUser?.contract?.indicated_by_name || ''
  );
  const [contract_type, setContractType] = React.useState(
    selectedUser?.contract?.contract_type || 'START'
  );
  const [total_deployment_value, setTotalDeploymentValue] = React.useState(
    selectedUser?.contract?.total_deployment_value || ''
  );
  const [deployment_billing_date, setDeploymentBillingDate] = React.useState(
    new Date(selectedUser?.contract?.deployment_billing_date) || new Date()
  );
  const [method_payment, setMethodPayment] = React.useState(
    selectedUser?.contract?.method_payment || ''
  );
  const [monthly_fee, setMonthlyFee] = React.useState(
    selectedUser?.contract?.monthly_fee || ''
  );
  const [min_fee, setMinFee] = React.useState(
    selectedUser?.contract?.min_fee || ''
  );
  const [percent_under_equity, setPercentUnderEquity] = React.useState(
    selectedUser?.contract?.percent_under_equity || 0
  );
  const [
    broker_charge_authorization,
    setBrokerChargeAuthorization,
  ] = React.useState(selectedUser?.contract?.broker_charge_authorization || '');
  const [hiring_manager, setHiringManager] = React.useState(
    selectedUser?.contract?.hiring_manager || ''
  );
  const [client_count, setClientCount] = React.useState(
    selectedUser?.contract?.client_count || true
  );
  // Dados do cônjuge
  const [partner_name, setPartnerName] = React.useState(
    selectedUser?.contract?.partner_name || ''
  );
  const [partner_email, setPartnerEmail] = React.useState(
    selectedUser?.contract?.partner_email || ''
  );
  const [partner_phone, setPartnerPhone] = React.useState(
    selectedUser?.contract?.partner_phone || ''
  );
  const [partner_birthdate, setPartnerBirthdate] = React.useState(
    selectedUser?.contract?.partner_birthdate || ''
  );

  // Dados do contrato
  const [planner_id, setPlannerId] = React.useState(
    selectedUser?.contract?.planner_id || ''
  );
  const [recommendation_id, setRecommendationId] = React.useState(
    selectedUser?.contract?.recommendation_id || ''
  );
  const [signature_date, setSignatureDate] = React.useState(
    new Date(selectedUser?.contract?.signature_date) || ''
  );
  const [contract_end_date, setContractEndDate] = React.useState(
    new Date(selectedUser?.contract?.contract_end_date) || ''
  );
  const [first_meeting_date, setFirstMeetingDate] = React.useState(
    new Date(selectedUser?.contract?.first_meeting_date) || new Date()
  );
  const [billing_date, setBillingDate] = React.useState(
    selectedUser?.contract?.billing_date || null
  );
  const [how_meet, setHowMeet] = React.useState(
    selectedUser?.contract?.how_meet || 'INDICAÇÃO DO CLIENTE'
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState();
  const [allCustomers, setAllCustomers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [allPlanners, setAllPlanners] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await api.get('/users', {
        params: {
          user_type: 'client',
          all: true,
        },
      });

      const { data: planners } = await api.get('/users', {
        params: {
          user_type: 'planner',
          all: true,
        },
      });

      setAllCustomers(
        data.map(costumer => ({
          label: costumer.name,
          id: costumer.id,
        }))
      );

      setAllPlanners(
        planners.map(planner => ({
          label: planner.name,
          id: planner.id,
        }))
      );
      setLoading(false);
    }

    fetchData();
  }, []);

  const isStepSkipped = step => {
    return skipped?.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const updateUser = async body => {
    try {
      let data;

      const response = await api.put(`/users/${selectedUser.id}`, {
        name,
        ...(email !== selectedUser.email && { email }),
      });
      data = response.data;

      const { data: data_contract } = await api.put(
        `/users/${selectedUser.id}/contracts`,
        {
          with_contract: true,
          ...body,
        }
      );

      setSelectedUser(user => {
        user.name = data.name;
        user.email = data.email;
        user.contract = data_contract;
        user.contract.planner = data_contract.planner;
        return user;
      });

      setCustomers(customers =>
        customers.map(customer => {
          const customer_id = customer[1].substr(1).toString();

          if (customer_id === data.id.toString()) {
            selectedUser.contract = data_contract;

            return [
              <Link to={`/customers/${data.id}/contracts`}>
                {userName(selectedUser)}
              </Link>,
              `#${customer.id}`,
              format(new Date(data.created_at), 'dd/MM/yyyy'),
              data_contract.active ? 'Ativo' : 'Inativo',
              <div className="div-icons">
                <div className="icons">
                  <Dropdown>
                    <button
                      onMouseDown={() => {
                        setShowUpdateModal(true);
                        setSelectedUser(selectedUser);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      onMouseDown={() => {
                        setSelectedUser(selectedUser);
                      }}
                    >
                      Excluir
                    </button>
                    <button
                      onMouseDown={() => {
                        window.open(
                          `${process.env.REACT_APP_CLIENT_URL ||
                            'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                            customer.id
                          }&external_user_id=${loggedUser.id}`
                        );
                      }}
                    >
                      Yuno On
                    </button>
                  </Dropdown>
                </div>
              </div>,
            ];
          }
          return customer;
        })
      );

      toast.success('Editado com sucesso');
      setShowUpdateModal(false);
      setActiveModal(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          toast.error(error.message);
        });
      }
      if (err?.response?.data) {
        err.response.data.forEach(message => toast.error(message.message));
      } else {
        toast.error(
          'Tivemos um problema, caso o usuário tenha sido criado com sucesso você deve editar o contrato dele e não criar um novo'
        );
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const type = 'client';

      const password = cpf;

      const schema = Yup.object().shape({
        planner_id: Yup.string().required('O Planejador é obrigatório'),
        recommendation_id: Yup.string().nullable(true),
        name: Yup.string().required('O nome é obrigatório'),
        email: Yup.string()
          .email('Digite um email válido')
          .required('O Email é obrigatório'),
        type: Yup.string().required(
          'O tipo do usuário não está sendo informado. Entre em contato com o suporte.'
        ),
        phone: Yup.string().required('O telefone é obrigatório'),
        birthdate: Yup.date()
          .typeError('Informe uma data de aniversário válida')
          .required('A data de aniversário é obrigatória'),
        partner_name: Yup.string()
          .nullable(true)
          .typeError('O nome do cônjuge não pode conter dígitos'),
        partner_email: Yup.string()
          .nullable(true)
          .email('Digite um email do cônjuge válido'),
        partner_phone: Yup.string().nullable(true),
        partner_birthdate: Yup.date()
          .nullable(true)
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Informe uma data de aniversário válida para o cônjuge.'),
        address: Yup.string().required('O endereço é obrigatório'),
        neighborhood: Yup.string().required('O bairro é obrigatório'),
        city: Yup.string().required('A cidade é obrigatório'),
        state: Yup.string().required('O estado é obrigatório'),
        zip: Yup.string()
          .max(8, 'O CEP deve conter 8 dígitos')
          .required('O CEP é obrigatório'),
        cpf: Yup.string()
          .min(11, 'O cpf deve conter 11 dígitos')
          .required('O CPF é obrigatório'),
        signature_date: Yup.date()
          .typeError('Informe uma data de assinatura válida')
          .required('A Data de início de contrato é obrigatória'),
        contract_end_date: Yup.date()
          .typeError('Informe uma data de fim de contrato válida')
          .required('A Data final de contrato é obrigatória'),
        first_meeting_date: Yup.date()
          .nullable(true)
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Informe uma data válida para a primeira reunião.'),
      });

      const body = {
        planner_id,
        name,
        email,
        password,
        birthdate,
        phone,
        zip,
        cpf,
        address,
        neighborhood,
        city,
        state,
        partner_name,
        partner_email,
        partner_birthdate,
        partner_phone,
        signature_date,
        contract_end_date,
        first_meeting_date,
        deadline,
        hiring_manager,
        how_meet,
        who_planner,
        indicated_by_colaborator,
        indicated_by_name,
        contract_type,
        total_deployment_value,
        deployment_billing_date,
        billing_date: parseInt(billing_date, 10),
        method_payment,
        monthly_fee,
        min_fee,
        percent_under_equity,
        broker_charge_authorization,
        recommendation_id,
        type,
        client_count,
      };

      await schema.validate(body, {
        abortEarly: false,
      });

      if (selectedUser?.id) return updateUser(body);

      const { data } = await api.post('/users', {
        name,
        email,
        password,
        type,
      });

      toast.success('Usuário criado com sucesso');

      const { data: data_contract } = await api.post(
        `/users/${data.id}/contracts`,
        {
          ...body,
          active: true,
        }
      );

      toast.success('Contrato criado com sucesso');

      await api.post(`users/${data.id}/categoriesDefault`);

      toast.success('Categorias default geradas com sucesso!');
      const customer = data;
      customer.contract = data_contract;

      //Criar estrutura de relatório para novos clientes cadastrados
      async function insertStructReport() {
        const response = await api_nort.post(`Usuario/${data.id}/relatorios`);
        if (response.data.sucesso) {
          toast.success('Relatórios gerados com sucesso!');
        } else {
          toast.error('Falha na geração dos relatório, Verifique!');
        }
      }

      insertStructReport();

      setCustomers(customers => [
        ...customers,
        [
          <Link to={`/customers/${customer.id}/contracts`}>
            {userName(customer)}
          </Link>,
          `#${customer.id}`,

          format(new Date(customer.created_at), 'dd/MM/yyyy'),
          data_contract.active ? 'Ativo' : 'Inativo',
          <Dropdown>
            <button
              onMouseDown={() => {
                setShowUpdateModal(true);
                setSelectedUser(customer);
              }}
            >
              Editar
            </button>
            <button
              onMouseDown={() => {
                setShowDisableModal(true);
                setSelectedUser(customer);
              }}
            >
              Excluir
            </button>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    customer.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Yuno On
            </button>
          </Dropdown>,
        ],
      ]);

      setPlannerId('');
      setName('');
      setEmail('');
      setBirthdate('');
      setPhone('');
      setCpf('');
      setZip('');
      setAddress('');
      setNeighborhood('');
      setCity('');
      setState('');
      setPartnerName('');
      setPartnerEmail('');
      setPartnerBirthdate('');
      setPartnerPhone('');
      setAllCustomers('');
      setAllPlanners('');
      setShowUpdateModal(false);
      setActiveModal(false);
      toast.success('Cliente cadastrado com sucesso.');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          toast.error(error.message);
        });
      }
      if (err?.response?.data) {
        err.response.data.forEach(message => toast.error(message.message));
      } else {
        toast.error(
          'Tivemos um problema, caso o usuário tenha sido criado com sucesso você deve editar o contrato dele e não criar um novo'
        );
      }
    }
  };

  const stepsCompoments = {
    '1': (
      <ClienteData
        planner_id={planner_id}
        setPlannerId={setPlannerId}
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        birthdate={birthdate}
        setBirthdate={setBirthdate}
        phone={phone}
        setPhone={setPhone}
        cpf={cpf}
        setCpf={setCpf}
        zip={zip}
        setZip={setZip}
        address={address}
        setAddress={setAddress}
        neighborhood={neighborhood}
        setNeighborhood={setNeighborhood}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        partner_name={partner_name}
        setPartnerName={setPartnerName}
        partner_email={partner_email}
        setPartnerEmail={setPartnerEmail}
        partner_birthdate={partner_birthdate}
        setPartnerBirthdate={setPartnerBirthdate}
        partner_phone={partner_phone}
        setPartnerPhone={setPartnerPhone}
        allPlanners={allPlanners}
        setAllPlanners={setAllPlanners}
        allCustomers={allCustomers}
        setAllCustomers={setAllCustomers}
        client_count={client_count}
        setClientCount={setClientCount}
      />
    ),
    '2': (
      <ContractData
        allPlanners={allPlanners}
        setAllPlanners={setAllPlanners}
        recommendation_id={recommendation_id}
        setRecommendationId={setRecommendationId}
        allCustomers={allCustomers}
        setAllCustomers={setAllCustomers}
        signature_date={signature_date}
        setSignatureDate={setSignatureDate}
        contract_end_date={contract_end_date}
        setContractEndDate={setContractEndDate}
        first_meeting_date={first_meeting_date}
        setFirstMeetingDate={setFirstMeetingDate}
        deadline={deadline}
        setDeadline={setDeadline}
        hiring_manager={hiring_manager}
        setHiringManager={setHiringManager}
        how_meet={how_meet}
        setHowMeet={setHowMeet}
        who_planner={who_planner}
        setWhoPlanner={setWhoPlanner}
        indicated_by_colaborator={indicated_by_colaborator}
        setIndicatedByColaborator={setIndicatedByColaborator}
        indicated_by_name={indicated_by_name}
        setIndicatedByName={setIndicatedByName}
        contract_type={contract_type}
        setContractType={setContractType}
        total_deployment_value={total_deployment_value}
        setTotalDeploymentValue={setTotalDeploymentValue}
        billing_date={billing_date}
        setBillingDate={setBillingDate}
        method_payment={method_payment}
        setMethodPayment={setMethodPayment}
        monthly_fee={monthly_fee}
        setMonthlyFee={setMonthlyFee}
        min_fee={min_fee}
        deployment_billing_date={deployment_billing_date}
        setDeploymentBillingDate={setDeploymentBillingDate}
        setMinFee={setMinFee}
        percent_under_equity={percent_under_equity}
        setPercentUnderEquity={setPercentUnderEquity}
        broker_charge_authorization={broker_charge_authorization}
        setBrokerChargeAuthorization={setBrokerChargeAuthorization}
        handleSubmit={handleSubmit}
      />
    ),
  };

  return (
    <>
      <Dialog maxWidth="md" open={activeModal || showUpdateModal}>
        <DialogTitle color={'#b7a3fd'} sx={{ fontWeight: 'bold' }}>
          Novo cliente
        </DialogTitle>
        <Box padding="20px">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <React.Fragment>
            {loading ? <Loading /> : stepsCompoments[activeStep + 1]}

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                onClick={() => {
                  setShowUpdateModal(false);
                  setActiveModal(false);
                }}
              >
                Sair
              </Button>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Anterior
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              <Button
                onClick={() => {
                  handleNext();
                  if (activeStep === steps.length - 1) {
                    handleSubmit();
                  }
                }}
              >
                {activeStep === steps.length - 1 ? 'Terminar' : 'Próximo'}
              </Button>
            </Box>
          </React.Fragment>
        </Box>
      </Dialog>
    </>
  );
}
