import styled from 'styled-components';

import { colors } from 'styles/global';

export const Container = styled.div`
  width: ${props => props.width};

  label {
    font-size: 14px;
    color: white;
    transition: 250ms ease;
  }

  div.custom__container {
    margin-top: 5px;
    width: 100%;
  }

  div.custom__value-container {
    font-size: 15px;
  }

  div.custom__control {
    border: 1px solid #2c4356;
    height: ${props => props.height};
    width: 100%;
  }

  div.custom__control--is-focused {
    border: 0.1px solid ${colors.info.default};
  }

  div.custom__menu {
    padding: 10px;
    background: #1f2940;
  }

  div.custom__option {
    margin-bottom: 5px;
    height: 48px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${colors.grafit.hover};

    &:last-child {
      margin-bottom: 0;
    }
  }

  div.custom__option:hover {
    background: ${colors.info.disabled};
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }

  div.custom__option--is-focused {
    background: ${colors.info.disabled};
    font-weight: bold;
    color: #fff;
  }

  div.custom__option--is-selected {
    background: ${colors.info.default};
    font-weight: bold;
    color: #fff;
  }

  div.css-1pahdxg-control {
    background: #1f2940;
  }
  div.css-1uccc91-singleValue {
    color: white;
  }
  div.css-yk16xz-control {
    background: #1f2940;
  }
`;
