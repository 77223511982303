import { combineReducers } from 'redux';

import auth from './auth/reducer';
import session from './session/reducer';
import user from './user/reducer';
import contract from './contract/reducer';
import contract_customer from './contract_customer/reducer';

export default combineReducers({
  auth,
  session,
  user,
  contract,
  contract_customer,
});
