import styled from 'styled-components';

import { colors } from 'styles/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: 95%;

  padding: 1%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  div.title {
    display: flex;
    align-items: center;
    margin-bottom: 2%;

    width: 20%;

    svg {
      color: ${colors.grafit.hover};
      transition: 200ms;
      &:hover {
        transform: scale(1.2);
        color: ${colors.info.default};
      }
    }

    span {
      margin-left: 15px;
      font-family: 'Nunito', sans-serif;
      font-size: 32px;
      line-height: 1.35;
      letter-spacing: 0.26px;
      color: ${colors.grafit.hover};
    }
  }

  div.buttons {
    width: 20%;
    display: flex;
  }

  button + button {
    margin-left: 5%;
  }
`;
