import styled from 'styled-components';

import { colors } from 'styles/global';

import logoReal from 'assets/logo.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  background: ${colors.secondary.default};
`;

export const LogoReal = styled.img.attrs({
  src: logoReal,
})`
  width: 300px;
  height: 200px;
`;

export const FormCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${colors.white.default};

  width: 350px;

  padding: 20px;

  border-radius: 10px;

  > button {
    margin-top: 20px;
  }

  > div + div {
    margin-top: 10px;
  }
`;

export const Legend = styled.legend`
  text-align: center;
  color: ${colors.info.default};
`;

export const SubscriptionsInformation = styled.div``;

export const MessageError = styled.h1`
  font-size: 52px;
`;

export const NumberCNPJ = styled.h2`
  margin-bottom: 15px;

  color: ${colors.white.default};
`;
