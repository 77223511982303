import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from '../../../components/atoms/Container';
import { H1 } from '../../../components/atoms/typography/H1';
import { P } from '../../../components/atoms/typography/P';
import { A } from 'components/atoms/typography/A/A';
const NotFoundPage = () => {
  const location = useLocation();
  const path = location.state?.path || '';
  return (
    <Container>
      <H1>Página não encontrada!</H1>
      <P>Parece que você se perdeu...</P>
      <A href={path}>Voltar para a página inicial</A>
    </Container>
  );
};

export default NotFoundPage;
