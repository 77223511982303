import styled from 'styled-components';

export const Container = styled.div`
  width: 100px;
  position: relative;
  cursor: pointer;
`

export const InputCheckBox = styled.input`
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
  pointer-events: none;

  &:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 30px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  pointer-events: none;

  &:active .react-switch-button {
    width: 60px;
  }
`

export const Button = styled.span`
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
`
