import produce from 'immer';

const INITIAL_STATE = {
  email: null,
  name: null,
  password: null,
  type: null,
  user_id: null,
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@user/STORE_USER':
      return produce(state, draft => {
        draft.name = action.payload.name;
        draft.email = action.payload.email;
        draft.password = action.payload.password;
        draft.type = action.payload.type;
      });

    case '@user/STORE_USER_SUCCESS':
      return produce(state, draft => {
        draft.user_id = action.payload.user_id;
      });

    default:
      return state;
  }
}
