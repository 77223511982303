import styled from 'styled-components';

import { colors } from 'styles/global';

export const Container = styled.div`
  width: 100%;
  span,
  label {
    color: ${colors.white.default};
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;

  padding: 50px;


`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  div.left-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 28%;
    svg {
      font-size: 40px;
      color: ${colors.grafit.hover};

      transition: 200ms;
      &:hover {
        transform: scale(1.2);
        color: ${colors.info.default};
      }
    }

    p {
      margin-left: 25px;
      margin-bottom: auto;
      font-family: 'Nunito', sans-serif;
      font-size: 32px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: 0.26px;
      color: ${colors.white.default};
    }
  }

  div.options {
    display: flex;

    margin-top: 25px;

    & > button + button {
      margin-left: 20px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 80%;

  div.labelForm {
    display: flex;
    align-items: center;

    padding: 0.6% 0 0 1%;
    margin-top: 2%;
    background: #1f2940;

    border-radius: 8px;

    h3 {
      color: ${colors.white.default};
      letter-spacing: 1px;
      font-weight: bold;
    }
  }
  input {
    outline: 0;
    /* border: 2px solid ${colors.grafit.default}; */
    border-radius: 5px;
    height: 30px;
    padding: 0px 10px;
    background: #1f2940;

      color:${colors.white.default};
    

    /* &:focus {
      border: 2px solid ${colors.info.default};
    } */
  }
  label {
    margin-top: 1.5%;
    margin-bottom: 1%;
    color: #9c9c9c;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0.26px;
  }

  div.data-format {
    div.row {
      display: flex;
      flex-direction: row;
      align-items: center;

      div.right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        div {
          display: flex;
          align-items: center;

          label {
            margin-left: 1%;
          }
        }
      }

      div.column {
        display: flex;
        flex-direction: column;
        justify-content: center;

        div.left {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 0.5%;

          &.customMargin {
            margin-top: 0.5% !important;
            margin-bottom: 0%;
          }
          input.customMargin-left {
            margin-left: 1% !important;
            margin-right: 0.2% !important;
          }

          div.divGroup {
            display: flex;
            flex-direction: column !important;
            justify-content: flex-start;
            

            &.zip {
              margin-left: -18.5%;
            }
            &.cpf {
              margin-left: -7%;
            }
            margin-right: 1%;

            input {
              &.phone {
                width: 70% !important;
              }
              &.birthdate {
                margin-left: 2%;
                width: 20% !important;
              }
              &.uf {
                width: 25% !important;
              }
              &.orgao {
                width: 70%;
              }
              &.nEtapas {
                width: 48% !important;
              }
            }
          }
        }
      }
    }
  }
`;

export const DivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
  div {
    display: flex;
    flex-direction: column;
    width: 50%;

    input.width {
      width: 90%;
    }

    input.customWidth {
      width: 80% !important;
    }

    &.line-up-horizontal {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-top: 1%;

      input.phone {
        width: 70% !important;
      }
      input.birthdate {
        width: 50% !important;
      }
    }
  }
`;

export const DivNewCard = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  height: 50px;

  margin-top: 0.6%;
`;

export const BusinessInformation = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 18px;

  label {
    margin-right: 5px;
    font-weight: bold;
  }
`;
