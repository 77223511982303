import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

import { Container, InputContainer } from './styles';

function Input({ width = '100%', height = 32, label, ...rest }, reference) {
  const [focus, setFocus] = useState(false);

  return (
    <Container width={width}>
      {label && (
        <label className={`${rest.className} ${focus ? 'focus' : ''}`}>
          {label}
        </label>
      )}
      <InputContainer
        height={height}
        className={`${rest.className} ${focus ? 'focus' : ''}`}
      >
        <input

          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          ref={reference}
          {...rest}
        />
        {rest.className === 'success' && <AiFillCheckCircle />}
        {rest.className === 'error' && <AiFillCloseCircle />}
      </InputContainer>
    </Container>
  );
}

Input.propTypes = {
  width: PropTypes.string,
  height: PropTypes.number,
  label: PropTypes.string,
};

Input.defaultProps = {
  width: '100%',
  height: 32,
  label: undefined,
};

Input.displayName = 'Input';

export default forwardRef(Input);
