import styled from 'styled-components';

const colors = {
  blueTitle: {
    default: '#4739EB',
    hover: '#5960FF',
    down: '#1C11A7',
    disabled: 'rgba(55, 65, 81, 0.48)'
  },
  grayTitle: {
    default: '#6C6C6C',
    hover: '#282728',
    down: '#8C8B8C',
    disabled: 'rgba(55, 65, 81, 0.48)'
  },
  blueDarkenTitle: {
    default:'#0C053F',
    hover: '#140361',
    down: '#060328',
    disabled: 'rgba(55, 65, 81, 0.48)'
  },
  listColorsBlue: {
    background: '#FFFFFF',
    backgroundHover: 'rgba(163, 175, 255, 0.12)',
    color: '#4739EB',
    colorHover: '#5960FF'
  },
  listColorsGray: {
    background: '#FFFFFF',
    backgroundHover: 'rgba(163, 175, 255, 0.12)',
    color: '#969797',
    colorHover: '#6C6C6C'
  },
  listColorsBlueDarken: {
    background: '#FFFFFF',
    backgroundHover: 'rgba(163, 175, 255, 0.12)',
    color: '#0C053F',
    colorHover: '#140361'
  }
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  color: #ffffff;

  h1 {
    color: #ffffff;
    margin-bottom: 1px;
    margin-left: 10px;
    font-size: 24px;
    font-weight: 500;
  }
  div.card {
    display: flex;
    flex-direction: column;
    background: #1f2940;
    border-radius: 15px;
    height: 90%;
    padding: 15px;
    justify-content: space-evenly;
  }
`;

export const Title = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: ${props => colors[props.theme].default};
  transition: all 0.5s ease;
  width: 200px;


  span {
    cursor: pointer;

    &.opened svg {
      transform: scaleY(-1);
    }
  }

  svg {
    margin-left: 8px;
    transform: scaleY(1);
  }

  &:hover {
    color: ${props => colors[props.theme].default};
  }
`

export const Menu = styled.ul`
  position: absolute;
  top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  transition: all 0.3s ease;

  width: auto;
  height: auto;

  background: ${props => colors[props.textColor].background};
  box-shadow: 0px 6px 16px rgba(71, 57, 235, 0.08);
  border-radius: 4px;
  margin-top: 20px;
`

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  cursor: pointer;
  background: ${props => colors[props.textColor].background};
  transition: all 0.3s ease;

  width: 135px;
  height: 39px;

  border-radius: 4px;

  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${props => colors[props.textColor].color};

  a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    color: ${props => colors[props.textColor].color};
  }

  &:hover {
    background: ${props => colors[props.textColor].backgroundHover};
    color: ${props => colors[props.textColor].colorHover};

    a {
      color: ${props => colors[props.textColor].colorHover};
    }
  }
`
