import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container, NewBankAccount, Body } from '../styles';

import Button from 'components/Button';

import ModalCreate from './Modals/ModalCreate';

import api from '../../../../../services/api';

export default function BankAccount({ routeParams }) {
  const { id: plannerId } = routeParams.params;
  const [showModal, setShowModal] = useState(false);
  const [bankAccount, setBankAccount] = useState(null);
  const [transfer_day, setTransferDay] = useState('');

  useEffect(() => {
    async function fetchBackAccount() {
      const { data } = await api.get(`users/${plannerId}/receivers`);
      setTransferDay(data.transfer_day);
      setBankAccount(data);
    }
    fetchBackAccount();
  }, [plannerId]);

  return (
    <>
      <Container>
        <NewBankAccount>
          <Button bg="info" size={13} onClick={() => setShowModal(true)}>
            Editar / Criar dados bancários
          </Button>
        </NewBankAccount>
        <Body>
          <div className="data">
            {bankAccount ? (
              <>
                <small>Data de transferência</small>
                <p>{transfer_day}</p>
                <small>Agência</small>
                <p>{bankAccount?.agencia}</p>
                <small>Agência Dígito</small>
                <p>{bankAccount?.agencia_dv}</p>
                <small>Código do banco</small>
                <p>{bankAccount?.bank_code}</p>
                <small>Conta</small>
                <p>{bankAccount?.conta}</p>
                <small>Dígito da conta</small>
                <p>{bankAccount?.conta_dv}</p>
                <small>CPF / CNPJ</small>
                <p>
                  {bankAccount?.document_number.length === 11
                    ? bankAccount?.document_number
                        .replace(/\D/g, '')
                        .replace(/(\d{3})(\d)/, '$1.$2')
                        .replace(/([.]\d{3})(\d)/, '$1.$2')
                        .replace(/([.]\d{3})(\d)/, '$1-$2')
                        .replace(/(-\d{2})(.)/, '$1')
                    : bankAccount?.document_number
                        .replace(/\D/g, '')
                        .replace(/(\d{2})(\d)/, '$1.$2')
                        .replace(/(.\d{3})(\d)/, '$1.$2')
                        .replace(/(.\d{3})(\d)/, '$1/$2')
                        .replace(/(\d{4})(\d)/, '$1-$2')
                        .replace(/([-]\d{2})(\d)/, '$1')}
                </p>
                <small>Nome</small>
                <p>{bankAccount?.legal_name}</p>
              </>
            ) : (
              <strong>Sem dados bancáris</strong>
            )}
          </div>
        </Body>
      </Container>
      {showModal && (
        <ModalCreate
          setShowModal={setShowModal}
          plannerId={plannerId}
          setBankAccount={setBankAccount}
          bankAccount={bankAccount}
          transfer_day={transfer_day}
          setTransferDay={setTransferDay}
        />
      )}
    </>
  );
}

BankAccount.propTypes = {
  routeParams: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
