import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { Container, Header, Body, BusinessInformation } from './styles';
import Button from 'components/Button';
import Contract from './Contract';
import CreditCard from './CreditCard';
import Finances from './Finances';

export default function Profile({ match }) {
  const [toggleButton, setToggleButton] = useState('contract');

  const location = useLastLocation();

  return (
    <Container>
      <Header>
        <div className="left-bar">
          <Link to={location ? location.pathname : '#'} data-tip="Voltar">
            <Icon type="import" />
          </Link>
          <p>Contrato do cliente</p>
        </div>
        <div className="options">
          <Button
            bg="info"
            size={35}
            onClick={() => setToggleButton('contract')}
          >
            Contrato
          </Button>
          <Button
            bg="info"
            size={90}
            onClick={() => setToggleButton('credit-card')}
          >
            Cartões de Crédito
          </Button>
          <Button
            bg="info"
            size={40}
            onClick={() => setToggleButton('finances')}
          >
            Finanças
          </Button>
        </div>
      </Header>
      <Body>
        <BusinessInformation>
          <div className="group">
            <label>CNPJ - </label>
            <span>28.616.998/0001-72</span>
          </div>
          <div className="group">
            <label>Razão Social - </label>
            <span>Real Cultura Financeira</span>
          </div>
        </BusinessInformation>
        <>
          {toggleButton === 'contract' && <Contract match={match} />}
          {toggleButton === 'credit-card' && <CreditCard match={match} />}
          {toggleButton === 'finances' && <Finances match={match} />}
        </>
      </Body>
    </Container>
  );
}

Profile.propTypes = {
  match: PropTypes.object.isRequired,
};
