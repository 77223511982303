import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from 'services/api';

import { storeUserSuccess, storeUserFailure } from './actions';

export function* storeUser({ payload }) {
  try {
    // Criação de usuário
    const { name, email, password, type, contract } = payload;

    const response = yield call(api.post, 'users', {
      name,
      email,
      password,
      type,
    });
    const user_id = response.data.id;

    yield put(storeUserSuccess(user_id));
    toast.success('Usuário cadastrado com sucesso');

    // Criação de contrato
    if (type === 'client') {
      const {
        recommendation_id,
        phone,
        birthdate,
        partner_name,
        partner_email,
        partner_phone,
        partner_birthdate,
        address,
        neighborhood,
        city,
        state,
        zip,
        rg,
        orgao,
        cpf,

        signature_date,
        contract_end_date,
        first_meeting_date,
        billing_date,
        number_of_steps,
        first_step_deadline,
        second_step_deadline,
        third_step_deadline,
        first_fee,
        second_fee,
        third_fee,
      } = contract;

      yield call(api.post, `/users/${user_id}/contracts`, {
        recommendation_id,
        phone,
        birthdate,
        partner_name,
        partner_email,
        partner_phone,
        partner_birthdate,
        address,
        neighborhood,
        city,
        state,
        zip,
        rg,
        orgao,
        cpf,
        signature_date,
        contract_end_date,
        first_meeting_date,
        billing_date,
        number_of_steps,
        first_step_deadline,
        second_step_deadline,
        third_step_deadline,
        first_fee,
        second_fee,
        third_fee,
      });
    } else if (type === 'planner') {
      const {
        phone,
        personalEmail,
        birthdate,
        address,
        neighborhood,
        city,
        state,
        rg,
        cpf,
        razao_social,
        inscricao_social,
        cnpj,
        business_address,
        business_neighborhood,
        business_city,
        business_state,
      } = contract;

      yield call(api.post, `/planners/${user_id}/contracts`, {
        phone,
        personal_email: personalEmail,
        birthdate,
        address,
        neighborhood,
        city,
        state,
        rg,
        cpf,
        razao_social,
        inscricao_social,
        cnpj,
        business_address,
        business_neighborhood,
        business_city,
        business_state,
      });
    }
    toast.success('Contrato criado com sucesso');
  } catch (err) {
    if (err?.response?.data) {
      err.response.data.forEach(message => toast.error(message.message));
    } else {
      toast.error(
        'Tivemos um problema, caso o usuário tenha sido criado com sucesso você deve editar o contrato dele e não criar um novo'
      );
    }
    yield put(storeUserFailure());
  }
}

export default all([takeLatest('@user/STORE_USER', storeUser)]);
