import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Container, DivGroup } from './styles';

import { CnpjInput } from 'components/masks';

import Input from 'components/Input/InputLabel';
import Button from 'components/Button';
import Select from 'components/Input/Dropdown';

import { api_ibge } from 'services/api';

export default function SecondStep({
  totalSteps,
  currentStep,
  previousStep,
  razao_social,
  setRazaoSocial,
  cnpj,
  setCnpj,
  inscricao_social,
  setInscricaoSocial,
  business_address,
  setAddressBusiness,
  business_neighborhood,
  setNeighborhoodBusiness,
  business_city,
  setCityBusiness,
  business_state,
  setStateBusiness,
  handleSubmit,
}) {
  const [allUfs, setAllUfs] = useState([]);

  const [selectedUf, setSelectedUf] = useState({});

  const [allCities, setAllCities] = useState([]);

  useEffect(() => {
    async function fetchUfData() {
      try {
        const { data } = await api_ibge.get('/estados');

        setAllUfs(
          data.map(uf => ({
            label: uf.nome,
            value: uf.nome,
            sigla: uf.sigla,
            id: uf.id,
          }))
        );
      } catch (err) {
        toast.error('Falha ao carregar Estados');
      }
    }
    fetchUfData();
  }, []);

  useEffect(() => {
    async function fetchCityData() {
      try {
        const { data } = await api_ibge.get(
          `/estados/${selectedUf.id}/municipios`
        );

        setAllCities(
          data.map(city => ({
            label: city.nome,
            value: city.nome,
            id: city.id,
          }))
        );
      } catch (err) {
        toast.error('Falha ao carregar cidades');
      }
    }
    fetchCityData();
  }, [selectedUf.id]);

  return (
    <Container>
      <div className="inputGroup">
        <div className="currentStep">
          <h3>
            Passo {currentStep} de {totalSteps}
          </h3>
        </div>
        <Input
          label="Razão Social"
          value={razao_social}
          onChange={e => setRazaoSocial(e.target.value)}
        />
      </div>

      <CnpjInput
        inputLabel
        label="CNPJ"
        value={cnpj || ''}
        onChange={cleanValue => setCnpj(cleanValue)}
      />

      <Input
        label="Inscrição Social"
        value={inscricao_social}
        onChange={e => setInscricaoSocial(e.target.value)}
      />

      <DivGroup>
        <Input
          label="Endereço Empresarial"
          width="45%"
          value={business_address}
          onChange={e => setAddressBusiness(e.target.value)}
        />
        <Input
          label="Bairro"
          width="45%"
          value={business_neighborhood}
          onChange={e => setNeighborhoodBusiness(e.target.value)}
        />
      </DivGroup>

      <DivGroup>
        <Select
          label="Estado"
          width="185px"
          value={{
            label: business_state,
            value: business_state,
          }}
          options={allUfs}
          onChange={selected => {
            setSelectedUf(selected);

            setStateBusiness(selected.sigla);
          }}
        />
        <Select
          label="Cidade"
          width="185px"
          value={{
            label: business_city,
            value: business_city,
          }}
          options={allCities.length >= 1 ? allCities : []}
          onChange={selected => setCityBusiness(selected.label)}
        />
      </DivGroup>
      <DivGroup className="buttonsForm">
        <Button size={20} bg="grafit" onClick={previousStep}>
          Voltar
        </Button>
        <Button size={20} bg="info" onClick={handleSubmit}>
          Cadastrar
        </Button>
      </DivGroup>
    </Container>
  );
}

SecondStep.propTypes = {
  totalSteps: PropTypes.number,
  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  razao_social: PropTypes.string.isRequired,
  setRazaoSocial: PropTypes.func.isRequired,
  cnpj: PropTypes.string.isRequired,
  setCnpj: PropTypes.func.isRequired,
  inscricao_social: PropTypes.string.isRequired,
  setInscricaoSocial: PropTypes.func.isRequired,
  business_address: PropTypes.string.isRequired,
  setAddressBusiness: PropTypes.func.isRequired,
  business_neighborhood: PropTypes.string.isRequired,
  setNeighborhoodBusiness: PropTypes.func.isRequired,
  business_city: PropTypes.string.isRequired,
  setCityBusiness: PropTypes.func.isRequired,
  business_state: PropTypes.string.isRequired,
  setStateBusiness: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SecondStep.defaultProps = {
  totalSteps: 0,
  currentStep: 0,
  previousStep: () => {},
};
