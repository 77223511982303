import { useState } from 'react';
import api from 'services/api';
import { toast } from 'react-toastify';
const useDeleteProfile = (selectedProfile, setProfiles, setShowModalExclude) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await api.delete(`/profiles/${selectedProfile.id}`);
      toast.success(`O usuário ${selectedProfile.name} do perfil ${selectedProfile.type} foi deletado!`);
      setProfiles(profiles =>
        profiles.filter(profile => {
          return profile.id !== selectedProfile.id;
        })
      );

    } catch (error) {
      toast.error('Falha ao deletar perfil! Tente novamente mais tarde.');
      console.error('Error on usePostProfiles', error);
    } finally {
      setLoading(false);
      setShowModalExclude(false);
    }
  };


  return { handleSubmit, loading };
};

export default useDeleteProfile;
