import * as React from 'react';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import CurrencyInput from 'components/masks/materialUI/CurrencyInput';

const ContractData = ({
    allCustomers,
    setAllCostumers,
    allPlanners,
    setAllPlanners,
    signature_date,
    setSignatureDate,
    contract_end_date,
    setContractEndDate,
    first_meeting_date,
    setFirstMeetingDate,
    deadline,
    setDeadline,
    hiring_manager,
    setHiringManager,
    how_meet,
    setHowMeet,
    who_planner,
    setWhoPlanner,
    indicated_by_colaborator,
    setIndicatedByColaborator,
    indicated_by_name,
    setIndicatedByName,
    contract_type,
    setContractType,
    total_deployment_value,
    setTotalDeploymentValue,
    billing_date,
    deployment_billing_date,
    setDeploymentBillingDate,
    setBillingDate,
    method_payment,
    setMethodPayment,
    monthly_fee,
    setMonthlyFee,
    min_fee,
    setMinFee,
    percent_under_equity,
    setPercentUnderEquity,
    broker_charge_authorization,
    setBrokerChargeAuthorization,
    recommendation_id,
    setRecommendationId,
}) => {

    const styleDefaultSx = {
        '& > :not(style)': { m: 1, width: '25ch' },
    }
    const styleDefaultInputDate = {
        svg: { color: '#fff' },
        input: { color: '#fff' },
        label: { color: '#fff' }
    }
    const setCostumer = new Set();
    const allCustomersUnique = allCustomers.filter((costumer) =>{
        const duplicateCostumer = setCostumer.has(costumer.label)
        setCostumer.add(costumer.label)
        return !duplicateCostumer
    })
    const setPlanners = new Set();
    const allPlannersUnique = allPlanners.filter((planner) =>{
        const duplicatePlanner = setPlanners.has(planner.label)
        setPlanners.add(planner.label)
        return !duplicatePlanner
    })

    const typeContractRender = {
        "START":
            <>
                <CurrencyInput
                    id="standard-basic"
                    label="Valor total impl:"
                    value={total_deployment_value}
                    onChange={(e) => setTotalDeploymentValue(e)}
                    variant="filled"
                />
                <DesktopDatePicker
                    label="Data cobrança impl"
                    inputFormat="dd/MM/yyyy"
                    sx={styleDefaultInputDate}
                    value={deployment_billing_date}
                    onChange={(e) => setDeploymentBillingDate(e)}
                    renderInput={(params) => <TextField color='primary' variant="filled" {...params} />}
                />
                <TextField
                    id="standard-basic"
                    label="Forma de pagamento impl"
                    variant="filled"
                    value={method_payment}
                    onChange={(e) => setMethodPayment(e.target.value)}
                />
            </>,
        "GROW":
            <>
                <CurrencyInput
                    id="standard-basic"
                    label="Valor total impl:"
                    value={total_deployment_value}
                    onChange={(e) => setTotalDeploymentValue(e)}
                    variant="filled"
                />
                <DesktopDatePicker
                    label="Data cobrança impl"
                    inputFormat="dd/MM/yyyy"
                    sx={styleDefaultInputDate}
                    value={deployment_billing_date}
                    onChange={(e) => setDeploymentBillingDate(e)}
                    renderInput={(params) => <TextField color='primary' variant="filled" {...params} />}
                />
                <TextField
                    id="standard-basic"
                    label="Forma de pagamento impl"
                    variant="filled"
                    value={method_payment}
                    onChange={(e) => setMethodPayment(e.target.value)}
                />
                <CurrencyInput
                    id="standard-basic"
                    label="FEE mensal:"
                    variant="filled"
                    value={monthly_fee}
                    onChange={(e) => setMonthlyFee(e)}
                />
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Dia da cobrança</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={billing_date}
                        onChange={(e) => setBillingDate(e.target.value)}
                    >
                        <FormControlLabel value={5} control={<Radio />} label="5" />
                        <FormControlLabel value={15} control={<Radio />} label="15" />
                        <FormControlLabel value={25} control={<Radio />} label="25" />
                    </RadioGroup>
                </FormControl>
            </>,
        "WEALTH/FAMILY OFFICE":
            <>
                <CurrencyInput
                    id="standard-basic"
                    label="Valor total impl:"
                    value={total_deployment_value}
                    onChange={(e) => setTotalDeploymentValue(e)}
                    variant="filled"
                />
                <DesktopDatePicker
                    label="Data cobrança impl"
                    inputFormat="dd/MM/yyyy"
                    sx={styleDefaultInputDate}
                    value={deployment_billing_date}
                    onChange={(e) => setDeploymentBillingDate(e)}
                    renderInput={(params) => <TextField color='primary' variant="filled" {...params} />}
                />
                <TextField
                    id="standard-basic"
                    label="Forma de pagamento impl"
                    variant="filled"
                    value={method_payment}
                    onChange={(e) => setMethodPayment(e.target.value)}
                />
                <CurrencyInput
                    id="standard-basic"
                    label="FEE min:"
                    variant="filled"
                    value={min_fee}
                    onChange={(e) => setMinFee(e)}
                />
                <CurrencyInput
                    id="standard-basic"
                    label="% sobre patrimônio:"
                    variant="filled"
                    currency='%'
                    value={percent_under_equity}

                    onChange={(e) =>
                        setPercentUnderEquity(e)
                    }
                />
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Dia da cobrança</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={billing_date}
                        onChange={(e) => setBillingDate(e.target.value)}
                    >
                        <FormControlLabel value="5" control={<Radio />} label="5" />
                        <FormControlLabel value="15" control={<Radio />} label="15" />
                        <FormControlLabel value="25" control={<Radio />} label="25" />
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Cobrar pela corretora:</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={broker_charge_authorization}
                        onChange={(e) => setBrokerChargeAuthorization(e.target.value)}
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Sim" />
                        <FormControlLabel value={false} control={<Radio />} label="Não" />
                    </RadioGroup>
                </FormControl>
            </>

    }

    const indicationRender = {
        'INDICAÇÃO DO CLIENTE':
            <> 
                <FormControl>
                    <Autocomplete
                        id="combo-box-demo"
                        selectOnFocus
                        options={allCustomersUnique}
                        getOptionLabel={(option) => option.label}
                        value={recommendation_id ? allCustomersUnique[allCustomersUnique.findIndex((option) => option.id === recommendation_id)] : null}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => newValue !== null ? setRecommendationId(newValue.id) : setRecommendationId('')}
                        renderInput={(params) => <TextField {...params} label="Quem indicou?" variant="outlined"/>} 
                    />
                </FormControl>

                <FormControl>
                    <Autocomplete
                        id="combo-box-demo"
                        selectOnFocus
                        clearText='Clear'
                        options={allPlannersUnique}
                        getOptionLabel={(option) => option.label}
                        value={who_planner ? allPlannersUnique[allPlannersUnique.findIndex((option) => option.id === who_planner)] : null}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => newValue !== null ? setWhoPlanner(newValue.id) : setWhoPlanner('')}
                        renderInput={(params) => <TextField {...params} label="Qual planejador?" variant="outlined"/>}
                    />
                </FormControl>

            </>,
        'INDICAÇÃO DE COLABORADOR':
            <FormControl>
                <TextField
                    id="standard-basic"
                    label="Quem indicou?"
                    variant="filled"
                    value={indicated_by_colaborator}
                    onChange={(e) => setIndicatedByColaborator(e.target.value)}
                />
            </FormControl>,
        'OUTROS':
            <FormControl>
                <TextField
                    id="standard-basic"
                    label="Que forma?"
                    variant="filled"
                    value={indicated_by_name}
                    helperText="Evento, Rede social..."
                    onChange={(e) => setIndicatedByName(e.target.value)}
                />
            </FormControl>
    }

    return (
        <DialogContent>
            <Typography sx={{ fontWeight: 'bold' }} color='primary' variant="subtitle2" gutterBottom>
                Dados do serviço contratado
            </Typography>
            <Box
                component="form"
                sx={styleDefaultSx}
                noValidate
                autoComplete="off"
            >

                <DesktopDatePicker
                    label="Data da assinatura"
                    inputFormat="dd/MM/yyyy"
                    sx={styleDefaultInputDate}
                    value={signature_date}
                    onChange={(e) => setSignatureDate(e)}
                    renderInput={
                        (params) =>
                            <TextField color='primary' variant="filled" {...params} />}
                />

                <DesktopDatePicker
                    label="Fim do contrato"
                    inputFormat="dd/MM/yyyy"

                    sx={styleDefaultInputDate}
                    value={contract_end_date}
                    onChange={(e) => setContractEndDate(e)}
                    renderInput={(params) => <TextField color='primary' variant="filled" {...params} />}
                />

                <DesktopDatePicker
                    label="Primeira reunião"
                    inputFormat="dd/MM/yyyy"
                    sx={styleDefaultInputDate}
                    value={first_meeting_date}
                    onChange={(e) => setFirstMeetingDate(e)}
                    renderInput={(params) => <TextField color='primary' variant="filled" {...params} />}
                />
                <TextField
                    id="standard-basic"
                    label="Prazo(meses)"
                    variant="filled"
                    value={deadline}
                    onChange={(e) => setDeadline(e.target.value)}
                    type='number' />
                 
                <FormControl>
                    <Autocomplete
                        id="combo-box-demo"
                        options={allPlannersUnique}
                        getOptionLabel={(option) => option.label}
                        value={hiring_manager ? allPlannersUnique[allPlannersUnique.findIndex((option) => option.id === hiring_manager)] : null}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => newValue !== null ? setHiringManager(newValue.id) : setHiringManager('')}
                        renderInput={(params) => <TextField {...params} label="Responsável pela contratação" variant="outlined"/>}
                    />
                </FormControl>
            </Box>
            <Box
                component="form"
                sx={styleDefaultSx}
                noValidate
                autoComplete="off"
            >
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Como conheceu a Yuno:</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Como conheceu a Yuno:"
                        value={how_meet}
                        onChange={(e) => setHowMeet(e.target.value)}
                        variant="filled"
                    >
                        <MenuItem value={"INDICAÇÃO DO CLIENTE"}>INDICAÇÃO DO CLIENTE</MenuItem>
                        <MenuItem value={"INDICAÇÃO DE COLABORADOR"}>INDICAÇÃO DE COLABORADOR</MenuItem>
                        <MenuItem value={"OUTROS"}>OUTROS</MenuItem>
                    </Select>
                </FormControl>
                {indicationRender[how_meet]}
                <Typography color="primary" variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>Política comercial</Typography>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Tipo de contrato</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Tipo de contrato"
                        value={contract_type}
                        onChange={(e) => setContractType(e.target.value)}
                        variant="filled"
                    >
                        <MenuItem value={"START"}>START</MenuItem>
                        <MenuItem value={"GROW"}>GROW</MenuItem>
                        <MenuItem value={"WEALTH/FAMILY OFFICE"}>WEALTH/FAMILY OFFICE</MenuItem>
                    </Select>
                </FormControl>
                {typeContractRender[contract_type]}
            </Box>

        </DialogContent >
    )
}

export default ContractData;