import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';

import { toast } from 'react-toastify';

import api from '../../../../../../../../services/api';

import { Form } from '../styles';

import Modal from 'components/Modal/Default';

import Button from 'components/Button';

import Input from 'components/Input/InputLabel';

import CurrencyInput from 'components/Input/Currency';

// amount, charges, card_id, splits;

export default function CreateModal({ setShowModal, match, setSubscriptions }) {
  const { id } = match.params;

  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPlanner, setSelectedPlanner] = useState(null);
  const [charges, setCharges] = useState(0);
  const [splits, setSplits] = useState([]);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [cards, setCards] = useState([]);
  const [planners, setPlanners] = useState([]);
  const [adm, setAdm] = useState([]);

  useEffect(() => {
    api.get(`users/${id}/cards`).then(response => {
      setCards(response.data);
    });

    api
      .get(`/users`, { params: { user_type: 'planner', all: true } })
      .then(response => {
        setPlanners(response.data);
      });

    api
      .get(`/users`, { params: { user_role: 'adm', all: true } })
      .then(response => {
        setAdm(response.data);
      });
  }, [id]);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    if (
      !selectedCard ||
      !selectedPlanner ||
      typeof Number(charges) !== 'number' ||
      !splits.length ||
      !amount
    ) {
      toast.error(
        'Preencha todos os dados e lembre-se escolha pelo menos um split'
      );
      return;
    }

    if (
      splits.reduce((total, current) => total + current.percentage, 0) !== 100
    ) {
      toast.error('A soma das porcentagens de split deve ser 100');
      return;
    }

    try {
      const { data } = await api.post(
        `planners/${selectedPlanner.value.id}/clients/${id}/subscriptions`,
        {
          amount,
          charges,
          card_id: selectedCard.value.id,
          splits: splits.map(split => ({
            user_id: split.user.id,
            percentage: split.percentage,
          })),
        }
      );
      toast.success('Cadastro de assinatura realizada com sucesso');
      setSubscriptions(oldState => [...oldState, data]);
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.message
      ) {
        toast.error(err?.response?.data?.error?.message);
        return;
      }

      toast.error('Tivemos um erro');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title="Nova Assinatura" color="info">
      <Form onSubmit={handleSubmit}>
        <Select
          options={planners.map(planner => ({
            value: planner,
            label: planner.name,
          }))}
          value={selectedPlanner}
          onChange={selected => setSelectedPlanner(selected)}
          placeholder="Planejador"
        />
        <CurrencyInput
          prefix="R$ "
          label="Amount"
          value={amount}
          onChange={cleanValue => setAmount(cleanValue)}
        />
        <Input
          type="number"
          value={charges}
          onChange={e => setCharges(e.target.value)}
          label="Charges"
        />
        <Select
          options={cards.map(card => ({
            value: card,
            label: `****-${card?.last_digits}`,
          }))}
          value={selectedCard}
          onChange={selected => setSelectedCard(selected)}
          placeholder="Cartão de crédito"
        />
        {splits?.map((split, index) => (
          <div key={split.id} className="split-group">
            <span>Split {index + 1}</span>
            <Select
              options={planners
                .concat(adm)
                .filter(user => {
                  return !splits.map(data => data?.user?.id).includes(user.id);
                })
                .map(planner => ({
                  value: planner,
                  label: planner?.name,
                }))}
              value={{
                value: splits[index]?.user,
                label: splits[index]?.user?.name,
              }}
              onChange={selected => {
                setSplits(oldState =>
                  oldState.map(oldSplit => {
                    if (oldSplit.id !== split.id) return oldSplit;
                    return {
                      ...oldSplit,
                      user: selected.value,
                    };
                  })
                );
              }}
              placeholder="Planejador"
            />
            <CurrencyInput
              value={splits[index]?.percentage}
              onChange={cleanResult => {
                setSplits(oldState =>
                  oldState.map(oldSplit => {
                    if (oldSplit.id !== split.id) return oldSplit;
                    return {
                      ...oldSplit,
                      percentage: cleanResult,
                    };
                  })
                );
              }}
              suffix="%"
              label="Porcentagem"
            />
          </div>
        ))}
        <button
          type="button"
          className="split"
          onClick={() => {
            const lastSplit = splits[splits.length - 1];
            if (!lastSplit) {
              setSplits(oldState => [
                ...oldState,
                { percentage: null, user: null, id: splits.length },
              ]);
              return;
            }
            if (!lastSplit?.user?.id && !lastSplit?.percentage) return;
            setSplits(oldState => [
              ...oldState,
              { percentage: null, user: null, id: splits.length },
            ]);
          }}
        >
          Adicionar split
        </button>
        <div className="divGroup">
          <Button stripped bg="grafit" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button bg="info">{loading ? 'Carregando...' : 'Confirmar'}</Button>
        </div>
      </Form>
    </Modal>
  );
}

CreateModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};
