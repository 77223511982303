import styled from 'styled-components';

export const Container = styled.div`
  div.currentStep {
    display: flex;
    justify-content: center;
    h3 {
      font-family: 'Nunito', sans-serif;
      color: #9c9c9c;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
    }
  }
  div.inputGroup {
    display: flex;
    flex-direction: column;
    input {
      padding-left: 10px;
    }
  }
`;

export const DivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1%;

  &.buttonsForm {
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-left: 2%;
    }
  }
`;
