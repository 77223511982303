import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { Container } from './styles';

export default function Dropdown({
  options,
  height,
  width,
  label,
  styles,
  theme,
  ...rest
}) {
  return (
    <Container width={width}>
      {label && <label className={`${rest.className}`}>{label}</label>}
      <Select
        options={options}
        height={height}
        styles={styles}
        theme={theme}
        className="custom__container"
        classNamePrefix="custom"
        {...rest}
      />
    </Container>
  );
}

Dropdown.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  width: PropTypes.string,
  height: PropTypes.number,
  label: PropTypes.string,
};

Dropdown.defaultProps = {
  width: '300px',
  height: 32,
  label: undefined,
};
