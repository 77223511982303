import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from 'services/history';
import api, { api_nort } from 'services/api';

import { signInSuccess, signInFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api_nort.post, 'Auth/session', {
      email,
      password,
    });

    const { user, token, roles, permissions } = response.data;
    const authType = ['adm', 'assistant-planner']
    if (!authType.includes(user.type)) {
      toast.error('Usuário não permitido!');
      yield put(signInFailure());
      return;
    }
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api_nort.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(signInSuccess(token, user, roles, permissions));
  } catch (err) {
    toast.error(
      'Falha na autenticação, verifique seus dados e tente novamente!'
    );
    yield put(signInFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api_nort.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
