import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { Icon } from 'antd';

import { format } from 'date-fns';

import { AiOutlineCaretRight, AiOutlineCaretLeft } from 'react-icons/ai';

import ReactPaginate from 'react-paginate';

import { toast } from 'react-toastify';
import {
  Container,
  Header,
  ContainerPaginate,
  DivInput,
  DivButton,
} from '../styles';

import userName from 'utils/userName';

import Table from 'components/Table/Default';
import Dropdown from 'components/Button/Dropdown';

import Button from 'components/Button';

import api from 'services/api';

export default function Profile({ match }) {
  const [customers, setCustomers] = useState([]);

  const [page, setPage] = useState(1);

  const [pageCount, setPageCount] = useState(1);

  // Mostra input de pesquisa
  const [showInput, setShowInput] = useState(false);

  // Value do input de pesquisa
  const [searchInputPlanner, setInputPlanner] = useState('');

  const token = useSelector(state => state.auth.token);

  const loggedUser = useSelector(state => state.session.profile);

  const { id } = match.params;

  const headTable = ['Nome', 'ID', 'Patrimônio', 'Data', 'Receita', 'Fee', ''];

  async function createUserTest() {
    const type = 'client';

    /**
     * users/:id -> id do planejador
     * retorna os dados do planejador
     */

    const data = await api.get(`/users/${id}`);
    const name_client = `${data.data.name} - CLIENTE`;
    const emailEditado = data.data.email.split('@');
    const email_client = `${emailEditado[0]}_cliente@realeasy.com`;
    const password = '123456';

    try {
      const { data: user } = await api.post('/users', {
        name: name_client,
        email: email_client,
        password,
        type,
      });

      await api.post(`/users/${user.id}/contracts`, {
        planner_id: id,

        phone: '(00)00000-0000',
        birthdate: '2020-10-08',
        partner_name: 'Partner Test',
        partner_email: email_client,
        partner_phone: '(00)00000-0000',
        partner_birthdate: '2020-10-08',
        address: '1 Destiny USA Dr, Syracuse, NY 13204',
        neighborhood: 'Syracuse',
        city: 'New York',
        state: 'California',
        zip: '13204',
        rg: '50.445.681-7',
        orgao: 'PC',
        cpf: '930.248.030-50',
        signature_date: '2020-10-08',
        contract_end_date: '2020-12-08',
        first_meeting_date: '2020-10-09',
        billing_date: 25,
        number_of_steps: 3,
        first_step_deadline: 4,
        second_step_deadline: 3,
        third_step_deadline: 3,
        first_fee: 344.68,
        second_fee: 641.73,
        third_fee: 341.22,
      });

      setCustomers([
        ...customers,
        [
          user.name,
          `#${user.id}`,
          '',
          format(new Date(user.created_at), 'dd/MM/yyyy'),
          '',
          '',
          <Dropdown>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    user.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Acessar a Yuno On
            </button>
          </Dropdown>,
        ],
      ]);

      toast.success('Cliente de teste criado com sucesso');
    } catch (err) {
      const { data: messages } = err.response;
      messages[0].message.toString() === 'email já está em uso.'
        ? toast.error('Cliente de teste já cadastrado no sistema')
        : toast.error('Ocorreu um erro na aplicação');
    }
  }

  useEffect(() => {
    async function fetchCustomers() {
      const response = await api.get(`/planners/${id}/clients`, {
        params: {
          page,
          per_page: 5,
          name: searchInputPlanner,
          with_contract: true,
        },
      });

      setPageCount(response.data.lastPage);
      setCustomers(
        response.data.data.map(customer => [
          userName(customer),
          `#${customer.id}`,
          '',
          format(new Date(customer.created_at), 'dd/MM/yyyy'),
          '',
          '',
          <Dropdown>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    customer.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Acessar a Yuno On
            </button>
          </Dropdown>,
        ])
      );
    }
    fetchCustomers();
  }, [id, loggedUser.id, page, searchInputPlanner, token]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/planners/${id}/clients`, {
        params: {
          name: searchInputPlanner,
          per_page: 5,
        },
      });
      setPageCount(response.data.lastPage);
      setPage(1);

      setCustomers(
        response.data.data.map(customer => [
          userName(customer),
          `#${customer.id}`,
          '',
          format(new Date(customer.created_at), 'dd/MM/yyyy'),
          '',
          '',
          <Dropdown>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    customer.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Acessar a Yuno On
            </button>
          </Dropdown>,
        ])
      );
    }
    fetchData();
  }, [id, loggedUser.id, searchInputPlanner, token]);

  // Disparada quando clicar em um elemento da paginação
  function handlePageClick(data) {
    setPage(data.selected + 1);
  }

  return (
    <>
      <Container>
        <Header>
          <div className="left-bar" />
          <div className="search">
            <DivInput>
              <button onClick={() => setShowInput(!showInput)} type="submit">
                <Icon type="search" />
              </button>
              <input
                className={showInput ? 'show' : ''}
                type="text"
                placeholder="Pesquisar..."
                value={searchInputPlanner}
                onChange={e => setInputPlanner(e.target.value)}
              />
              <DivButton>
                <Button onClick={() => createUserTest()}>
                  Novo Usuário Teste
                </Button>
              </DivButton>
            </DivInput>
          </div>
        </Header>
        <Table head={headTable} body={customers} />
      </Container>
      <ContainerPaginate>
        <ReactPaginate
          previousLabel={<AiOutlineCaretLeft size={22} />}
          pageCount={pageCount}
          previousLinkClassName="previous_page"
          nextLabel={<AiOutlineCaretRight size={22} />}
          nextLinkClassName="next_page"
          activeClassName="active"
          onPageChange={handlePageClick}
        />
      </ContainerPaginate>
    </>
  );
}

Profile.propTypes = {
  match: PropTypes.object.isRequired,
};
