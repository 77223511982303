import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'antd';

import { Container, Header, Body } from './styles';

import Button from 'components/Button';

import CustomersOfPlanner from './CutomerOfPlanner';
import BankAccount from './BankAccount';

export default function Profile({ match }) {
  const [toggleButton, setToggleButton] = useState('bank_account');

  return (
    <>
      <Container>
        <Header>
          <div className="left-bar">
            <Icon type="import" />
            <p>Perfil</p>
          </div>

          <div className="options">
            <Button bg="info" onClick={() => setToggleButton('clients')}>
              Clientes
            </Button>
            <Button bg="info" onClick={() => setToggleButton('bank_account')}>
              Dados bancários
            </Button>
          </div>
        </Header>
        <Body>
          {toggleButton === 'clients' ? (
            <CustomersOfPlanner match={match} />
          ) : (
            <BankAccount routeParams={match} />
          )}
        </Body>
      </Container>
    </>
  );
}

Profile.propTypes = {
  match: PropTypes.object.isRequired,
};
