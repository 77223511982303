import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

import { Container, InputContainer } from './styles';

import CurrencyInput from '../../masks/CurrencyInput';

export default function Input({
  width,
  height,
  label,
  prefix,
  suffix,
  reference,
  ...rest
}) {
  const [focus, setFocus] = useState(false);

  return (
    <Container width={width}>
      {label && (
        <label className={`${rest.className} ${focus ? 'focus' : ''}`}>
          {label}
        </label>
      )}
      <InputContainer
        height={height}
        className={`${rest.className} ${focus ? 'focus' : ''}`}
      >
        <CurrencyInput
          prefix={prefix}
          suffix={suffix}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          ref={reference}
          {...rest}
        />
        {rest.className === 'success' && <AiFillCheckCircle />}
        {rest.className === 'error' && <AiFillCloseCircle />}
      </InputContainer>
    </Container>
  );
}

Input.propTypes = {
  width: PropTypes.string,
  height: PropTypes.number,
  label: PropTypes.string,
};

Input.defaultProps = {
  width: '100%',
  height: 32,
  label: undefined,
};
