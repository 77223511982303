import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { AiOutlineCaretRight, AiOutlineCaretLeft } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import Select from 'components/Input/Dropdown';
import {
  Container,
  Header,
  DivInput,
  ContainerPaginate,
  DivButtons,
  StyleDefaultInputDate,
} from './styles';
import ClientManagement from './CreateClienteModal';
import debounce from 'utils/debounce';
import Table from 'components/Table/Default';
import Button from 'components/Button';
import Dropdown from 'components/Button/Dropdown';
import ModalDefault from 'components/Modal/Default';
import api, { api_nort } from 'services/api';
import userName from 'utils/userName';
import Input from 'components/Input/InputLabel';
import Loading from 'components/Loading';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import Can from 'components/Can';

export default function Customers() {
  const token = useSelector(state => state.auth.token);
  const loggedUser = useSelector(state => state.session.profile);

  const [activeModal, setActiveModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // Mostra modal de data para última visita
  const [showDateLastVisitModal, setshowDateLastVisitModal] = useState(false);

  // Data última visita no cliente
  const [newDateLastVisit, setnewDateLastVisit] = useState('');

  // Usuário selecionado para exclusão ou edição
  const [selectedUser, setSelectedUser] = useState({});

  // Armazerara customers provenientes da api
  const [customers, setCustomers] = useState([]);

  // Mostra input de pesquisa
  // const [showInput, setShowInput] = useState(false);
  // Value do input de pesquisa
  const [searchInputCustomer, setInputCustomer] = useState('');
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const inputRef = useRef();
  const location = useLastLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [newDateEndContract, setNewDateEndContract] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const headTable = ['Nome', 'Dt. última visita', 'ID', 'Data', 'Status', ''];
  const isAccountPagarme = useCallback(
    () => loggedUser.email === 'pagarme@pagarme.com',
    [loggedUser]
  );
  const listFilter = [
    { label: 'Ativos', value: 'active' },
    { label: 'Inativos', value: 'disabled' },
    { label: 'Todos', value: 'all' },
  ];
  const [filter, setFilter] = useState(listFilter[2]);

  // Busca os clientes a partir do input de pesquisa
  useEffect(() => {
    async function fetchedCustomerSearch() {
      setIsLoading(true);
      const response = await api.get('/users', {
        params: {
          user_type: 'client',
          per_page: 5,
          name: isAccountPagarme() ? 'Pagarme' : searchInputCustomer,
          with_contract: true,
        },
      });
      setPageCount(response.data.lastPage);
      setPage(1);
      setCustomers(
        response.data.data.map(customer => [
          <Link to={`/customers/${customer.id}/contracts`}>
            {userName(customer)}
          </Link>,
          `#${customer.id}`,
          '',
          format(new Date(customer.created_at), 'dd/MM/yyyy'),
          '',
          '##############',
          <Dropdown>
            <Can canRoles={['adm']}>
              <button
                onMouseDown={() => {
                  setshowDateLastVisitModal(true);
                  setSelectedUser(customer);
                }}
              >
                Dt. última visita
              </button>
            </Can>
            <button
              onMouseDown={() => {
                setShowUpdateModal(true);
                setSelectedUser(customer);
              }}
            >
              Editar
            </button>
            <Can canRoles={['adm']}>
              <button
                onMouseDown={() => {
                  setShowDisableModal(true);
                  setSelectedUser(customer);
                }}
              >
                Excluir
              </button>
            </Can>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    customer.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Yuno On
            </button>
          </Dropdown>,
        ])
      );
    }
    setIsLoading(false);
    debounce(fetchedCustomerSearch, 600);
  }, [isAccountPagarme, loggedUser.id, searchInputCustomer, token]);

  // Busca os clientes da api
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await api.get('/users', {
        params: {
          user_type: 'client',
          page,
          per_page: 5,
          with_contract: true,
          active: filter.value,
          name: isAccountPagarme() ? 'Pagarme' : searchInputCustomer,
        },
      });

      if (response.status === 200) {
        setPageCount(response.data.lastPage);
        setCustomers(
          response.data.data.map(customer => [
            <Link to={`/customers/${customer.id}/contracts`}>
              {userName(customer)}
            </Link>,
            customer.ultimaVisita
              ? format(
                  new Date(customer.ultimaVisita?.data_visita),
                  'dd/MM/yyyy'
                )
              : '-',
            `#${customer.id}`,
            format(new Date(customer.created_at), 'dd/MM/yyyy'),
            customer.contract?.active ? 'Ativo' : 'Inativo',
            <Dropdown>
              <Can canRoles={['adm']}>
                <button
                  onMouseDown={() => {
                    setshowDateLastVisitModal(true);
                    setSelectedUser(customer);
                  }}
                >
                  Dt. última visita
                </button>
                <button
                  onMouseDown={() => {
                    setShowUpdateModal(true);
                    setSelectedUser(customer);
                  }}
                >
                  Editar
                </button>
                <button
                  onMouseDown={() => {
                    setShowDisableModal(true);
                    setSelectedUser(customer);
                  }}
                >
                  {customer.contract?.active ? 'Desativar' : 'Ativar'}
                </button>
              </Can>
              <button
                onMouseDown={() => {
                  window.open(
                    `${process.env.REACT_APP_CLIENT_URL ||
                      'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                      customer.id
                    }&external_user_id=${loggedUser.id}`
                  );
                }}
              >
                Yuno On
              </button>
            </Dropdown>,
          ])
        );
      } else {
        toast.info('Não foi encontrado nenhum resultado');
      }

      setIsLoading(false);
    }
    debounce(fetchData, 600);
  }, [
    isAccountPagarme,
    loggedUser.id,
    page,
    searchInputCustomer,
    token,
    filter,
  ]);

  // Disparada quando clicar em um elemento da paginação
  function handlePageClick(data) {
    setPage(data.selected + 1);
  }

  function handleShowModal() {
    setActiveModal(true);
  }

  //CONTROLADOR ATIVAR/DESATIVAR CLIENTE
  async function handleDisableUser(user_id) {
    try {
      const { active } = selectedUser.contract;
      const { data: data_contract } = await api.put(
        `/users/${selectedUser.id}/contracts/disable`,
        {
          contract_end_date: newDateEndContract,
          active: !active,
        }
      );

      setSelectedUser(user => {
        user.contract = data_contract;
        return user;
      });

      setCustomers(customers =>
        customers.map(customer => {
          const customer_id = customer[2].substr(1).toString();

          if (customer_id === data_contract.user_id.toString()) {
            return [
              <Link to={`/customers/${data_contract.id}/contracts`}>
                {userName(selectedUser)}
              </Link>,
              selectedUser?.ultimaVisita
                ? format(
                    new Date(selectedUser?.ultimaVisita?.data_visita),
                    'dd/MM/yyyy'
                  )
                : '',
              `#${data_contract.user_id}`,
              format(new Date(data_contract.created_at), 'dd/MM/yyyy'),
              data_contract.active ? 'Ativo' : 'Inativo',
              <div className="div-icons">
                <div className="icons">
                  <Dropdown>
                    <button
                      onMouseDown={() => {
                        setshowDateLastVisitModal(true);
                        setSelectedUser(selectedUser);
                      }}
                    >
                      Dt. última visita
                    </button>
                    <button
                      onMouseDown={() => {
                        setShowUpdateModal(true);
                        setSelectedUser(selectedUser);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      onMouseDown={() => {
                        setShowDisableModal(true);
                        setSelectedUser(selectedUser);
                      }}
                    >
                      {data_contract?.active ? 'Desativar' : 'Ativar'}
                    </button>
                    <button
                      onMouseDown={() => {
                        window.open(
                          `${process.env.REACT_APP_CLIENT_URL ||
                            'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                            customer.id
                          } &external_user_id=${loggedUser.id}`
                        );
                      }}
                    >
                      Yuno On
                    </button>
                  </Dropdown>
                </div>
              </div>,
            ];
          }
          return customer;
        })
      );

      let message = () => {
        if (!data_contract.active) {
          return 'Desativado com sucesso';
        } else {
          return 'Ativado com sucesso';
        }
      };

      toast.success(message);
    } catch (err) {
      toast.error('Falha ao alterar status do cliente');
    }
  }

  //CONTROLADOR Dt. Ultima visita
  async function handleDateLastVisitModal() {
    let isValidDate = Date.parse(newDateLastVisit);
    if (isNaN(isValidDate)) {
      toast.error('Data de última visita é inválida');
      return;
    }
    if (new Date(newDateLastVisit) < new Date('1900-01-01')) {
      toast.error('Data de última visita é inválida');
      return;
    }
    setIsLoading(true);
    try {
      await api_nort.post(`/usuario/visita`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        data_visita: newDateLastVisit,
        id_planejador: loggedUser.id,
        id_cliente: selectedUser.id,
      });

      const response = await api.get('/users', {
        params: {
          user_type: 'client',
          page,
          per_page: 5,
          with_contract: true,
          active: filter.value,
          name: isAccountPagarme() ? 'Pagarme' : searchInputCustomer,
        },
      });

      setPageCount(response.data.lastPage);
      setCustomers(
        response.data.data.map(customer => [
          <Link to={`/customers/${customer.id}/contracts`}>
            {userName(customer)}
          </Link>,
          customer.ultimaVisita
            ? format(new Date(customer.ultimaVisita?.data_visita), 'dd/MM/yyyy')
            : '-',
          `#${customer.id}`,
          format(new Date(customer.created_at), 'dd/MM/yyyy'),
          customer.contract?.active ? 'Ativo' : 'Inativo',
          <Dropdown>
            <button
              onMouseDown={() => {
                setshowDateLastVisitModal(true);
                setSelectedUser(customer);
              }}
            >
              Dt. última visita
            </button>
            <button
              onMouseDown={() => {
                setShowUpdateModal(true);
                setSelectedUser(customer);
              }}
            >
              Editar
            </button>
            <button
              onMouseDown={() => {
                setShowDisableModal(true);
                setSelectedUser(customer);
              }}
            >
              {customer.contract?.active ? 'Desativar' : 'Ativar'}
            </button>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    customer.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Yuno On
            </button>
          </Dropdown>,
        ])
      );

      toast.success('ùltimo dia de visita adicionado com sucesso');
      setIsLoading(false);
    } catch (err) {
      toast.error('Falha ao adicionar o último dia da visita');
      setIsLoading(false);
    }
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: '0 !important',
      // This line disable the blue border
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
      background: '#023950',
      // match with the menu
      borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
      // boxShadow: 'none',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color: '#fff',
      // specify a fallback color here for those values not accounted for in the styleMap
    }),
  };

  return (
    <>
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <Container>
          <Header>
            <aside>
              <Link to={location ? location.pathname : '#'} data-tip="Voltar">
                <Icon type="import" />
              </Link>
              <p>Clientes</p>
            </aside>

            <DivInput>
              <button
              // onClick={() => {
              //   setShowInput(!showInput);
              //   inputRef.current.focus();
              // }}
              // type="submit"
              >
                <Icon type="search" />
              </button>

              <input
                ref={inputRef}
                className={'show'}
                type="text"
                placeholder="Pesquisar..."
                value={searchInputCustomer}
                onChange={e => setInputCustomer(e.target.value)}
              />
            </DivInput>
          </Header>

          <div className="subHeader">
            <Select
              value={filter}
              options={listFilter}
              onChange={selected => setFilter(selected)}
              styles={customStyles}
              theme={theme => ({
                ...theme,
                borderRadius: 0,

                colors: {
                  neutral50: '#fff',
                  primary: '#fff',
                },
              })}
            />
            <Button
              bg="info"
              className="novo-cliente"
              onClick={handleShowModal}
            >
              Novo Cliente
            </Button>
          </div>

          <Table head={headTable} body={customers} />
          {activeModal && (
            <ClientManagement
              setActiveModal={setActiveModal}
              activeModal={activeModal}
              setCustomers={setCustomers}
              setShowUpdateModal={setShowUpdateModal}
              setSelectedUser={setSelectedUser}
              setShowDisableModal={setShowDisableModal}
              token={token}
              loggedUser={loggedUser}
            />
          )}
          {showDisableModal && (
            <ModalDefault color="danger" title="Confirmar?" icon={null}>
              {!selectedUser.contract.active ? (
                <Input
                  label="Fim do Contrato"
                  type="date"
                  width="100%"
                  value={
                    newDateEndContract &&
                    format(new Date(newDateEndContract), 'yyyy-MM-dd')
                  }
                  onChange={e => {
                    setNewDateEndContract(new Date(e.target.value));
                  }}
                />
              ) : (
                ''
              )}
              <DivButtons>
                <Button
                  bg="grafit"
                  onClick={() => setShowDisableModal(!showDisableModal)}
                >
                  Cancelar
                </Button>
                <Button
                  bg="danger"
                  onClick={() => {
                    handleDisableUser(selectedUser.id);
                    setShowDisableModal(!showDisableModal);
                  }}
                >
                  Confirmar
                </Button>
              </DivButtons>
            </ModalDefault>
          )}

          {showDateLastVisitModal && (
            <ModalDefault
              color="grafit"
              title="Data da última visita"
              icon={null}
            >
              <DesktopDatePicker
                label="Informe a data"
                inputFormat="dd/MM/yyyy"
                sx={StyleDefaultInputDate}
                value={newDateLastVisit}
                onChange={e => setnewDateLastVisit(e)}
                renderInput={params => (
                  <TextField color="primary" variant="filled" {...params} />
                )}
              />

              <DivButtons>
                <Button
                  bg="secondary"
                  onClick={() =>
                    setshowDateLastVisitModal(!showDateLastVisitModal)
                  }
                >
                  Cancelar
                </Button>
                <Button
                  bg="success"
                  onClick={() => {
                    handleDateLastVisitModal();
                    setshowDateLastVisitModal(!showDateLastVisitModal);
                  }}
                >
                  Confirmar
                </Button>
              </DivButtons>
            </ModalDefault>
          )}

          {showUpdateModal && (
            <ClientManagement
              showUpdateModal={showUpdateModal}
              selectedUser={selectedUser}
              setCustomers={setCustomers}
              setShowUpdateModal={setShowUpdateModal}
              setActiveModal={setActiveModal}
              setSelectedUser={setSelectedUser}
              customers={customers}
              setShowDisableModal={setShowDisableModal}
            />
          )}
          <ContainerPaginate>
            <ReactPaginate
              previousLabel={<AiOutlineCaretLeft size={22} />}
              pageCount={pageCount}
              forcePage={page - 1}
              previousLinkClassName="previous_page"
              nextLabel={<AiOutlineCaretRight size={22} />}
              nextLinkClassName="next_page"
              activeClassName="active"
              onPageChange={handlePageClick}
            />
          </ContainerPaginate>
        </Container>
      )}
      <ReactTooltip />
    </>
  );
}
