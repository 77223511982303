export default function userName(user) {
  if (!user) return '';
  const partnerName = user?.contract?.partner_name?.split(' ');
  if (partnerName && partnerName[0].length) {
    const userName = user.name.split(' ');
    return `${userName[0]} e ${partnerName[0]}`;
  }

  return `${user?.name}`;
}
