import styled from '@emotion/styled';

const StyledA = styled.a`
 &.a-atom{
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.white.default};
  text-decoration: none;

  &:hover {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.white.hover};
  }}
`;
export { StyledA };
