import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Sidebar from './Sidebar';

import { Container, Wrapper } from './styles';

export default function DashboardLayout({ children }) {
  const [height, setHeight] = useState('100%')
  const ref = useRef(null)

  const getListSize = () => {
    setHeight(ref.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", getListSize);
    setHeight(ref.current.clientHeight);

    return () => {
      window.removeEventListener("resize", getListSize);
    }
  }, []);

  return (
    <Container height={height}>
      <Header />
      <Sidebar ref={ref} />
      <Wrapper>{children}</Wrapper>
    </Container>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
