import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { DivGroup, InputStyled } from './styles';

export default function Input({ ...rest }) {
  const highlight = rest.className === 'error' || rest.className === 'success';

  return highlight ? (
    <DivGroup {...rest}>
      <InputStyled />
      {rest.className === 'success' && (
        <Icon type="check-circle" style={{ color: '#00ffbf' }} />
      )}

      {rest.className === 'error' && (
        <Icon type="close-circle" style={{ color: '#ff3300' }} />
      )}
    </DivGroup>
  ) : (
    <InputStyled {...rest} />
  );
}

Input.propTypes = {
  rest: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

Input.defaultProps = {
  rest: '',
};
