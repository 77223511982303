let time = null;

/**
 *
 * @param {function} handler função que será executada pelo debounce
 * @param {number} wait tempo de espera para a execução da função
 */
export default function debounce(handler, wait) {
  clearTimeout(time);

  time = setTimeout(() => handler(), wait);
}
