import styled from 'styled-components';
import Background from '../../../assets/bg-login-admin.png'
import Watermark from '../../../assets/watermark-login.png'

import { colors } from 'styles/global';

export const WrapperSelectPerfil = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 374px;
  margin-top: 24px;
`

export const WrapperGray = styled.div`
  height: 100%;
  width: 100%;
  background-color: #1E1E1E;
  padding: 16px;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
`;

export const ContainerImage = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1E1E1E url(${Background}) ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const HeaderGroup = styled.div`
  position: relative;
  display: flex;
  div.watermark {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 52px;
    height: 49px;
    background: url(${Watermark});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  h1 {
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    opacity: 0.5;
    font-weight: 500;
    margin-top: 100px;
    padding: 0 60px;
  }
`

export const BottomGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  h2 {
    text-align: center;
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    opacity: 0.5;
    font-weight: 500;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
`

export const Logo = styled.img`
  width: 186px;
  height: auto;
  margin-bottom: 16px;
  margin-top: 0;
`;

export const Container = styled.div`
  width: 558px;
  min-width: 558px;
  min-height: 580px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 374px;

  .control-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 6px;

    .control-label, svg {
      transition: all 0.5s ease;
    }

    &:focus-within {
        .control-label {
            color: ${colors.blue.default};
        }

        svg {
          color: ${colors.blue.default};
        }

        .bordered {
          border: 1px solid ${colors.blue.default} !important;
        }
    }
  }

  h1 {
    display: flex;
    justify-content: center;
    font-size: 60px;
    color: #1f1f66;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.97px;
  }

  label {
    font-family: 'Inter', sans-serif;
    margin-bottom: 6px;
    color: ${colors.gray.custom};
    font-weight: 400;
    line-height: 1.38;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    &.right {
      justify-content: flex-end;
      margin-bottom: 32px !important;
    }

    div {
      display: flex;
      align-items: center;

      label {
        margin-left: 5px;
        font-size: 13px;
      }
    }
    a {
      font-family: 'Inter', sans-serif;
      color: ${colors.blue.default};
      font-size: 16px;
      margin-left: 10px;
      font-stretch: 400;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.15px;
    }
  }
`;

export const InputGroup = styled.div`
  position: relative;
  height: 48px;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  margin-bottom: 16px;
  background: #ffffff;
  border: 1px solid ${colors.gray.default};
  border-radius: 4px;
  transition: all 0.5s ease;
  width: 100%;
  gap: 16px;

  svg {
    color: ${colors.gray.default};
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-lock {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 100%;
      margin: 0;
  }

  .button-visibility {
    position: absolute;
    right: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 48px;
    height: 100%;
    margin: 0;

    svg {
      width: 24px;
      height: auto;
      margin: 0;
      color: ${colors.gray.hover};
      transition: all .3s ease;
      cursor: pointer;

      &:hover {
        color: ${colors.gray.default};
      }
    }
  }

  input {
    font-family: 'Inter', sans-serif;
    height: 39px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    width: calc(100% - 114px);

    &#inputPassword {
      width: calc(100% - 114px);
    }

    &::placeholder {
      font-family: 'Inter', sans-serif;
      color: ${colors.grafit.default};
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: 0.26px;
    }
  }
`;
