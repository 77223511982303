import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StepWizard from 'react-step-wizard';

import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';

import { format } from 'date-fns';

import { Form } from './styles';

import Modal from 'components/Modal/Default';
import Dropdown from 'components/Button/Dropdown';

import Context from './Context';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import api from 'services/api';

export default function ModalCreate({
  setCustomers,
  pfg_class_id,
  setShowModal,
}) {
  // Dados pessoais
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [rg, setRg] = useState('');
  const [orgao, setOrgao] = useState('');
  const [cpf, setCpf] = useState('');

  // Dados do cônjuge
  const [partner_name, setPartnerName] = useState('');
  const [partner_email, setPartnerEmail] = useState('');
  const [partner_phone, setPartnerPhone] = useState('');
  const [partner_birthdate, setPartnerBirthdate] = useState('');
  const [zip, setZip] = useState('');

  // Dados do contrato
  const [planner_id, setPlannerId] = useState('');
  const [recommendation_id, setRecommendationId] = useState('');
  const [signature_date, setSignatureDate] = useState('');
  const [contract_end_date, setContractEndDate] = useState('');
  const [first_meeting_date, setFirstMeetingDate] = useState('');
  const [billing_date, setBillingDate] = useState(0);
  const [number_of_steps, setNumberOfSteps] = useState(0);
  const [first_step_deadline, setFirstStepDeadline] = useState(0);
  const [second_step_deadline, setSecondStepDeadline] = useState(0);
  const [third_step_deadline, setThirdStepDeadline] = useState(0);
  const [first_fee, setFirstFee] = useState(0);
  const [second_fee, setSecondFee] = useState(0);
  const [third_fee, setThirdFee] = useState(0);

  const token = useSelector(state => state.auth.token);

  const loggedUser = useSelector(state => state.session.profile);

  async function handleSubmit() {
    try {
      const { data } = await api.post('/users', {
        name,
        email,
        password: cpf,
        type: 'client',
      });

      const userId = data.id;

      await api.post(`/users/${data.id}/contracts`, {
        pfg_class_id,
        phone,
        birthdate,
        partner_name,
        partner_email,
        partner_phone,
        partner_birthdate,
        address,
        neighborhood,
        city,
        state,
        zip,
        rg,
        orgao,
        cpf,
        signature_date,
        contract_end_date,
        first_meeting_date,
        billing_date,
        number_of_steps,
        first_step_deadline,
        second_step_deadline,
        third_step_deadline,
        first_fee,
        second_fee,
        third_fee,
      });

      // cria a role para o cliente pfg poder editar seus dados no frontent do client
      await api.post(`/users/${userId}/roles`, {
        role_id: 11,
      });

      setCustomers(customers => [
        ...customers,
        [
          data.name,
          `#${data.id}`,
          data.email,
          format(new Date(data.created_at), 'dd/MM/yyyy'),
          <Dropdown>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    data.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Yuno On
            </button>
          </Dropdown>,
        ],
      ]);

      setName('');
      setEmail('');
      setPhone('');
      setBirthdate('');
      setAddress('');
      setNeighborhood('');
      setCity('');
      setState('');
      setRg('');
      setOrgao('');
      setCpf('');
      setPartnerName('');
      setPartnerEmail('');
      setPartnerPhone('');
      setPartnerBirthdate('');
      setZip('');
      setPlannerId('');
      setRecommendationId('');
      setSignatureDate('');
      setContractEndDate('');
      setFirstMeetingDate('');
      setBillingDate('');
      setNumberOfSteps('');
      setFirstStepDeadline('');
      setSecondStepDeadline('');
      setThirdStepDeadline('');
      setFirstFee('');
      setSecondFee('');
      setThirdFee('');

      toast.success('Criado com sucesso');
      setShowModal(false);
    } catch (err) {
      toast.error('Falha ao criar aluno, verifique os dados e tente novamente');
    }
  }

  return (
    <Modal color="info" title="Cadastrar Novo Aluno" icon={null}>
      <Form onSubmit={e => e.preventDefault()}>
        <Context.Provider
          value={{
            handleSubmit,
            setShowModal,

            planner_id,
            setPlannerId,
            recommendation_id,
            setRecommendationId,

            name,
            setName,
            email,
            setEmail,
            phone,
            setPhone,
            birthdate,
            setBirthdate,
            address,
            setAddress,
            neighborhood,
            setNeighborhood,
            city,
            setCity,
            state,
            setState,
            rg,
            setRg,
            orgao,
            setOrgao,
            cpf,
            setCpf,

            partner_name,
            setPartnerName,
            partner_email,
            setPartnerEmail,
            partner_phone,
            setPartnerPhone,
            partner_birthdate,
            setPartnerBirthdate,
            zip,
            setZip,

            signature_date,
            setSignatureDate,
            contract_end_date,
            setContractEndDate,
            first_meeting_date,
            setFirstMeetingDate,
            billing_date,
            setBillingDate,
            number_of_steps,
            setNumberOfSteps,
            first_step_deadline,
            setFirstStepDeadline,
            second_step_deadline,
            setSecondStepDeadline,
            third_step_deadline,
            setThirdStepDeadline,
            first_fee,
            setFirstFee,
            second_fee,
            setSecondFee,
            third_fee,
            setThirdFee,
          }}
        >
          <StepWizard>
            <FirstStep />
            <SecondStep />
            <ThirdStep />
          </StepWizard>
        </Context.Provider>
      </Form>
    </Modal>
  );
}

ModalCreate.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  pfg_class_id: PropTypes.string.isRequired,
  setCustomers: PropTypes.func.isRequired,
};
