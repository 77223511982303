import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import session from './session/sagas';
import user from './user/sagas';
import contract from './contract/sagas';
import contract_customer from './contract_customer/sagas';

export default function* rootSaga() {
  return yield all([auth, session, user, contract, contract_customer]);
}
