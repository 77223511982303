import styled from 'styled-components';

import { colors } from 'styles/global';

export const DivGroup = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 5px;
  padding-right: 10px;

  input {
    border: 0 !important;
  }
  /* &.success {
    border: 2px solid #00ffbf !important;
  }

  &.error {
    border: 2px solid #ff3300 !important;
  } */
`;

export const InputStyled = styled.input`
  padding: 3px;
  flex: 1;
  border:none;

  /* &:focus {
    border: 2px solid ${colors.primary.default};
  } */
`;
