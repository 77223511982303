import styled from '@emotion/styled';

const H1 = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.white.default};
`;
export { H1 };
