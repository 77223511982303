import * as React from 'react';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomInputMask from 'components/masks/materialUI/CustomInputMask';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

const ClienteData = ({
    planner_id,
    setPlannerId,
    name,
    setName,
    email,
    setEmail,
    birthdate,
    setBirthdate,
    phone,
    setPhone,
    cpf,
    setCpf,
    zip,
    setZip,
    address,
    setAddress,
    neighborhood,
    setNeighborhood,
    city,
    setCity,
    state,
    setState,
    partner_name,
    setPartnerName,
    partner_email,
    setPartnerEmail,
    partner_birthdate,
    setPartnerBirthdate,
    partner_phone,
    setPartnerPhone,
    allPlanners,
    client_count,
    setClientCount,
}) => {
    return (
        <DialogContent>
            <>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" >Planejador</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={planner_id}
                            label="Planejador"
                            variant="filled"
                            onChange={(e) => {
                                setPlannerId(e.target.value)
                            }}
                        >
                            {
                                allPlanners.map(planner => (
                                    <MenuItem key={planner.id} value={planner.id}>{planner.label}</MenuItem>
                                ))
                            }

                        </Select>
                    </FormControl>
                </Box>

                <Typography sx={{ fontWeight: 'bold' }} color='primary' variant="subtitle2" gutterBottom>
                    Métricas Administrativas
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Entra na contagem de clientes ativos?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={client_count}
                            onChange={(e) => setClientCount(e.target.value)}
                            sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Sim" />
                            <FormControlLabel value={false} control={<Radio />} label="Não" />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Typography sx={{ fontWeight: 'bold' }} color='primary' variant="subtitle2" gutterBottom>
                    Dados do contratante
                </Typography>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <TextField id="standard-basic" label="Nome" variant="filled" value={name} onChange={(e) => { setName(e.target.value) }} />
                    <TextField id="standard-basic" label="E-mail" variant="filled" value={email} onChange={(e) => { setEmail(e.target.value) }} />

                    <DesktopDatePicker
                        label="Data de nascimento"
                        inputFormat="dd/MM/yyyy"
                        sx={{
                            svg: { color: '#fff' },
                            input: { color: '#fff' },
                            label: { color: '#fff' }
                        }}
                        value={birthdate}
                        onChange={(e) => setBirthdate(e)}
                        renderInput={(params) => <TextField color='primary' variant="filled" {...params} />}
                    />

                    <CustomInputMask
                        mask="(99) 9 9999-9999"
                        label="Número de telefone"
                        value={phone}
                        onChange={setPhone}
                        maskChar=" "
                    />
                    <CustomInputMask
                        mask="999.999.999-99"
                        value={cpf}
                        label="CPF"
                        onChange={setCpf}
                    />

                </Box>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <CustomInputMask
                        mask="99999-999"
                        value={zip}
                        label="CEP"
                        onChange={setZip}
                        disabled={false}
                        maskChar=" "
                    />


                    <TextField id="standard-basic" label="Endereço" variant="filled" value={address}
                        onChange={(e) => setAddress(e.target.value)} />
                    <TextField id="standard-basic" label="Bairro" variant="filled" value={neighborhood}
                        onChange={(e) => setNeighborhood(e.target.value)} />
                    <TextField id="standard-basic" label="Cidade" variant="filled" value={city}
                        onChange={(e) => setCity(e.target.value)} />
                    <TextField id="standard-basic" label="UF" variant="filled" value={state}
                        onChange={(e) => setState(e.target.value)} />

                    <Typography color="primary" variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>Dados do cônjuge</Typography>
                    <TextField id="standard-basic" label="Nome" variant="filled" value={partner_name} onChange={(e) => setPartnerName(e.target.value)} />
                    <TextField id="standard-basic" label="E-mail" variant="filled" value={partner_email} onChange={(e) => setPartnerEmail(e.target.value)} />
                    <DesktopDatePicker
                        label="Data de nascimento"
                        inputFormat="dd/MM/yyyy"
                        sx={{
                            svg: { color: '#fff' },
                            input: { color: '#fff' },
                            label: { color: '#fff' }
                        }}
                        value={partner_birthdate}
                        onChange={(e) => setPartnerBirthdate(e)}
                        renderInput={(params) => <TextField color='primary' variant="filled" {...params} />}
                    />
                    <CustomInputMask
                        mask="(99) 9 9999-9999"
                        value={partner_phone}
                        onChange={setPartnerPhone}
                        label="Número de telefone"
                    />

                </Box>
            </>

        </DialogContent>
    )
}
export default ClienteData;