import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import 'antd/dist/antd.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'config/ReactotronConfig';

import { Router } from 'react-router-dom';

import { LastLocationProvider } from 'react-router-last-location';

import Routes from 'routes';

import history from 'services/history';

import { store, persistor } from 'store';

import GlobalStyle from 'styles/global';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { defaultTheme } from 'styles/theme';
export default function App() {
  const darkTheme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
      },
    },

    palette: {
      neutral: {
        100: '#F3F4F6',
        200: '#E5E7EB',
        300: '#D1D5DB',
        400: '#9CA3AF',
        500: '#6B7280',
        600: '#4B5563',
        700: '#374151',
        800: '#1F2937',
        900: '#111827'
      },
      action: {
        active: '#6B7280',
        focus: 'rgba(55, 65, 81, 0.12)',
        hover: 'rgba(55, 65, 81, 0.04)',
        selected: 'rgba(55, 65, 81, 0.08)',
        disabledBackground: 'rgba(55, 65, 81, 0.12)',
        disabled: 'rgba(55, 65, 81, 0.26)'
      },
      background: {
        default: '#2a263d',
        paper: '#1d1a28'
      },
      divider: '#E6E8F0',
      primary: {
        main: '#b7a3fd',
        light: '#828DF8',
        dark: '#3832A0',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#10B981',
        light: '#3FC79A',
        dark: '#0B815A',
        contrastText: '#FFFFFF'
      },
      success: {
        main: '#14B8A6',
        light: '#43C6B7',
        dark: '#0E8074',
        contrastText: '#FFFFFF'
      },
      info: {
        main: '#2196F3',
        light: '#64B6F7',
        dark: '#0B79D0',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FFB020',
        light: '#FFBF4C',
        dark: '#B27B16',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#D14343',
        light: '#DA6868',
        dark: '#922E2E',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#cdcdcd',
        secondary: '#d1d1d1',
        custom: '#28bcb1',
        disabled: 'rgba(55, 65, 81, 0.48)'
      },
    },
    shape: {
      borderRadius: 8
    },


  });
  return (
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        <EmotionThemeProvider theme={defaultTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <PersistGate persistor={persistor}>
              <Router history={history}>
                <LastLocationProvider>
                  <GlobalStyle />
                  <Routes />
                </LastLocationProvider>
              </Router>
            </PersistGate>
          </LocalizationProvider>
        </EmotionThemeProvider>
      </ThemeProvider>
    </Provider>
  );
}
