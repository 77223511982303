import styled from 'styled-components';

export const Form = styled.form`
  overflow: auto;
  max-height: 600px;
  min-width: 400px;

  & > * {
    margin-bottom: 30px;
  }

  div.divGroup {
    display: flex;
    margin-top: 20px;

    & > button + button {
      margin-left: 15px;
    }
  }

  div.split-group > * {
    margin-bottom: 15px;
  }

  button.split {
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #00ffbf;

    background: none;
    color: #00ffbf;

    font-weight: bold;

    transition: 500ms ease;
  }

  button.split:hover {
    background: #00ffbf;
    color: white;
  }
`;
