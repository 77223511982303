import styled, { keyframes } from 'styled-components';

import { colors } from 'styles/global';

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }

`
export const Container = styled.div`
  width: 100%;
  height: 80%;
`;

export const TableContainer = styled.div`
  opacity: 0;
  animation: ${fadeIn} .8s ease forwards;
`

export const DivButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > button + button {
    margin-left: 8%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 1%;
  min-height: 48px;

  aside {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 0.5rem;

    a {
      margin-top: 4px;
    }

    svg {
      font-size: 26px;
      color: ${colors.white.default};
      transition: 200ms;
      &:hover {
        transform: scale(1.2);
        color: ${colors.info.default};
      }
    }

    p {
      margin-bottom: auto;
      font-family: 'Nunito', sans-serif;
      font-size: 32px;
      line-height: 1.35;
      letter-spacing: 0.26px;
      color: ${colors.white.default};
      margin-left: 2%;
      white-space: nowrap;
    }
  }
`;

export const HeaderTitle = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  min-height: 52px;

  .percent {
    color: ${colors.grafit.hover};
    font-weight: bold;
  }

  .danger {
    color: ${colors.danger.default};
    font-weight: bold;
  }
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 1%;

  div {
    &.right-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div {
        display: flex;
        justify-content: space-evenly;
        &.right-bar-buttons {
          display: flex;
          justify-content: flex-start;
          button {
            width: auto;
            & + button {
              margin-left: 5%;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

export const Content = styled.div`
  header {
    padding: 2% 0 0.5% 2%;
    background: #4545e6;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    h2 {
      color: #eee;
      font-family: 'Nunito', sans-serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: 0.26px;
    }

    button {
      background: transparent;
      padding: 0 3% 2% 0;
      svg {
        color: #eee;
        font-weight: bold;
      }
    }
  }

  form {
    padding: 5%;
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    input {
      outline: 0;
      border: 2px solid ${colors.grafit.default};
      border-radius: 5px;
      height: 30px;

      &:focus {
        border: 2px solid ${colors.info.default};
      }

      &.inputWidth {
        width: 130%;
      }
      &.inputEndereco {
        width: 125%;
      }

      &.maskedInput {
        padding-left: 2%;
      }
    }
    label {
      margin-top: 1.5%;
      margin-bottom: 1%;
      color: #9c9c9c;
      font-family: 'Nunito', sans-serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: 0.26px;
    }
  }
`;

export const Form = styled.form`
  width: 400px;
`;

export const DivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5%;
  div {
    display: flex;
    flex-direction: column;
  }

  &.buttonsForm {
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-left: 2%;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  min-width: 300px;
  background: #023950;
  height: 38px;
  font-size: 15px;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 3px;
`;

export const ContainerPaginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 10%;

  & > ul {
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
    width: 30%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      width: 30px;
      height: 30px;

      color: ${colors.grafit.hover};
      font-size: 16px;
      transition: 200ms ease;
      border-radius: 5px;
    }

    & > li + li {
      margin-left: 7%;
    }

    a.previous_page {
      padding: 5px;
      color: ${colors.grafit.hover};
      border: 1px solid ${colors.grafit.default};
    }
    a.next_page {
      padding: 5px;
      color: ${colors.grafit.hover};
      border: 1px solid ${colors.grafit.default};
    }

    li.active {
      a {
        color: ${colors.white.default};
        background: ${colors.info.default};
        border-radius: 50%;
      }
    }

    a:hover {
      color: ${colors.info.default};
      transform: scale(1.1);
    }
  }
`;
