import React, { useCallback, forwardRef } from 'react';

import { Link } from 'react-router-dom';
import Can from 'components/Can';
import { useSelector } from 'react-redux';

import {
  AiOutlineContacts,
  AiOutlineIdcard,
  AiOutlineBook,
  AiOutlineUserAdd,
  AiOutlineHome,
  AiOutlineFileText,
  AiOutlineWallet,
} from 'react-icons/ai';

import ReactTooltip from 'react-tooltip';

import { Container, SideNav, Avatar, HomeIcon } from './styles';

import placeholder from 'assets/sidebarIcons/placeholder.svg';

// eslint-disable-next-line no-empty-pattern
const Sidebar = forwardRef(({ }, ref) => {
  const { email } = useSelector(state => state.auth.profile);

  function checkActive(pathname) {
    let active = null;

    const [, choosenPath] = window.location.pathname.split('/');

    if (choosenPath === pathname) {
      active = 'active';
    }

    return active;
  }

  const isAccountPagarme = useCallback(() => {
    return email === 'pagarme@pagarme.com';
  }, [email]);

  return (
    <Container ref={ref}>
      <Avatar src={placeholder} alt="Avatar" />
      <SideNav>

        <>
          <Can canRoles={['adm']}>
            <li className={checkActive('planners')}>
              <Link to="/planners" data-tip="Planejadores">
                <AiOutlineContacts />
              </Link>
            </li>
          </Can>

          <Can canRoles={['adm', 'assistant-planner']}>
            <li className={checkActive('customers')}>
              <Link to="/customers" data-tip="Clientes">
                <AiOutlineIdcard />
              </Link>
            </li>
          </Can>

          <Can canRoles={['adm']}>
            <li className={checkActive('reports')}>
              <Link to="/reports" data-tip="Relatórios">
                <AiOutlineFileText />
              </Link>
            </li>
          </Can>

          <Can canRoles={['adm']}>
            <li className={checkActive('recommended-wallet')}>
              <Link to="/recommended-wallet" data-tip="Carteira Recomendada">
                <AiOutlineWallet />
              </Link>
            </li>
          </Can>

          <Can canRoles={['adm']}>
            <li className={checkActive('pfg')}>
              <Link to="/pfg" data-tip="PFG's">
                <AiOutlineBook />
              </Link>
            </li>
          </Can>
          <Can canRoles={['adm']}>
            <li className={checkActive('profiles')}>
              <Link to="/profiles" data-tip="Perfis">
                <AiOutlineUserAdd />
              </Link>
            </li>
          </Can>

        </>

      </SideNav>
      <Can canRoles={['adm']}>
        <HomeIcon className={checkActive('home')}>
          <Link to="/home">
            <AiOutlineHome />
          </Link>
        </HomeIcon>
      </Can>
      <ReactTooltip />
    </Container>
  );
})

export default Sidebar;
