import React, { useState, useEffect, useRef } from 'react';

import { useLastLocation } from 'react-router-last-location';

import { toast } from 'react-toastify';

import TextField from '@mui/material/TextField';

import ButtonMui from '@mui/material/Button';

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import Loading from 'components/Loading';

import { useSelector } from 'react-redux';

import { format } from 'date-fns';

import { Icon } from 'antd';

import Link from '@mui/material/Link';

import { Link as LinkDom } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';

import Select from 'components/Input/Dropdown';

import Switch from 'components/Input/Switch'

import api, { api_nort } from 'services/api';

import {
  Container,
  Header,
  Toolbar,
  TableContainer,
  ContainerPaginate
} from '../styles';

import debounce from 'utils/debounce';
import Table from 'components/Table/Default';
import Button from 'components/Button';
import Dropdown from 'components/Button/Dropdown';
import ReactPaginate from 'react-paginate';
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';

export default function ViewByReport() {

  const [clientList, setClientList] = useState([{ label: "Carregando clientes...", value: null }]);
  const [filter, setFilter] = useState(clientList[0]);

  const PrazoList = [
    { label: "Todos", value: 'todos' },
    { label: "No prazo", value: 'verde' },
    { label: "Vencimento próximo", value: 'amarelo' },
    { label: "Vencido a menos de cinco dias", value: 'vermelho' },
    { label: "Vencido a mais de 5 dias", value: 'preto' }
  ]
  const [filterPrazo, setFilterPrazo] = useState(PrazoList[0]);

  const AplicaList = [
    { label: "Todos", value: '0' },
    { label: "Aplica", value: '1' },
    { label: "Não aplica", value: '2' }
  ]
  const [filterAplica, setfilterAplica] = useState(AplicaList[1]);

  const [plannerList, setPlannerList] = useState([{ label: "Carregando panejadores...", value: null }]);
  const [filterPlannerList, setfilterPlannerList] = useState(plannerList[0])

  const ref = useRef();

  const [activeClient, setActiveClient] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  // Planners da api
  const [tableList, setTableList] = useState([]);

  const [colorList, setColorList] = useState([]);

  const token = useSelector(state => state.auth.token);

  //  const [selectedUser, setSelectedUser] = useState([]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: '0 !important',
      // This line disable the blue border
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
      background: '#023950',
      // match with the menu
      borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
      // boxShadow: 'none',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color: '#fff',
      // specify a fallback color here for those values not accounted for in the styleMap
    }),
  };

  const getColor = (tipo, dataEntrega, value) => {
    if (tipo === "ORÇAMENTÁRIO" || tipo === "PATRIMONIAL") {
      var valor = Number(value);

      if (dataEntrega !== null) return "blue"
      if (value === null) return null
      if (valor <= 0) return "red"
      if (valor >= 30) return "green"
      if (valor < 30) return "yellow"
    }
  }

  //Caso o relatório possua Dt. Entrega e NÃO possua Previsão, a cor da linha deverá ficar VERDE
  const getColorBlue = (tipo, dataEntrega, aplica) => {
    if (tipo === "ORÇAMENTÁRIO" || tipo === "PATRIMONIAL") return null;
    if (dataEntrega !== null) return "blue"
    if (aplica) return "yellow"
    return null
  }

  // Recebe a rota anterior acessada
  const location = useLastLocation();

  //Cabeçalho do grid
  const headTable = [
    'Cliente',
    'Contrato',
    'Aplica?',
    'Previsão',
    'Dt. Entrega',
    '',
  ];

  const startLoading = () => {

    setIsLoading(true);

    // Apaga os dados da tabela
    setActiveClient(null);

    setTableList([]);

    setColorList([]);

  }

  //Faz UPDATE na data prevista
  const updateDataPrevisao = (e, id) => {

    const dataPrevista = format(e, 'yyyy-MM-dd');

    startLoading();

    async function listCLients() {
      const response = await api_nort.patch(`Usuario/${id}/dataprevista`, { dataPrevista: dataPrevista });

      fetchClientsData();

      if (response.data.sucesso) {
        toast.success('Dado atualizado!');
      } else {
        toast.error('Erro ao tentar atualizar o dado');
      }
    }

    listCLients();
  }

  const uploadFile = async (e, id, name) => {
    ref.current.close();

    const file = e.target.files[0];

    if (!file) {
      return toast.error('Selecione um arquivo');
    }

    startLoading();

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(`report/${id}`, formData);

      if (response.data.id) {
        toast.success('Arquivo enviado com sucesso!');
        fetchClientsData();
      } else {
        throw new Error('Falha ao enviar o arquivo. Tente novamente mais tarde.');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const mountDatePicker = (item) => {
    const { tipoRelatorio, id, previsao } = item;

    if (tipoRelatorio !== "ORÇAMENTÁRIO" && tipoRelatorio !== "PATRIMONIAL") return '-';
    return <DesktopDatePicker
      inputFormat="dd/MM/yyyy"
      value={previsao}
      onChange={(e) => updateDataPrevisao(e, id)}
      renderInput={(params) => (
        <TextField color='primary'
          variant="filled"
          margin='none'
          sx={{
            svg: { color: '#fff' },
            input: { color: '#fff', padding: 0 },
            label: { color: '#fff' }
          }}
          {...params} />
      )}
    />
  }

  //Após retorno dos clientes por relatório alimenta a grid
  useEffect(() => {
    if (activeClient?.data) {
      setPageCount(activeClient.total / 5)
      const resultadoLista = activeClient.data.map(relatorio => {
        return {
          id: relatorio.id_relatorio,
          contrato: relatorio.contrato === null ? "-" : relatorio.contrato,
          nomeCliente: relatorio.name,
          aplica: relatorio.aplica,
          previsao: relatorio.data_previsao,
          dataEntrega: (relatorio.data_entrega === null ? "-" : format(new Date(relatorio.data_entrega), 'dd/MM/yyyy')),
          color: getColor(relatorio.tipo, relatorio.data_entrega, relatorio.data_diferenca),
          linkUrlReport: relatorio.relatorio_url,
          tipoRelatorio: relatorio.tipo,
          colorBlue: getColorBlue(relatorio.tipo, relatorio.data_entrega, relatorio.aplica)
        }
      });

      const resultadoCores = resultadoLista.map(relatorio => {
        if (relatorio.colorBlue !== null) return relatorio.colorBlue
        return relatorio.color
      });
      setColorList(resultadoCores)

      setTableList(
        resultadoLista.map((item) => {
          return [
            `${item.nomeCliente}`,
            `${item.contrato}`,
            <Switch
              key={item.id}
              isOn={item.aplica}
              onColor="#4545e6"
              handleToggle={() => updateToggle(item.id, item.aplica)}
            />,
            mountDatePicker(item)
            ,
            item.linkUrlReport !== null ? <Link href={item.linkUrlReport} underline="hover" target="_blank">
              {String(item.dataEntrega)}
            </Link>
              :
              `${String(item.dataEntrega)}`,
            <div className="div-icons">
              <div className="icons">
                <Dropdown ref={ref} canClose={false} >
                  <ButtonMui variant="contained" component="label">
                    Upload
                    <input hidden onChange={(e) => {
                      uploadFile(e, item.id, "Relatorio" + filter.label + '-' + item.nomeCliente);
                    }} accept=".pdf" type="file" />
                  </ButtonMui>
                </Dropdown>
              </div>
            </div>,
          ]
        }));
    }
    // eslint-disable-next-line
  }, [activeClient])

  // Load initial report
  //Executa a rota para retornar os tipos de relatórios
  useEffect(() => {
    async function listCLients() {
      const response = await api_nort.get('/Usuario/relatorios');

      var resultsConverted = response.data.map((relatorio) => {
        return {
          label: relatorio.tipo,
          value: relatorio.id
        }
      })

      resultsConverted.unshift({
        label: "Selecione o relatório",
        value: 0
      })

      setFilter(resultsConverted[0])
      setClientList(resultsConverted);
    }
    listCLients();
  }, []);

  // Load initial report
  //Executa a rota para retornar os planejadores ativos
  useEffect(() => {
    async function listPlanners() {
      const response = await api_nort.get('/Usuario/get/planner');

      var resultsConverted = response.data.map((planner) => {
        return {
          label: planner.nome,
          value: planner.id
        }
      })

      resultsConverted.unshift({
        label: "Todos",
        value: 0
      })

      setfilterPlannerList(resultsConverted[0])
      setPlannerList(resultsConverted);
    }
    listPlanners();
  }, []);

  //Executa a rota para buscar os clientes conforme filtro de parâmetros
  //  Qual o tipo de relatório?
  //  Paginação
  //  Qual o filtro de prazo?
  const fetchClientsData = async () => {
    if (filter.value === 0 || filter.value === null) return;
    startLoading();
    const response = await api_nort.get(`/Usuario/relatorios/${filter.value}/${page}/${filterPrazo.value}/${filterAplica.value}/${filterPlannerList.value}`);
    setActiveClient(response.data);
    setIsLoading(false);
  }

  //Executado ao alterar o controle Select para escolher o tipo de relatório
  useEffect(() => {
    debounce(fetchClientsData, 600);
    // eslint-disable-next-line
  }, [filter])

  //Executado ao alterar o controle Select para filtrar o prazo
  useEffect(() => {
    debounce(fetchClientsData, 600);
    // eslint-disable-next-line
  }, [filterPrazo])

  //Executado ao alterar o controle Select para filtrar o APLICA
  useEffect(() => {
    debounce(fetchClientsData, 600);
    // eslint-disable-next-line
  }, [filterAplica])

  //Executado ao alterar o controle Select para filtrar o planner
  useEffect(() => {
    debounce(fetchClientsData, 600);
    // eslint-disable-next-line
  }, [filterPlannerList])

  //Executa a rota da opção APLICAR
  const updateToggle = (id, aplica) => {
    startLoading();

    async function listCLients() {
      const response = await api_nort.patch(`Usuario/${id}/aplica`, { aplica: !aplica });

      fetchClientsData();

      if (response.data.sucesso) {
        toast.success('Dado atualizado!');
      } else {
        toast.error('Erro ao tentar atualizar o dado');
      }
    }
    listCLients();
  }

  //Chama a função fetchClientsData para executar a rota que alimenta a grid de clientes
  useEffect(() => {
    fetchClientsData();
    // eslint-disable-next-line
  }, [page])

  //Retorno dos dados para o DOM
  return (
    <>
      <Container>
        <Header hideExtrair={true}>
          <aside>
            <Link to={location ? location.pathname : '#'} data-tip="Voltar">
              <Icon type="import" />
            </Link>
            <p>Relatórios</p>
          </aside>
        </Header>
        <Toolbar>
          <Select
            label={'Tipo'}
            value={filter}
            options={clientList}
            onChange={selected => setFilter(selected)}
            styles={customStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                neutral50: '#fff',
                primary: '#fff',
              },
            })}
          />
          <Select
            label={'Prazo'}
            value={filterPrazo}
            options={PrazoList}
            onChange={selected => setFilterPrazo(selected)}
            styles={customStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                neutral50: '#fff',
                primary: '#fff',
              },
            })}
          />
          <Select
            label={'Aplica'}
            value={filterAplica}
            options={AplicaList}
            onChange={selected => setfilterAplica(selected)}
            styles={customStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                neutral50: '#fff',
                primary: '#fff',
              },
            })}
          />
          <Select
            label={'Planejador'}
            value={filterPlannerList}
            options={plannerList}
            onChange={selected => setfilterPlannerList(selected)}
            styles={customStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                neutral50: '#fff',
                primary: '#fff',
              },
            })}
          />
          <div className="right-bar">
            <div className="right-bar-buttons">
              <LinkDom to="/reports">
                <Button size={35} bg="info">
                  Visualizar por cliente
                </Button>
              </LinkDom>
            </div>
          </div>
        </Toolbar>
        {isLoading &&
          <Loading />
        }
        {tableList.length > 0 &&
          <TableContainer>
            <Table head={headTable} body={tableList} colorList={colorList} />
          </TableContainer>
        }
        <ContainerPaginate>
          <ReactPaginate
            previousLabel={<AiOutlineCaretLeft size={22} />}
            pageCount={pageCount}
            previousLinkClassName="previous_page"
            nextLabel={<AiOutlineCaretRight size={22} />}
            nextLinkClassName="next_page"
            activeClassName="active"
            onPageChange={data => setPage(data.selected * 10)}
          />
        </ContainerPaginate>
      </Container>
      <ReactTooltip />
    </>
  );
}
