import { takeLatest, call, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from 'services/api';

export function* storeContractCustomer({ payload }) {
  try {
    const {
      customer_id,
      recommendation_id,
      phone,
      birthdate,
      partner_name,
      partner_email,
      partner_phone,
      partner_birthdate,
      address,
      neighborhood,
      city,
      state,
      zip,
      rg,
      orgao,
      cpf,

      signature_date,
      contract_end_date,
      first_meeting_date,
      billing_date,
      number_of_steps,
      first_step_deadline,
      second_step_deadline,
      third_step_deadline,
      first_fee,
      second_fee,
      third_fee,
    } = payload;

    console.tron.error(customer_id);

    yield call(api.post, `/users/${customer_id}/contracts`, {
      recommendation_id,
      phone,
      birthdate,
      partner_name,
      partner_email,
      partner_phone,
      partner_birthdate,
      address,
      neighborhood,
      city,
      state,
      zip,
      rg,
      orgao,
      cpf,

      signature_date,
      contract_end_date,
      first_meeting_date,
      billing_date,
      number_of_steps,
      first_step_deadline,
      second_step_deadline,
      third_step_deadline,
      first_fee,
      second_fee,
      third_fee,
    });

    toast.success('Cliente e contrato criados com sucesso');
  } catch (err) {
    toast.error(`Erro: ${err}`);
    console.tron.error(err);
  }
}

export default all([
  takeLatest(
    '@contract_customer/STORE_CONTRACT_CUSTOMER',
    storeContractCustomer
  ),
]);
