import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  div.divGroup {
    display: flex;
    margin-top: 20px;

    & > button + button {
      margin-left: 15px;
    }
  }
`;
