import { takeLatest, call, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from 'services/history';

export function* storeContract({ payload }) {
  try {
    const {
      phone,
      personalEmail,
      birthdate,
      neighborhood,
      city,
      state,
      rg,
      cpf,
      razao_social,
      inscricao_social,
      cnpj,
      business_address,
      business_neighborhood,
      business_city,
      business_state,
      planner_id,
    } = payload;

    yield call(api.post, `/planners/${planner_id}/contracts`, {
      phone,
      personalEmail,
      birthdate,
      neighborhood,
      city,
      state,
      rg,
      cpf,
      razao_social,
      inscricao_social,
      cnpj,
      business_address,
      business_neighborhood,
      business_city,
      business_state,
    });

    toast.success('Planejador e contrato criados com sucesso');
  } catch (err) {
    if (err?.response?.data) {
      err.response.data.forEach(message => toast.error(message.message));
    } else {
      toast.error(
        'Tivemos um problema, caso o usuário tenha sido criado com sucesso você deve editar o contrato dele e não criar um novo'
      );
    }
  }
}

export default all([takeLatest('@contract/STORE_CONTRACT', storeContract)]);
