import styled from 'styled-components';

export const measures = {
  sidebarWidth: 75,
  sidebarIconsSize: 55,
  sidebarHomeIconSize: 65,
  avatarSize: 50,
  headerHeight: 75,
  headerIconsSize: 35,
  searchIconSize: 30,
  wrapperSize: 50,
  scrollMargin: 10,
};

export const Container = styled.div`
  height: ${props => props.height ? `${props.height}px` : '100%'};
  width: 100%;
  display: flex;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: ${measures.headerHeight}px;
  margin-left: ${measures.sidebarWidth}px;
  padding: ${measures.wrapperSize}px
    ${measures.wrapperSize - measures.scrollMargin}px ${measures.wrapperSize}px
    ${measures.wrapperSize}px;
  margin-right: ${measures.scrollMargin}px;
`;

export const BreadCrumb = styled.div`
  margin-bottom: 35px;

  a {
    font-size: 17px;
    letter-spacing: 0.26px;
    color: #999999;
    transition: 500ms ease;
  }

  a.main {
    font-size: 30px;
  }

  svg {
    font-size: 25px;
  }

  span {
    font-size: 25px;
    color: #999999;
  }

  a:hover {
    color: ${props => props.theme.info.default};
  }

  a.active {
    color: ${props => props.theme.info.default};
  }
`;
