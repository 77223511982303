import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import StepWizard from 'react-step-wizard';

import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from './styles';

import api from 'services/api';

import Context from './Context';

import Modal from 'components/Modal/Default';
import Dropdown from 'components/Button/Dropdown';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

export default function UpdateModal({
  selectedUser,
  setSelectedUser,
  setPlanners,
  setShowUpdateModal,
}) {
  // Dados do planejador
  const id = selectedUser?.id;
  const [name, setName] = useState(selectedUser?.name);
  const [email, setEmail] = useState(selectedUser?.email);

  // Dados do contrato
  const [rg, setRg] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [personal_email, setPersonalEmail] = useState('');
  const [razao_social, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricao_social, setInscricaoSocial] = useState('');
  const [business_address, setAddressBusiness] = useState('');
  const [business_neighborhood, setNeighborhoodBusiness] = useState('');
  const [business_city, setCityBusiness] = useState('');
  const [business_state, setStateBusiness] = useState('');

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`/planners/${id}/contracts`);

      // Dados do contrato
      setRg(data.rg);
      setBirthdate(data.birthdate);
      setCpf(data.cpf);
      setPhone(data.phone);
      setAddress(data.address);
      setNeighborhood(data.neighborhood);
      setCity(data.city);
      setState(data.state);
      setPersonalEmail(data.personal_email);
      setRazaoSocial(data.razao_social);
      setCnpj(data.cnpj);
      setInscricaoSocial(data.inscricao_social);
      setAddressBusiness(data.business_address);
      setNeighborhoodBusiness(data.business_neighborhood);
      setCityBusiness(data.business_city);
      setStateBusiness(data.business_state);
    }
    fetchData();
  }, [id]);

  async function handleSubmit() {
    try {
      let data;
      if (email === selectedUser.email) {
        const response = await api.put(`/users/${id}`, {
          name,
        });
        data = response.data;
      } else {
        const response = await api.put(`/users/${id}`, {
          name,
          email,
        });
        data = response.data;
      }

      try {
        const schema = Yup.object().shape({
          // begin user
          name: Yup.string().required('Nome é obrigatório'),
          emailValidate: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um email Real válido'),
          passwordValidate: Yup.string().required('Senha é obrigatória'),
          // end user
          phoneValidate: Yup.string().required('Telefone é obrigatório'),
          personal_emailValidate: Yup.string()
            .required('E-mail pessoal é obrigatório')
            .email('Digite um email pessoal válido'),
          birthdateValidate: Yup.string().required(
            'Data de Nascimento é obrigatória'
          ),
          addressValidate: Yup.string().required('O endereço é obrigatório'),
          neighborhoodValidate: Yup.string().required('Bairro é obrigatório'),
          cityValidate: Yup.string().required('Cidade é obrigatória'),
          stateValidate: Yup.string().required('Estado é obrigatório'),
          rgValidate: Yup.string().required('RG é obrigatório'),
          cpfValidate: Yup.string().required('CPF é obrigatório'),
          razao_socialValidate: Yup.string().required(
            'Razão social é obrigatória'
          ),
          inscricao_socialValidate: Yup.string().required('Inscrição Estadual'),
          cnpjValidate: Yup.string().required('CNPJ é obrigatório'),
          business_addressValidate: Yup.string().required(
            'Endereço Empresarial é obrigatório'
          ),
          business_neighborhoodValidate: Yup.string().required(
            'Bairro Empresarial é obrigatório'
          ),
          business_cityValidate: Yup.string().required(
            'Cidade empresarial é obrigatório'
          ),
          business_stateValidate: Yup.string().required(
            'O estado empresarial é obrigatório'
          ),
        });

        await schema.validate(
          {
            // begin user
            name,
            emailValidate: email,
            passwordValidate: cpf,
            // end user
            phoneValidate: phone,
            personal_emailValidate: personal_email,
            birthdateValidate: birthdate,
            addressValidate: address,
            neighborhoodValidate: neighborhood,
            cityValidate: city,
            stateValidate: state,
            rgValidate: rg,
            cpfValidate: cpf,
            razao_socialValidate: razao_social,
            inscricao_socialValidate: inscricao_social,
            cnpjValidate: cnpj,
            business_addressValidate: business_address,
            business_neighborhoodValidate: business_neighborhood,
            business_cityValidate: business_city,
            business_stateValidate: business_state,
          },
          { abortEarly: false }
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.errors.map(error => toast.error(error.toString()));
          // toast.error(err.errors.toString());
          return;
        }
      }

      await api.put(`/planners/${id}/contracts`, {
        phone,
        personal_email,
        birthdate,
        address,
        neighborhood,
        city,
        state,
        rg,
        cpf,
        razao_social,
        inscricao_social,
        cnpj,
        business_address,
        business_neighborhood,
        business_city,
        business_state,
      });

      setPlanners(planners =>
        planners.map(planner => {
          const planner_id = planner[1].slice('#').toString();

          if (planner_id[1] === data.id.toString()) {
            return [
              <Link to={`/planners/${data.id}`}>{data.name}</Link>,
              `#${data.id}`,
              '',
              '',
              '',
              '',
              <div className="div-icons">
                <div className="icons">
                  <Dropdown>
                    <button
                      onMouseDown={() => {
                        setShowUpdateModal(true);
                        setSelectedUser(planner);
                      }}
                    >
                      Editar
                    </button>
                    <button>Excluir</button>
                    <button>Acessar Yuno On</button>
                  </Dropdown>
                </div>
              </div>,
            ];
          }
          return planner;
        })
      );
      setShowUpdateModal(false);

      toast.success('Dados editados com sucesso');
    } catch (err) {}
  }

  return (
    <Modal title="Editar Planejador" color="info" icon={null}>
      <Form onSubmit={e => e.preventDefault()}>
        <Context.Provider
          value={{
            name,
            setName,
            email,
            setEmail,
            rg,
            setRg,
            birthdate,
            setBirthdate,
            cpf,
            setCpf,
            phone,
            setPhone,
            address,
            setAddress,
            neighborhood,
            setNeighborhood,
            city,
            setCity,
            state,
            setState,
            personal_email,
            setPersonalEmail,

            razao_social,
            setRazaoSocial,
            cnpj,
            setCnpj,
            inscricao_social,
            setInscricaoSocial,
            business_address,
            setAddressBusiness,
            business_neighborhood,
            setNeighborhoodBusiness,
            business_city,
            setCityBusiness,
            business_state,
            setStateBusiness,

            handleSubmit,

            setShowUpdateModal,
          }}
        >
          <StepWizard>
            <FirstStep />
            <SecondStep />
          </StepWizard>
        </Context.Provider>
      </Form>
    </Modal>
  );
}

UpdateModal.propTypes = {
  selectedUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  setPlanners: PropTypes.func.isRequired,
  setShowUpdateModal: PropTypes.func.isRequired,
};
