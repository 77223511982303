import styled, { css } from 'styled-components';

export const Form = styled.form`
  width: 400px;
`;

export const DivGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props =>
    props.buttons ? 'space-evenly' : 'space-between'};
  align-items: center;
  flex: 1;

  ${props =>
    props.buttons &&
    css`
      margin-top: 25px;
    `}
`;

export const DivCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  width: 190px;

  div.check {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > span {
      margin-left: 5px;
      margin-right: 10px;
    }
  }
`;
