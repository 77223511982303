import React from 'react';
import PropTypes from 'prop-types';

import { FaSpinner } from 'react-icons/fa';

import { Container } from './styles';

export default function Loading({ color, className }) {
  return (
    <Container color={color} className={className}>
      <FaSpinner className="spin" />
    </Container>
  );
}

Loading.propTypes = {
  color: PropTypes.string,
};

Loading.defaultProps = {
  color: 'info',
};
